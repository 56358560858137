import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import { usaStatesFull } from 'typed-usa-states';

const countryNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

export const sentenceCase = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizePhrase = (str: string): string => capitalize(startCase(str).toLowerCase());

export const getFullStateName = (stateCode: string): string => {
    const stateEntry = usaStatesFull.find((state) => state.abbreviation === stateCode);
    return stateEntry ? stateEntry.name : '';
};

export const getCountryNameFromCode = (regionCode?: string): string => {
    let countryName = 'N/A';
    try {
        if (regionCode) {
            countryName = countryNamesInEnglish.of(regionCode) || 'N/A';
        } else {
            return countryName;
        }
    } catch (err) {
        console.warn('Trying to parse invalid country code', regionCode);
    }

    return countryName;
};
