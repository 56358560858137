import React from 'react';
import { useClientConfig } from 'client/contexts/ClientConfigContext';

interface ImageProps
    extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    path: string;
    alt: string;
}

const Image = (props: ImageProps): JSX.Element => {
    const { staticUrl } = useClientConfig();
    const { path, alt, ...rest } = props;

    return <img src={`${staticUrl}images/${path}`} alt={alt} {...rest} />;
};

export default Image;
