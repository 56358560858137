import React from 'react';
import { useClientConfig } from 'client/contexts/ClientConfigContext';

type AnchorProps = React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
>;

type EmailProps = AnchorProps & {
    email: string;
};

const Email = ({ email, ...props }: EmailProps): JSX.Element => {
    return (
        <a href={`mailto:${email}`} {...props}>
            {email}
        </a>
    );
};

export const FlexHelpEmail = (props: AnchorProps = {}): JSX.Element => {
    const { flexHelpEmail } = useClientConfig();
    return <Email email={flexHelpEmail!} {...props} />;
};

export const FlexComplianceUsEmail = (props: AnchorProps = {}): JSX.Element => {
    const { flexComplianceUsEmail } = useClientConfig();
    return <Email email={flexComplianceUsEmail!} {...props} />;
};

export const FlexComplianceUkEmail = (props: AnchorProps = {}): JSX.Element => {
    const { flexComplianceUkEmail } = useClientConfig();
    return <Email email={flexComplianceUkEmail!} {...props} />;
};
