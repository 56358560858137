import React, { ComponentProps } from 'react';
import { Calendar } from '@indeed/ifl-icons';
import { TextField } from 'client/components/FormikFields';
import { css } from '@emotion/react';

export const CalendarField = (props: ComponentProps<typeof TextField>): JSX.Element => {
    return (
        <TextField
            {...props}
            inputProps={{
                className: 'dd-privacy-hidden',
                type: 'date',
                contentAfter: <Calendar />,
                required: true,
                css: css`
                    input[type='date'] {
                        display: block;
                        -webkit-appearance: textfield;
                        -moz-appearance: textfield;
                        height: 2.75em; // 44px
                        background: transparent; // instead of default grey
                        text-align: left; // instead of default centre
                    }
                    input::-webkit-date-and-time-value {
                        text-align: left; // instead of iOS default centre
                    }
                    // Override calendar indicator to be transparent and fill the whole input
                    // to allow users to easily click the field to open the picker.
                    input[type='date']::-webkit-calendar-picker-indicator {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: auto;
                        height: auto;
                        color: transparent;
                        background: transparent;
                    }
                `,
                ...props.inputProps,
            }}
        />
    );
};
