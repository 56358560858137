// imported first to set webpack public path
import 'client/utils/publicPath';
import 'core-js/actual/array/at';
import 'core-js/proposals/promise-all-settled';
import 'core-js/proposals/promise-with-resolvers';

import React, { PropsWithChildren } from 'react';
import { BaseAppProviders, getInitialState, initThirdPartyServices } from 'client/apps/BaseApp';
import { BrowserRouter } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import { ClientConfig } from 'client/contexts/ClientConfigContext';
import { LocaleProvider } from 'client/contexts/LocaleContext';
import { TaskEligibilityProvider } from 'client/contexts/TaskEligibilityContext';
import { TasksProvider } from 'client/contexts/TasksContext';
import { WorkerComplianceRoutes } from 'client/components/WorkerComplianceRoutes/WorkerComplianceRoutes';
import { WorkerProvider } from 'client/contexts/WorkerContext';
import { createRoot } from 'react-dom/client';
const initialState = getInitialState();
const { emotionCache } = initThirdPartyServices(initialState);
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface WorkerComplianceAppProvidersProps {
    config: ClientConfig;
}

/**
 * This component just wraps children in all the nested providers needed for this app
 */
const WorkerComplianceAppProviders = ({
    config,
    children,
}: PropsWithChildren<WorkerComplianceAppProvidersProps>): JSX.Element => {
    return (
        <BaseAppProviders
            config={config}
            expectingRoles={['worker']}
            loginType="PASSPORT"
            injectedGraphQlVariables={['accountKey']}
        >
            <WorkerProvider>
                <LocaleProvider>
                    <TaskEligibilityProvider>
                        <TasksProvider>{children}</TasksProvider>
                    </TaskEligibilityProvider>
                </LocaleProvider>
            </WorkerProvider>
        </BaseAppProviders>
    );
};

const root = createRoot(document.getElementById('app-root')!);
root.render(
    <CacheProvider value={emotionCache}>
        <BrowserRouter>
            <WorkerComplianceAppProviders config={initialState.config}>
                <WorkerComplianceRoutes />
            </WorkerComplianceAppProviders>
        </BrowserRouter>
    </CacheProvider>
);
