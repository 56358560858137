import React, { useEffect } from 'react';
import { Box, Heading } from '@indeed/ifl-components';

import AccountErrorPage, {
    I9StatusCodes,
} from 'client/components/AccountErrorPage/AccountErrorPage';
import ComplianceContainer from 'client/components/ComplianceContainer';
import NoTasksBlock from 'client/pages/index/components/NoTasksBlock/NoTasksBlock';
import Page from 'client/components/Page';
import TaskItem from 'client/pages/index/components/TaskItem';
import useCreateMissingTasks from 'client/hooks/useCreateMissingTasks';
import useImportMissingFlexData from 'client/hooks/useImportMissingFlexData';
import { LoadingPage } from 'client/components/Loading';
import { TaskStatuses, byTaskType, getI9Metadata, isTaskEnabled } from 'client/utils/task';
import { useAuthentication } from 'client/contexts/AuthenticationContext';
import { useTasks } from 'client/contexts/TasksContext';
import { useWorker } from 'client/contexts/WorkerContext';

const IndexPage = (): JSX.Element => {
    const { tasks, loading, completed, error } = useTasks();
    const [callCreateMissingTasks] = useCreateMissingTasks('indexPage', 'onPageLoad');

    const { user } = useAuthentication();
    const workerType = useWorker();
    const worker = workerType.worker!;
    const [callImportMissingFlexData] = useImportMissingFlexData(
        worker.slug,
        'indexPage',
        'onPageLoad'
    );

    useEffect(() => {
        if (completed && tasks.length > 0) {
            /* eslint-disable @typescript-eslint/no-floating-promises */
            callCreateMissingTasks();
            callImportMissingFlexData();
            /* eslint-enable @typescript-eslint/no-floating-promises */
        }
    }, [completed, tasks.length, callCreateMissingTasks, callImportMissingFlexData]);

    // If get tasks never succeeded
    if (error && !completed) {
        throw new Error(`Error retrieving Tasks: ${error}`);
    }

    if (loading || !completed) {
        return <LoadingPage />;
    }

    const locationTasks = tasks.filter(
        (task) => isTaskEnabled(task) && task.type === 'LOCATION_REQUIREMENTS'
    );

    const mediaTasks = tasks.filter(
        (task) => isTaskEnabled(task) && task.type === 'MEDIA_REQUIREMENTS'
    );

    const filteredTasks = tasks.filter(
        (task) =>
            isTaskEnabled(task) &&
            task.type !== 'LOCATION_REQUIREMENTS' &&
            task.type !== 'MEDIA_REQUIREMENTS'
    );

    // Add a single Location Task and Media Task for purposes of ordering task cards below
    if (locationTasks.length) {
        filteredTasks.push(locationTasks[0]);
    }
    if (mediaTasks.length) {
        filteredTasks.push(mediaTasks[0]);
    }

    const i9Task = tasks.find((task) => task.type === 'FORM_I9');
    const i9TaskMetadata = getI9Metadata(i9Task ?? null);
    if (i9Task?.status === TaskStatuses.NEEDS_REVIEW && i9TaskMetadata?.provider !== 'WORKBRIGHT') {
        return (
            <AccountErrorPage user={user} worker={worker} errorCode={I9StatusCodes.DUP_ACCOUNT} />
        );
    }

    return (
        <Page title="Onboarding">
            <ComplianceContainer>
                <Box sx={{ mb: '2rem' }}>
                    <Heading>Onboarding tasks</Heading>
                    {!filteredTasks.length && (
                        <div>You currently do not have any tasks to complete.</div>
                    )}
                    {!!filteredTasks.length && (
                        <div>Complete the following to become employed by Indeed Flex.</div>
                    )}
                </Box>
                <Box>
                    {filteredTasks.sort(byTaskType).map((task) => {
                        let currentTasks = [task];
                        if (task.type === 'LOCATION_REQUIREMENTS') {
                            currentTasks = locationTasks;
                        } else if (task.type === 'MEDIA_REQUIREMENTS') {
                            currentTasks = mediaTasks;
                        }
                        return <TaskItem tasks={currentTasks} key={task.slug} />;
                    })}
                    {!filteredTasks.length && <NoTasksBlock />}
                </Box>
            </ComplianceContainer>
        </Page>
    );
};

export default IndexPage;
