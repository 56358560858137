import { findTaskByType } from 'client/utils/task';
import { useTasks } from 'client/contexts/TasksContext';

export type DisabledReason =
    | 'HAS_DISABLED_STATUS'
    | 'INCOMPLETE_CJO'
    | 'INCOMPLETE_FORM_I9'
    | 'INCOMPLETE_BGC'
    | 'INCOMPLETE_PROFILE'
    | 'INCOMPLETE_BANK_DETAILS'
    | 'INCOMPLETE_DOCUMENTS'
    | null;

const nonBlockedTasks = (currentTaskType: TaskType): TaskType[] => [
    'PROFILE',
    'EMERGENCY_CONTACT',
    'UK_PAYROLL',
    'UK_DOCUMENTS',
    'RIGHT_TO_WORK',
    currentTaskType,
];

/**
 * Return the reason the task is currently disabled (user is unable to start work), or null if task is enabled
 *
 * Task is disabled if:
 * all but PROFILE
 * - PROFILE has not been completed
 * BACKGROUND_CHECK
 * - no cjo OR cjo has not been completed
 * FORM_I9
 * - no cjo OR cjo has not been completed OR has a DISABLED status
 * LOCATION_REQUIREMENTS
 * - no cjo OR cjo has not been completed OR has a DISABLED status
 * PAYROLL
 * - no cjo OR cjo has not been completed
 *   OR
 * - has a DISABLED status (race condition where required tasks are done but payroll team isn't ready yet)
 */
export const useTaskDisabledReason = (task: ComplianceTask | null | undefined): DisabledReason => {
    const { tasks } = useTasks();
    if (!task) {
        return null;
    }

    const { type, status } = task;
    const locationRequirementsTask = findTaskByType(tasks, 'LOCATION_REQUIREMENTS');
    if (type !== 'PROFILE' && !findTaskByType(tasks, 'PROFILE')?.completedDate) {
        return 'INCOMPLETE_PROFILE';
    }
    if (
        !nonBlockedTasks('CONDITIONAL_JOB_OFFER').includes(type) &&
        !findTaskByType(tasks, 'CONDITIONAL_JOB_OFFER')?.completedDate
    ) {
        return 'INCOMPLETE_CJO';
    }

    if (
        locationRequirementsTask &&
        ['FORM_I9', 'PAYROLL'].includes(type) &&
        !locationRequirementsTask.completedDate
    ) {
        // Not all workers have a location requirements task so only return this if the task exists
        return 'INCOMPLETE_DOCUMENTS';
    }

    if (type === 'PAYROLL') {
        if (['DISABLED', 'NEEDS_REVIEW'].includes(status)) {
            return 'HAS_DISABLED_STATUS';
        }
        if (!findTaskByType(tasks, 'BACKGROUND_CHECK')?.completedDate) {
            return 'INCOMPLETE_BGC';
        }
    }

    return null;
};
