import React, { PropsWithChildren } from 'react';
import { Box } from '@indeed/ifl-components';

interface ConfirmEntryProps {
    title: string;
}

const ConfirmEntry = ({ title, children }: PropsWithChildren<ConfirmEntryProps>): JSX.Element => {
    return (
        <Box sx={{ mb: '1rem' }}>
            <Box sx={{ mb: '.25rem' }}>
                <strong>{title}</strong>
            </Box>
            <div className="dd-privacy-hidden">{children}</div>
        </Box>
    );
};

export default ConfirmEntry;
