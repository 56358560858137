import * as FormikFields from 'client/components/FormikFields';
import FormRow from 'client/components/FormRow';
import React from 'react';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import { Col } from '@indeed/itmp-components';
import { FormikProps, FormikValues } from 'formik';
import { Label } from '@indeed/ifl-components';
import { useCountryCallingCodes } from 'client/utils/options';

export interface PhoneNumberFieldProps {
    formik: FormikProps<unknown> | FormikValues;
    name?: string;
    label: string;
    marginBlock?: number;
    required?: boolean;
    disabled?: boolean;
}

const PhoneNumberField = ({
    formik,
    label,
    name = 'phoneNumber',
    marginBlock = 1,
    required = false,
    disabled = false,
}: PhoneNumberFieldProps): JSX.Element => {
    const countryCallingCodeOptions = useCountryCallingCodes();

    return (
        <>
            <Label size="md" id="phone" showRequiredIndicator={required} htmlFor={`${name}-field`}>
                {label}
            </Label>
            <FormRow sx={{ justifyContent: 'center' }}>
                <Col size={5} sx={{ paddingInlineEnd: 1, marginBlock }}>
                    <FormikFields.SelectField
                        selectProps={{
                            required: false,
                            disabled,
                            className: 'fs-exclude',
                            /* eslint-disable @typescript-eslint/naming-convention */
                            'aria-label': 'Phone country',
                            onChange: (event) => {
                                formik.values[name] = new AsYouType(
                                    event.target.value as CountryCode
                                ).input(formik.values[name]);

                                formik.handleChange(event);
                            },
                        }}
                        options={countryCallingCodeOptions}
                        name="phoneCountry"
                        aria-labelledby="phone"
                        data-testid="phone-country-field"
                    />
                </Col>
                <Col size={7} sx={{ paddingInlineStart: 1, marginBlock }}>
                    <FormikFields.TextField
                        inputProps={{
                            autoComplete: 'off',
                            className: 'fs-exclude',
                            required,
                            disabled,
                            /* eslint-disable @typescript-eslint/naming-convention */
                            'aria-label': 'Phone number',
                            onChange: (event) => {
                                const formattedPhoneNumber = new AsYouType(
                                    formik.values.phoneCountry as CountryCode
                                ).input(event.target.value);

                                // Handle delete
                                if (formattedPhoneNumber === formik.values[name]) {
                                    const digits = formattedPhoneNumber.replace(/\D/g, ''); // strip away non-numeric characters
                                    const withoutLastDigit = digits.slice(0, digits.length - 1); // remove the last digit
                                    event.target.value = new AsYouType(
                                        formik.values.phoneCountry as CountryCode
                                    ).input(withoutLastDigit);
                                } else {
                                    event.target.value = formattedPhoneNumber;
                                }

                                formik.handleChange(event);
                            },
                        }}
                        aria-valuemax={9}
                        name={name}
                        aria-labelledby="phone"
                    />
                </Col>
            </FormRow>
        </>
    );
};

export default PhoneNumberField;
