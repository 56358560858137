import { MouseEvent, useCallback } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useIsMobileBridgeInitialized, useMobileBridgeDispatch } from '@indeed/flex-mobile-bridge';

import { NavigateToUrlTargets, webActions } from 'client/contexts/MobileBridgeContext';

export const useNavigateToUrlOnClick = (
    target = NavigateToUrlTargets.EXTERNAL,
    trackingEvent?: string
): ((e: MouseEvent<HTMLAnchorElement>) => void) => {
    const isBridgeInitialized = useIsMobileBridgeInitialized();
    const dispatch = useMobileBridgeDispatch();

    return useCallback(
        (e: MouseEvent<HTMLAnchorElement>) => {
            if (trackingEvent) {
                datadogRum.addAction(trackingEvent);
            }
            if (isBridgeInitialized) {
                e.preventDefault();
                dispatch(
                    webActions.navigateToUrl({
                        url: e.currentTarget.href,
                        target,
                    })
                );
            }
        },
        [dispatch, target]
    );
};
