import React from 'react';
import { DatadogAction } from 'client/utils/logging';
import {
    GraphicContentSection,
    TextContentSection,
} from 'client/pages/i9/WorkBrightI9/ContentSection';
import { LogRenderDatadogAction } from 'client/components/LogRenderDatadogAction';
import { Thanks1 } from '@indeed/ipl-illustrations-spot';

const WorkBrightI9Complete = (): JSX.Element => {
    return (
        <LogRenderDatadogAction action={DatadogAction.i9CompletedRendered}>
            <GraphicContentSection>
                <Thanks1 sx={{ display: 'flex' }} />
            </GraphicContentSection>
            <TextContentSection
                heading="Your Form I-9 is now completed."
                text="We will contact you if any action is required in the future."
            />
        </LogRenderDatadogAction>
    );
};
export default WorkBrightI9Complete;
