import { NavigateToUrlOptionsPayload } from './MobileBridgeContext.types';
import { WEB_ACTION_SOURCE, WebActionTypes } from './MobileBridgeContext.constants';

const setSourceInPayload = <T>(payload: T): T & { source: typeof WEB_ACTION_SOURCE } => ({
    ...payload,
    source: WEB_ACTION_SOURCE,
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const navigateToUrl = (payload: NavigateToUrlOptionsPayload) => ({
    type: WebActionTypes.NAVIGATE_TO_URL,
    payload: setSourceInPayload(payload),
});
