/**
 * This module provides utility component and methods useful for making API calls,
 * either to the TACO backend, or elsewhere.
 */

import React, { PropsWithChildren, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

/**
 * Utility component to provide a singleton react-query client
 * Retrieve with `useQueryClient()` straight from react-query
 * @param children
 * @constructor
 */
export const LocalQueryClientProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
    const [queryClient] = useState(new QueryClient());

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
