import Image from 'client/components/Image';
import React from 'react';
import StatusTag from 'client/components/StatusTag';
import { Flex } from '@indeed/ifl-components';
import { RequiresProfilePageContent } from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import { useClientConfig } from 'client/contexts/ClientConfigContext';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';

const NotCompleted = (): JSX.Element => {
    const { flexAppDownloadUrl } = useClientConfig();
    return (
        <>
            <p>
                Download the Indeed Flex app and complete the onboarding steps to get your
                conditional job offer.
            </p>
            <p>Estimated completion time: 15 minutes</p>
            <Flex
                /* eslint-disable @typescript-eslint/naming-convention */
                sx={{ '&': { 'a:first-of-type': { mr: '.25rem' } } }}
            >
                <a href={flexAppDownloadUrl} target="_blank">
                    <Image path="app-store-download.png" alt="App store download" width="149" />
                </a>
                <a href={flexAppDownloadUrl} target="_blank">
                    <Image path="google-play-download.png" alt="Google play download" width="149" />
                </a>
            </Flex>
        </>
    );
};

type CompletedProps = {
    task: ComplianceTask;
};

const Completed = ({ task }: CompletedProps): JSX.Element => {
    return (
        <>
            <StatusTag task={task} sx={{ mb: '1.5rem' }} />
            <p>
                You've successfully acknowledged and signed the conditional job offer for Indeed
                Flex. Please complete the remaining onboarding tasks.
            </p>
        </>
    );
};

interface PageContent {
    task: ComplianceTask;
}

const PageContent = ({ task }: PageContent): JSX.Element => {
    const disabledReason = useTaskDisabledReason(task);

    if (disabledReason === 'INCOMPLETE_PROFILE') {
        return <RequiresProfilePageContent task={task} taskName="conditional job offer" />;
    }
    if (task.completedDate) {
        // "hack" until we can actually rely on CJO status
        // this mirrors the previous hide cjo logic
        return <Completed task={task} />;
    }
    switch (task.status) {
        case 'NOT_COMPLETED':
            return <NotCompleted />;
        case 'COMPLETED':
            return <Completed task={task} />;
        default:
            throw Error(`Invalid status for conditional job offer: ${task.status}`);
    }
};

export default PageContent;
