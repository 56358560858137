export const redirect = (url: string): void => {
    window.location.href = url;
};

export const matchesRole = (user: ComplianceUser | null, role: Roles): boolean => {
    return !!user?.roles?.includes(role);
};

export const matchesRoles = (user: ComplianceUser | null, roles: Roles[]): boolean => {
    return roles.some((role: Roles) => !!user?.roles?.includes(role));
};

export const expectingRolesMatchesRoles = (
    expectingRoles: Roles[],
    userRoles: Roles[]
): boolean => {
    return expectingRoles.some((role: Roles) => userRoles.includes(role));
};

export const hasUkAgentRole = (user: ComplianceUser | null): boolean =>
    matchesRoles(user, ['uk_agent', 'uk_admin']);

export const hasUkAdminRole = (user: ComplianceUser | null): boolean =>
    matchesRoles(user, ['uk_admin']);

export const hasUsAgentRole = (user: ComplianceUser | null): boolean =>
    matchesRoles(user, ['us_agent', 'us_admin']);

export const hasUsAdminRole = (user: ComplianceUser | null): boolean =>
    matchesRoles(user, ['us_admin']);
