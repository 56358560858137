import dayjs from 'client/utils/dayjs';
import { array, object, string } from 'yup';
import { getUkDocumentsMetadata } from 'client/utils/task';

export interface HealthFormValues {
    medicalCondition: string;
    medicalAdjustments: string;
    agree: string[];
}

export const healthFormInitialValues = {
    medicalCondition: '',
    medicalAdjustments: '',
    agree: [],
};

export const healthFormValidationSchema = object().shape({
    medicalCondition: string().trim().required('Yes or no is required.'),
    medicalAdjustments: string().trim(),
    agree: array().length(1, 'Acknowledging this statement is required.'),
});

export const toHealthFormValues = (task: ComplianceTask): Partial<HealthFormValues> => {
    const ukDocumentsMetadata = getUkDocumentsMetadata(task);
    const { medicalAdjustments, medicalCondition } = ukDocumentsMetadata!;
    const result = {
        medicalAdjustments,
        medicalCondition: medicalCondition != null ? String(medicalCondition) : '',
        agree: task.status === 'COMPLETED' ? ['true'] : [],
    };
    return result;
};

export interface OverseasFormValues {
    livedOverseas: string;
}

export const overseasFormInitialValues = {
    livedOverseas: '',
};

export const overseasFormValidationSchema = object().shape({
    livedOverseas: string().trim().required('Yes or no is required.'),
});

export const toOverseasFormValues = (task: ComplianceTask): Partial<OverseasFormValues> => {
    const ukDocumentsMetadata = getUkDocumentsMetadata(task);
    const { livedOverseas } = ukDocumentsMetadata!;
    const result = {
        livedOverseas: livedOverseas != null ? String(livedOverseas) : '',
    };
    return result;
};

const isHealthFormSubmission = (
    values: HealthFormValues | OverseasFormValues
): values is HealthFormValues => {
    return (values as HealthFormValues).medicalAdjustments !== undefined;
};

export const toCompleteUkDocumentsInput = (
    slug: string,
    values: HealthFormValues | OverseasFormValues
): CompleteUkDocumentsInput => {
    const completedDate = dayjs().toISOString();
    const input = {
        slug,
        metadata: {
            ukDocuments: {},
        },
    };
    if (isHealthFormSubmission(values)) {
        input.metadata.ukDocuments = {
            healthQuestionnaireCompletedDate: completedDate,
            medicalCondition: values.medicalCondition === 'true',
            medicalAdjustments: values.medicalAdjustments,
        };
    } else {
        input.metadata.ukDocuments = {
            overseasQuestionnaireCompletedDate: completedDate,
            livedOverseas: values.livedOverseas === 'true',
        };
    }
    return input;
};

export const getInitialHealthFormValues = (task: ComplianceTask): HealthFormValues => {
    const initial = toHealthFormValues(task);
    return { ...healthFormInitialValues, ...initial };
};

export const getInitialOverseasFormValues = (task: ComplianceTask): OverseasFormValues => {
    const initial = toOverseasFormValues(task);
    return { ...overseasFormInitialValues, ...initial };
};
