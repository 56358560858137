import { I9TaskTrackingEvents } from 'client/pages/i9/I9.tracking';

export const DatadogAction = {
    test: 'worker_task_list.page.viewed',
    bankDetailsSubmitted: 'bank_details_task.submit_btn.clicked',
    bgcSubmitted: 'task.bgc.submitted',
    onboardingStartClicked: 'onboarding.start.clicked',
    loadingStuck: 'app.loading.viewed',
    ...I9TaskTrackingEvents,
} as const;
