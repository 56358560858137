const addressComponentMap: { [key: string]: string } = {
    /* eslint-disable */
    street_number: 'streetNumber',
    route: 'streetName',
    premise: 'address2',
    locality: 'city',
    postal_town: 'postalTown',
    country: 'country',
    administrative_area_level_1: 'state',
    postal_code: 'postalCode',
    /* eslint-enable */
};

export interface AddressType {
    [key: string]: string | undefined;
    streetNumber?: string;
    streetName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    postalTown?: string;
    state?: string;
    country?: string;
    postalCode?: string;
}

export interface AddressDetail {
    types: string[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    short_name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    long_name: string;
}

export const mapAddress = (addressDetails: AddressDetail[]): AddressType => {
    const address: AddressType = {};
    addressDetails.forEach((details) => {
        for (const i in details.types) {
            if (addressComponentMap.hasOwnProperty(details.types[i])) {
                const fieldName = addressComponentMap[details.types[i]];
                address[fieldName] = details.short_name;
                break;
            }
        }
    });
    if (address.streetNumber && address.streetName) {
        address.address1 = `${address.streetNumber} ${address.streetName}`;
    } else if (address.streetName) {
        address.address1 = address.streetName;
    }
    if (!address.city && address.postalTown) {
        address.city = address.postalTown;
    }
    return address;
};

export interface LocationDetail {
    lat(): number;
    lng(): number;
}

export interface GeometryDetail {
    location: LocationDetail;
}
