import Cookies from 'js-cookie';
import fetch from 'cross-fetch';
import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    Observable,
    Operation,
    from,
} from '@apollo/client';
import { VariableNode } from 'graphql/language/ast';
import { createUploadLink } from 'apollo-upload-client';

const queryRequiresVariable = (variableName: string, operation: Operation): boolean => {
    return operation.query.definitions?.some((node) => {
        return 'variableDefinitions' in node
            ? node.variableDefinitions?.some(
                  ({ variable }: { variable: VariableNode }) => variable.name.value === variableName
              )
            : false;
    });
};
import { Subscription } from 'zen-observable-ts';

/**
 *
 * @param url - Base url for graphql endpoint
 * @param accountKey - Account key of user
 * @param injectedGraphQlVariables - Variables tp inject into each call
 */
export const createClient = (
    url: string,
    accountKey: string,
    injectedGraphQlVariables: string[] = []
): ApolloClient<object> => {
    const uploadLink = createUploadLink({
        uri: `${url}/graphql`,
        credentials: 'include',
        fetch,
    });
    const httpTerminatingLink = uploadLink;

    const authLink = new ApolloLink((operation, forward) => {
        const csrftoken = Cookies.get('csrftoken');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        operation.setContext(({ headers }: Record<string, any>) => ({
            headers: {
                ...headers,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'X-CSRFTOKEN': csrftoken ? csrftoken : '',
            },
        }));

        return forward(operation);
    });

    const injectVariables = (operation: Operation): Operation => {
        injectedGraphQlVariables.forEach((variableName) => {
            const requiresVariable = queryRequiresVariable(variableName, operation);
            if (requiresVariable) {
                operation.variables[variableName] = accountKey;
            }
        });

        return operation;
    };

    const variableInjectionLink = new ApolloLink(
        (operation, forward) =>
            new Observable((observer) => {
                let handle: Subscription;
                Promise.resolve(operation)
                    .then(injectVariables)
                    .then((oper) => {
                        handle = forward(oper).subscribe({
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        });
                    })
                    .catch(observer.error.bind(observer));

                return () => {
                    if (handle) {
                        handle.unsubscribe();
                    }
                };
            })
    );

    return new ApolloClient({
        cache: new InMemoryCache({
            typePolicies: {
                WorkerNode: {
                    keyFields: ['slug'],
                },
            },
        }),
        link: from([variableInjectionLink, authLink, httpTerminatingLink]),
    });
};
