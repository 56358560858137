import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import Page from 'client/components/Page';
import PageContent from 'client/pages/ukpayroll/components/PageContent';
import React from 'react';
import { LoadingPage } from 'client/components/Loading';
import { Redirect, useParams } from 'react-router-dom';
import { UkPayrollTaskTrackingEvents } from './UkPayroll.tracking';
import { isUkPayrollTask } from 'client/utils/task';
import { useTasks } from 'client/contexts/TasksContext';

type SlugParam = {
    slug?: string;
};

const UkPayrollPage = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();

    const { completed, loading, tasks } = useTasks();

    if (!completed || loading) {
        return <LoadingPage />;
    }

    const task = tasks.find((t) => t.slug === slug);
    if (!isUkPayrollTask(task)) {
        return <Redirect to="/" />;
    }

    return (
        <Page title="Pay">
            <ComplianceContainer>
                <BackButton trackingEvent={UkPayrollTaskTrackingEvents.backBtnClicked} />
                <PageContent task={task!} />
            </ComplianceContainer>
        </Page>
    );
};

export default UkPayrollPage;
