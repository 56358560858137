import { getUkDocumentsMetadata } from 'client/utils/task';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useCompleteUkDocumentsMutation } from 'client/hooks/graphql';
import { useHistory } from 'react-router-dom';
import { useTasks } from 'client/contexts/TasksContext';
import { useToast } from 'client/contexts/ToastContext';

const useCompleteUkDocuments = (
    task: ComplianceTask,
    redirect: string,
    scopeKey: string
): [(input: CompleteUkDocumentsInput) => Promise<void>] => {
    const history = useHistory();
    const { pushToast } = useToast();
    const { updateTask } = useTasks();
    const [callCompleteUkDocumentsMutation] = useCompleteUkDocumentsMutation();
    const taskMetadata = getUkDocumentsMetadata(task);

    const callCompleteUkDocuments = (input: CompleteUkDocumentsInput): Promise<void> => {
        return callCompleteUkDocumentsMutation({
            variables: { input },
        })
            .then(({ data }) => {
                const result = data?.completeUkDocuments?.result;
                if (result) {
                    const medicalAdjustments = result.metadata.ukDocuments.medicalAdjustments;
                    const updatedTaskValue = {
                        ...task,
                        metadata: {
                            ...task.metadata,
                            ukDocuments: {
                                slug: taskMetadata!.slug,
                                ...result.metadata.ukDocuments,
                                medicalAdjustments: medicalAdjustments ? medicalAdjustments : '',
                            },
                        },
                    };
                    updateTask(updatedTaskValue);
                    history.push({
                        pathname: redirect,
                        search: window.location.search,
                    });
                } else {
                    reportErrorToThirdPartyTools(
                        new Error(
                            `Could not retrieve result from completeUkDocuments mutation given input: ${JSON.stringify(
                                input
                            )}`
                        ),
                        scopeKey,
                        'onFormSubmit'
                    );
                    pushToast(
                        'There was an error while retrieving the response from submission, but the data may have been saved. Refresh the page and try again.',
                        { status: 'warning' }
                    );
                }
            })
            .catch((apolloError) => {
                reportErrorToThirdPartyTools(apolloError, scopeKey, 'completeUkDocuments');
                pushToast(
                    'There was an error while attempting to submit the information, please try again.',
                    { status: 'critical' }
                );
            });
    };
    return [callCompleteUkDocuments];
};

export default useCompleteUkDocuments;
