import FormRouter from 'client/pages/ukpayroll/components/FormRouter';
import React from 'react';
import { Box } from '@indeed/ifl-components';
import { RequiresProfilePageContent } from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';

type PageContentProps = {
    task: ComplianceTask;
};

const PageContent = ({ task }: PageContentProps): JSX.Element => {
    const disabledReason = useTaskDisabledReason(task);
    if (disabledReason === 'INCOMPLETE_PROFILE') {
        return (
            <Box>
                <RequiresProfilePageContent task={task} taskName="Pay" />
            </Box>
        );
    }

    return <FormRouter task={task} />;
};

export default PageContent;
