import { BackgroundCheckTrackingEvents } from 'client/pages/bgc/BackgroundCheckPage.tracking';
import { ClientConfig } from 'client/contexts/ClientConfigContext';
import { ConditionalJobOfferTrackingEvents } from 'client/pages/cjo/ConditionalJobOffer.tracking';
import { TkData } from 'server/controllers/common';
import { UkDocumentsTaskTrackingEvents } from 'client/pages/ukdocuments/UkDocuments.tracking';
import { datadogRum } from '@datadog/browser-rum';
import { getFlexId, isAdminUser } from 'client/utils/worker';

export type DocumentTrackingEventType =
    | 'signCheckboxClicked'
    | 'submitBtnClicked'
    | 'backBtnClicked'
    | 'downloadDocumentClicked'
    | 'completeTrainingClicked';

type TagValue = string | number | boolean;
type RumGlobalContextTags = { [key: string]: TagValue };

export const removeGlobalContextTag = (key: string): void => {
    const context = datadogRum.getRumGlobalContext();
    const tags = (context.globalTags as RumGlobalContextTags) || {};
    const { [key]: _, ...outputTags } = tags;

    datadogRum.addRumGlobalContext('globalTags', outputTags);
};

export const addGlobalContextTag = (key: string, value?: TagValue): void => {
    const context = datadogRum.getRumGlobalContext();
    const tags = (context.globalTags as RumGlobalContextTags) || {};

    datadogRum.addRumGlobalContext('globalTags', {
        ...tags,
        [key]: value ?? true,
    });
};

export const initDatadogRum = (config: ClientConfig, tkData: TkData): void => {
    datadogRum.init({
        applicationId: config['worker-compliance-webapp.datadog.applicationId']!,
        clientToken: config['worker-compliance-webapp.datadog.clientToken']!,
        proxyUrl: config['worker-compliance-webapp.datadog.proxy'],
        site: 'datadoghq.com',
        service: 'worker-compliance-webapp',
        env: config.level,
        version: config['worker-compliance-webapp.release'],
        sessionSampleRate: 100,
        sessionReplaySampleRate: 10,
        allowedTracingUrls: [
            /https:\/\/.*\.indeed\.tech/,
            /^https?:\/\/(.*)?.indeed.com/,
            /^https?:\/\/(.*)?.indeed.net/,
            /^http:\/\/localhost.*$/,
        ],
        useSecureSessionCookie: true,
        trackUserInteractions: true,
        trackResources: true,
    });

    datadogRum.addRumGlobalContext('team', 'taco');
    datadogRum.addRumGlobalContext('app', 'worker-compliance-webapp');
    datadogRum.addRumGlobalContext('device_id', tkData.ctk);

    addGlobalContextTag('accountable_party_id', 'sts-flex-acquisition-t3');
};

/* eslint-disable @typescript-eslint/naming-convention */
export const setDatadogUser = (user: ComplianceUser): void => {
    if (user.accountKey) {
        datadogRum.setUser({
            id: user.accountKey,
            platform_id: 1,
            main_profile_type: isAdminUser(user.roles) ? 'admin' : 'worker',
        });
    }
};
/* eslint-enable @typescript-eslint/naming-convention */

export const setDatadogUserPropertiesForWorker = (worker: ComplianceWorker): void => {
    const workerFlexId = getFlexId(worker);
    if (workerFlexId) {
        datadogRum.setUserProperty('worker_id', workerFlexId);
    }
};

export const logDocumentEvent = (
    taskType: TaskType,
    eventName: DocumentTrackingEventType,
    payload?: Record<string, Maybe<string | undefined>>
): void => {
    switch (taskType) {
        case 'UK_DOCUMENTS':
            datadogRum.addAction(UkDocumentsTaskTrackingEvents[eventName], payload);
            break;
        case 'BACKGROUND_CHECK':
            datadogRum.addAction(BackgroundCheckTrackingEvents[eventName], payload);
            break;
        case 'CONDITIONAL_JOB_OFFER':
            datadogRum.addAction(ConditionalJobOfferTrackingEvents[eventName], payload);
            break;
        default:
            break;
    }
};
