import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { ClientConfig } from 'client/contexts/ClientConfigContext';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';

export interface PostalAddress {
    revision?: number;
    regionCode: string;
    languageCode?: string;
    postalCode: string;
    sortingCode?: string;
    administrativeArea: string;
    locality: string;
    sublocality?: string;
    addressLines: string[];
    recipients?: string[];
    organization?: string;
}

interface ComponentName {
    text: string;
    languageCode?: string;
}
export interface AddressComponent {
    componentName: ComponentName;
    componentType: string;
    confirmationLevel?: string;
    inferred?: boolean;
    spellCorrected?: boolean;
    replaced?: boolean;
    unexpected?: boolean;
}
export interface GoogleAddressValidationResponse {
    result: {
        verdict: {
            inputGranularity: string;
            validationGranularity: string;
            geocodeGranularity: string;
            addressComplete: boolean;
            hasUnconfirmedComponents?: boolean;
            hasInferredComponents: boolean;
            hasReplacedComponents?: boolean;
        };
        address: {
            formattedAddress: string;
            postalAddress: PostalAddress;
            addressComponents: AddressComponent[];
            missingComponentTypes?: [string];
            unconfirmedComponentTypes?: [string];
            unresolvedTokens?: [string];
        };
        geocode: {};
        metadata: {};
        uspsData: {};
    };
    responseId: string;
}
export interface AddressStatus {
    valid: boolean;
    suggestedAddress?: WcsAddressType;
}

// This is related directly to https://developers.google.com/maps/documentation/address-validation
export const googleAddressComponentMap: { [key: string]: string } = {
    /* eslint-disable */
    street_number: 'streetNumber',
    route: 'streetName',
    subpremise: 'address2',
    locality: 'city',
    administrative_area_level_1: 'state',
    postal_code: 'postalCode',
    /* eslint-enable */
};

export interface WcsAddressType {
    [key: string]: string | undefined;
    streetNumber?: string;
    streetName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
}
interface GoogleAddress {
    address: PostalAddress;
}
export default class GoogleAddressValidationClient {
    private readonly clientConfig: ClientConfig;

    constructor(clientConfig: ClientConfig) {
        this.clientConfig = clientConfig;
    }

    private get url(): string {
        return this.clientConfig.googleAddressValidationUrl!;
    }

    private get apiKey(): string {
        return this.clientConfig.googleAddressValidationApiKey!;
    }

    private get headers(): RawAxiosRequestHeaders {
        /* eslint-disable prettier/prettier */
        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }

    public async validateAddress(address: GoogleAddress): Promise<AddressStatus> {
        const requestOptions: AxiosRequestConfig = {
            headers: this.headers,
        };
        const url = `${this.url}?key=${this.apiKey}`;

        try {
            const response = await axios.post(url, address, requestOptions);

            if (response.status === 200) {
                const addressData: GoogleAddressValidationResponse = response.data;
                return this.getAddressValidType(address.address, addressData);
            } else {
                reportErrorToThirdPartyTools(
                    response.statusText,
                    'profilePage',
                    'googleAddressValidationCall'
                );
                // just returning true to bypass the confirm-address flow
                return { valid: true };
            }
        } catch (error) {
            reportErrorToThirdPartyTools(error, 'profilePage', 'googleAddressValidationCall');
            // just returning true to bypass the confirm-address flow
            return { valid: true };
        }
    }

    public getAddressValidType(
        initialAddress: PostalAddress,
        googleAddressResponse: GoogleAddressValidationResponse
    ): AddressStatus {
        const addressStatus: AddressStatus = { valid: true };
        if (googleAddressResponse.result.address.addressComponents) {
            const suggestedAddress = this.mapGoogleAddress(
                googleAddressResponse.result.address.addressComponents
            );
            // check if any of the required fields in suggestedAddress are empty (return valid i.e. skip confirm-address step)
            if (
                !suggestedAddress.address1 ||
                !suggestedAddress.city ||
                !suggestedAddress.state ||
                !suggestedAddress.postalCode
            ) {
                return addressStatus;
            }
            // check address1 - streetnumber + streetname
            if (initialAddress.addressLines[0] !== suggestedAddress.address1) {
                addressStatus.valid = false;
                addressStatus.suggestedAddress = suggestedAddress;
                return addressStatus;
            }
            // check address2 - subpremise?
            if (suggestedAddress.address2) {
                if (!initialAddress.addressLines.includes(suggestedAddress.address2)) {
                    addressStatus.valid = false;
                    addressStatus.suggestedAddress = suggestedAddress;
                    return addressStatus;
                }
            }
            // check city
            if (initialAddress.locality !== suggestedAddress.city) {
                addressStatus.valid = false;
                addressStatus.suggestedAddress = suggestedAddress;
                return addressStatus;
            }
            // check state
            if (initialAddress.administrativeArea !== suggestedAddress.state) {
                addressStatus.valid = false;
                addressStatus.suggestedAddress = suggestedAddress;
                return addressStatus;
            }
            // check postalCode
            if (initialAddress.postalCode !== suggestedAddress.postalCode) {
                addressStatus.valid = false;
                addressStatus.suggestedAddress = suggestedAddress;
                return addressStatus;
            }
        }
        return addressStatus;
    }
    public mapGoogleAddress(addressDetails: AddressComponent[]): WcsAddressType {
        const address: WcsAddressType = {};
        addressDetails.forEach((details) => {
            if (googleAddressComponentMap.hasOwnProperty(details.componentType)) {
                address[googleAddressComponentMap[details.componentType]] =
                    details.componentName.text;
            }
        });
        if (address.streetNumber && address.streetName) {
            address.address1 = `${address.streetNumber} ${address.streetName}`;
        }
        return address;
    }
}
