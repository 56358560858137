export const getFullName = (
    firstName: string | undefined,
    middleName: string | undefined,
    lastName: string | undefined
): string => {
    if (middleName === 'N/A') {
        middleName = undefined;
    }
    return [firstName, middleName, lastName]
        .map((name) => (name || '').trim())
        .filter(Boolean)
        .join(' ');
};

export const getFormI9WorkerFullName = (
    worker: ComplianceWorker | Partial<RightToWorkWorkerType>,
    withMiddleName = true
): string => {
    return worker
        ? getFullName(
              worker.firstName,
              withMiddleName ? worker.middleName?.charAt(0) : undefined,
              worker.lastName
          )
        : '';
};

export const getWorkerFullName = (
    worker: ComplianceWorker | Partial<RightToWorkWorkerType> | WorkerNode,
    withMiddleName = true
): string => {
    return worker
        ? getFullName(
              worker.firstName,
              withMiddleName ? worker.middleName : undefined,
              worker.lastName
          )
        : '';
};

export const getFlexId = (
    worker: ComplianceWorker | Partial<RightToWorkWorkerType>
): string | null => {
    return worker?.flexWorkerId ?? null;
};

export const governmentIdLabelMap: Record<GovernmentIdType, string> = {
    /* eslint-disable @typescript-eslint/naming-convention */
    BIOMETRIC_RESIDENCE_PERMIT: 'Biometric residence permit',
    BIRTH_CERTIFICATE: 'Birth certificate',
    DRIVERS_LICENSE: 'Driving license',
    NATIONAL_INSURANCE: 'National insurance',
    PASSPORT: 'Passport',
    PHOTO_ID: 'Document Photos',
    SELFIE: 'Selfie verification',
    SSN: 'Social security number',
    ADMISSION_NUMBER: 'Admission number',
    ALIEN_NUMBER: 'Alien number',
    RIGHT_TO_WORK_VERIFICATION_DOCUMENT: 'Right to work verification document',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const isAdminUser = (roles: Roles[]): boolean =>
    roles.some((role) => ['uk_admin', 'us_admin'].includes(role));
