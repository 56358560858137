import React, { PropsWithChildren, RefAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type ButtonLinkProps = LinkProps<unknown> & RefAttributes<HTMLAnchorElement>;

/**
 * Designed to be used with a <Button> as the top level child element
 * If the <Button> is disabled, then render a span instead of a <Link> to disabled the link as well
 * e.g.
 * <ButtonLink to="/next-page">
 *     <Button disabled={disabled}>Continue</Button>
 * </ButtonLink>
 */
const ButtonLink = ({ children, ...rest }: PropsWithChildren<ButtonLinkProps>): JSX.Element => {
    const disabled = React.Children.map(React.Children.only(children), (child) => {
        return React.isValidElement(child) && child.props.disabled;
    })![0];
    return disabled ? <span>{children}</span> : <Link {...rest}>{children}</Link>;
};

export default ButtonLink;
