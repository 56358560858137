import React from 'react';
import { Heading } from '@indeed/ifl-components';
import { Redirect, useParams } from 'react-router-dom';

import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import Page from 'client/components/Page';
import PageContent from 'client/pages/federaltaxes/components/PageContent';
import { LoadingPage } from 'client/components/Loading';
import { indexLocation } from 'client/utils/redirect';
import { isFederalTaxTask } from 'client/utils/task';
import { useTasks } from 'client/contexts/TasksContext';

type SlugParam = {
    slug?: string;
};

const FederalTaxesPage = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();
    const { completed, loading, tasks } = useTasks();
    const task = tasks.find((t) => t.slug === slug);

    if (!completed || loading) {
        return <LoadingPage />;
    }

    if (!isFederalTaxTask(task)) {
        return <Redirect to={indexLocation} />;
    }

    return (
        <Page title="Pay Status - Federal Taxes">
            <ComplianceContainer>
                <BackButton />
                <Heading level={4}>Pay - Federal Taxes</Heading>
                <PageContent task={task!} />
            </ComplianceContainer>
        </Page>
    );
};

export default FederalTaxesPage;
