import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import partial from 'lodash/partial';

/**
 * This file is used to type local storage data that we want to use for instance:
 * Dev feature flags
 * Cached user preferences (i.e. requisition table page size) - I would hold off on that (see details below)
 *
 * The local preferences are available in the browser console globally (`webappLocalStorage`) however you will lose the typing you get in typescript
 *
 * To add a flag update `availablePreferences` with your flag and it's default value
 * I think moving forward we should move this logic to a utility file or library and then we can have those configurations be at the top
 * Additionally this doesn't support nesting configurations but we might want that as well - but we need to consider the fact the keys are used as is and might have conflicts
 * Lastly let's use this as a dev only feature until we flesh out the above details, I think using this for minimal client configurations like page size is ideal however we need to be careful about updating once we introduce that
 *
 * nil-able values are currently not supported
 *
 * Usage
 * ```ts
 * webappLocalPreferences.ukComplianceEnabled = true;
 * webappLocalPreferences.ukComplianceEnabled = false;
 * webappLocalPreferences.clear('ukComplianceEnabled');
 * ```
 */

/**
 * Utility type to convert the string to it's type
 * Could be more complicated in the future if we start including nil-able types
 */
type TypeMap = {
    boolean: boolean;
    number: number;
};

/**
 * ############################ ############################
 * ##################### Configuration #####################
 * ############################ ############################
 */

/**
 * A tuple that utilizes a string rep of a type and the default value
 */
type PreferenceOption<Key extends keyof TypeMap = keyof TypeMap> = readonly [Key, TypeMap[Key]];

/**
 * A list of keys with their associated types and default values
 */
const availablePreferences = {
    ukComplianceEnabled: ['boolean', false],
    /**
     * For debugging purposes, output metrics logged using `useLogEvent`.
     * Defaults to false
     */
    consoleLogEvents: ['boolean', false],
    /**
     * For debugging purposes, output errors to js console.
     * For now this is only used in `reportErrorToThirdPartyTools` and does not include SentryErrorBoundary
     * Defaults to false
     */
    consoleLogSentryErrors: ['boolean', false],
    /**
     * For debugging purposes, outputs datadog action logs to console.
     * For now this is only used in `LogRenderDatadogAction` and it's corresponding hook
     * Defaults to false
     */
    consoleLogDatadogActions: ['boolean', false],
} as const;
// Would love this validation to be inline, however if we do we lose implicit typing of valid keys
availablePreferences as { [key: string]: PreferenceOption };
type AvailablePreferencesKeys = keyof typeof availablePreferences;

/**
 * ############################ ############################
 * ################### Utility Functions ###################
 * ############################ ############################
 */

/**
 * Get's the item from local storage
 * Abstracted so we can add our own prefix
 * @param key is our key
 * @returns the value from local storage
 */
const getLocalStorageValue = (key: string): string | null => localStorage.getItem(`WCW_${key}`);

/**
 * Try to convert the local storage value to a number
 * If result isNaN it will return null
 * @param key is the key to return it's value
 * @returns the value as a number or null if it's not a number (including not set)
 */
const getLocalStorageNum = (key: string): number | null => {
    const val = getLocalStorageValue(key);
    if (val === null) return null;
    const result = parseInt(val, 10);
    if (isNaN(result)) return null;
    return result;
};

/**
 * Try to convert the local storage value to a boolean
 * This means checking string value for explicitly "true" for true, "false" for false otherwise it returns null
 * @param key is the key to return it's value
 * @returns the value as a boolean or null if it's not a boolean (including not set)
 */
const getLocalStorageBoolean = (key: string): boolean | null => {
    const val = getLocalStorageValue(key);
    switch (val) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return null;
    }
};

/**
 * A map of conversion function
 */
const CONVERSIONS = {
    number: getLocalStorageNum,
    boolean: getLocalStorageBoolean,
} as const;

type ConversionKeys = keyof typeof CONVERSIONS;

/**
 * Set local preference value (uses raw strings)
 * If nil (null || undefined) then remove the item
 * @param key is a string to set
 * @param val is the value to store
 */
const setLocalStorageValue = (key: string, val: string | null | undefined): void => {
    const adjustedKey = `WCW_${key}`;
    if (isNil(val)) {
        localStorage.removeItem(adjustedKey);
    } else {
        localStorage.setItem(adjustedKey, val);
    }
};

/**
 * This function fetches the value and tries to convert it to the right type
 * On mistyped (which might just be unset) it will return the default value
 * @param key is the key to fetch
 * @returns
 */
const getLocalStorageTypedValue = <Key extends ConversionKeys>(
    key: AvailablePreferencesKeys
): ReturnType<(typeof CONVERSIONS)[Key]> => {
    const [localTypeValue, defaultValue] = availablePreferences[key];
    // Don't like the as but it get's confused
    return (CONVERSIONS[localTypeValue](key) ?? defaultValue) as ReturnType<
        (typeof CONVERSIONS)[Key]
    >;
};

/**
 * ############################ ############################
 * ######################### Logic #########################
 * ############################ ############################
 */

type WebappLocalPreferencesValueType = {
    -readonly [Key in AvailablePreferencesKeys]: TypeMap[(typeof availablePreferences)[Key][0]];
};

/**
 * Adds the getters and setters to an object
 * This needs to be done so we can attach getters and setters to the root of the object with the utility function
 * Spreading will actually evaluate the getter which is why this is necessary
 * @param obj is the object to bind the getters and setters on
 * @returns The object passed in (which has been mutated)
 */
const bindWebappLocalPreferencesValues = <T extends object>(
    obj: T
): WebappLocalPreferencesValueType & T =>
    Object.entries(availablePreferences).reduce(
        (result, [wcwLocalPreferenceKeyRaw, typeString]) => {
            // We lose the more specific type when using Object.entries
            const wcwLocalPreferenceKey = wcwLocalPreferenceKeyRaw as AvailablePreferencesKeys;
            Object.defineProperty(result, wcwLocalPreferenceKey, {
                get: partial(getLocalStorageTypedValue, wcwLocalPreferenceKey, typeString),
                set: partial(setLocalStorageValue, wcwLocalPreferenceKey),
                enumerable: true,
            });
            return result;
        },
        // This is all generated dynamically and so "as" is necessary
        obj as WebappLocalPreferencesValueType & T
    );

/**
 * This is the object that should be interfaced with when dealing with local storage
 */
export const wcwLocalPreferencesValues = bindWebappLocalPreferencesValues({});

/**
 * The actual return type generated from `availablePreferences`
 */
type WebappLocalPreferencesType = WebappLocalPreferencesValueType & {
    /**
     * These are the local preference values set, included both on the root and a nested object
     */
    values: WebappLocalPreferencesValueType;
    /**
     * Outputs json representation
     * Excludes non value types (currently toString, clear, reset)
     * If other utilities are added to the object we need to add it to array below
     * Could also move these to separate functions or nest data lower
     */
    toString: () => string;
    /**
     * Unsets a specific value
     */
    clear: (key: AvailablePreferencesKeys) => void;
    /**
     * Clears all values
     */
    reset: () => void;
};

/**
 * The values along with utility functions
 * This is the result which is exported and bound to the window
 */
export const wcwLocalPreferences: WebappLocalPreferencesType = bindWebappLocalPreferencesValues({
    values: wcwLocalPreferencesValues,
    toString: () => {
        return JSON.stringify(wcwLocalPreferencesValues);
    },
    clear: (key: AvailablePreferencesKeys) => {
        setLocalStorageValue(key, null);
    },
    reset: () => {
        // Dynamic call to reset keys
        // All keys are fine to reset since it's in values
        // eslint-disable-next-line guard-for-in
        for (const key in wcwLocalPreferencesValues) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (wcwLocalPreferencesValues as any)[key] = null;
        }
    },
});

declare global {
    interface Window {
        wcwLocalPreferences: WebappLocalPreferencesType;
    }
}

/**
 * Make interface available to the console
 */
window.wcwLocalPreferences = wcwLocalPreferences;
