import BackButton from 'client/components/BackButton';
import Block from 'client/components/Block';
import DocumentDownloadModal from './components/DocumentDownloadModal';
import Loading from 'client/components/Loading';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import SignAndAcknowledgeBlock from 'client/components/SignAndAcknowledgeBlock';
import dayjs from 'dayjs';
import { Box, Button, Flex, Heading, TextLink } from '@indeed/ifl-components';
import { Document, Page } from 'react-pdf';
import { ErrorBoundary } from 'react-error-boundary';
import {
    FlexComplianceUkEmail,
    FlexComplianceUsEmail,
    FlexHelpEmail,
} from 'client/components/Emails/Emails';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { SignAndAcknowledgeValues } from 'client/components/SignAndAcknowledgeBlock/SignAndAcknowledgeBlock';
import {
    byTaskLocation,
    getMatchingWorkerDocument,
    getMediaRequirementsMetadata,
    getTaskDocuments,
    getTaskWithNextDocuments,
    isConditionalJobOfferTask,
    isDocumentToggleable,
} from 'client/utils/task';
import { getRedirectUrlFromStorage, indexLocation } from 'client/utils/redirect';
import { logDocumentEvent } from 'client/utils/datadog';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useCreateWorkerDocumentMutation, useDownloadDocumentQuery } from 'client/hooks/graphql';
import { useTasks } from 'client/contexts/TasksContext';
import { useToast } from 'client/contexts/ToastContext';
import { useWorker } from 'client/contexts/WorkerContext';

type SlugParams = {
    slug?: string;
    templateSlug?: string;
};

type HtmlContentDisplayProps = {
    htmlContent: string;
};

const HtmlContentDisplay = ({ htmlContent }: HtmlContentDisplayProps): JSX.Element => {
    return (
        <Block>
            <div
                // eslint-disable-next-line @typescript-eslint/naming-convention
                dangerouslySetInnerHTML={{ __html: htmlContent }}
                style={{ margin: 0, padding: 0 }}
            />
        </Block>
    );
};

interface ExternalFormLinkDisplayProps {
    workerCountry?: Maybe<WorkercomplianceserviceappWorkerCountryChoices>;
}
const ExternalFormLinkDisplay = ({ workerCountry }: ExternalFormLinkDisplayProps): JSX.Element => {
    return (
        <>
            <p>
                The button below will navigate you to the training that needs to be completed. After
                completing the training, there may be some time before this training is marked as
                completed.
            </p>
            <p>
                If the training is not marked as completed within a day, please contact{' '}
                {workerCountry === 'US' ? <FlexComplianceUsEmail /> : <FlexComplianceUkEmail />}.
            </p>
        </>
    );
};

type PdfDisplayProps = {
    templateSlug: string;
    onPageRendered: () => void;
    documentCompleted: boolean;
    onButtonClick: () => void;
};

const PdfDisplay = ({
    templateSlug,
    onPageRendered,
    documentCompleted,
    onButtonClick,
}: PdfDisplayProps): JSX.Element => {
    const {
        data: queryData,
        loading: queryLoading,
        error: queryError,
    } = useDownloadDocumentQuery({
        variables: {
            slug: templateSlug,
            documentType: 'TEMPLATE',
        },
    });
    const { worker, completed: completedWorker, loading: loadingWorker } = useWorker();
    const pdfContainerRef = useRef<HTMLDivElement>(null);
    const [numPdfPages, setNumPdfPages] = useState(0);
    const [pdfWidth, setPdfWidth] = useState(0);

    const calculatePdfSize = (): void => {
        if (pdfContainerRef.current) {
            setPdfWidth(pdfContainerRef.current.offsetWidth);
        }
    };

    // Calculate size on first load when the ref is attached
    useEffect(calculatePdfSize, [pdfContainerRef.current]);

    useEffect(() => {
        window.addEventListener('resize', calculatePdfSize);
        return () => {
            window.removeEventListener('resize', calculatePdfSize);
        };
    }, []);

    if (!completedWorker || loadingWorker || queryLoading || !worker) {
        return (
            <Block>
                <Loading />
            </Block>
        );
    }

    const errorBlock = (
        <Block>
            There was an error while attempting to retrieve the document. Refresh the page and try
            again.
        </Block>
    );

    const documentUrl = queryData?.downloadDocument?.url;
    if (queryError || !documentUrl) {
        reportErrorToThirdPartyTools(
            new Error(
                `Could not retrieve result from download document query given input: documentSlug:${templateSlug}, documentType:TEMPLATE`
            ),
            'documentDisplayPage',
            'download-document'
        );

        return errorBlock;
    }

    const onPdfLoadError = (error: Error): void => {
        reportErrorToThirdPartyTools(
            new Error(`Could not retrieve PDF from returned URL '${documentUrl}': ${error}`),
            'documentDisplayPage',
            'pdf-loading'
        );
    };

    const isExternalFormUrl = documentUrl.includes('forms.indeedflex');
    const redirectUrl = getRedirectUrlFromStorage();

    return (
        <>
            <Block>
                {!isExternalFormUrl ? (
                    <Document
                        file={{
                            url: documentUrl,
                        }}
                        renderMode="canvas"
                        loading={<Loading />}
                        error={errorBlock}
                        onLoadSuccess={({ numPages }) => {
                            setNumPdfPages(numPages);
                        }}
                        onLoadError={onPdfLoadError}
                        onSourceError={onPdfLoadError}
                        inputRef={pdfContainerRef}
                    >
                        {Array.from(Array(numPdfPages), (e, i) => (
                            <React.Fragment key={i}>
                                {i !== 0 && <br />}
                                <Page
                                    pageNumber={i + 1}
                                    width={pdfWidth}
                                    onRenderSuccess={onPageRendered}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                />
                            </React.Fragment>
                        ))}
                    </Document>
                ) : (
                    <ExternalFormLinkDisplay workerCountry={worker.country} />
                )}
            </Block>
            {isExternalFormUrl && !documentCompleted && (
                <TextLink
                    href={`${documentUrl}?workerSlug=${worker.slug}${
                        redirectUrl ? '&redirect_to=' + encodeURIComponent(redirectUrl) : ''
                    }`}
                    rel="noopener"
                >
                    <Button
                        sx={{ width: ['100%', 'auto'], marginBlockStart: 4 }}
                        onClick={onButtonClick}
                    >
                        Complete training
                    </Button>
                </TextLink>
            )}
        </>
    );
};

type DocumentDisplayPageProps = {
    baseUrl: string;
};

const DocumentDisplayPage = ({ baseUrl }: DocumentDisplayPageProps): JSX.Element => {
    const { slug, templateSlug } = useParams<SlugParams>();
    const history = useHistory();
    const { tasks, updateTask } = useTasks();
    const { pushToast } = useToast();
    const [callCreateWorkerDocumentMutation, { loading: createWorkerDocumentLoading }] =
        useCreateWorkerDocumentMutation();
    const [enableCtas, setEnableCtas] = useState(false);
    let nextTaskSlug = slug;

    if (!slug || !templateSlug) return <Redirect to={indexLocation} />;

    const task = tasks.find((t) => t.slug === slug) || null;
    if (!task) return <Redirect to={indexLocation} />;

    const { templates, workerDocuments } = getTaskDocuments(task);
    const documentTemplate = templates.find((template) => template.slug === templateSlug);
    if (!documentTemplate) return <Redirect to={indexLocation} />;
    const workerDocument = getMatchingWorkerDocument(documentTemplate, workerDocuments);

    const templateIndex = templates.indexOf(documentTemplate);
    let nextUnsignedTemplate = templates.find(
        (template, index) =>
            index > templateIndex && !getMatchingWorkerDocument(template, workerDocuments)
    );

    const isNonLocationMediaTask = (currentTask: ComplianceTask): boolean =>
        currentTask.type === 'MEDIA_REQUIREMENTS' &&
        !getMediaRequirementsMetadata(currentTask)?.location;

    /**
     * Search all tasks for user (sorted) where TaskType is the same (if it's a location or media requirement task).
     * Get the first task where there is an unsigned doc and the first template out of there.
     */
    if (
        !nextUnsignedTemplate &&
        (task.type === 'LOCATION_REQUIREMENTS' || task.type === 'MEDIA_REQUIREMENTS')
    ) {
        const nonLocationMediaTasks = tasks.filter((t) => isNonLocationMediaTask(t));
        const locationOrMediaTasks = tasks.filter(
            (t) => t.type === task.type && !isNonLocationMediaTask(t)
        );
        const nextTask = getTaskWithNextDocuments(
            [...nonLocationMediaTasks, ...locationOrMediaTasks.sort(byTaskLocation)],
            task
        );
        const { templates: nextTemplates, workerDocuments: nextWorkerDocuments } = nextTask
            ? getTaskDocuments(nextTask)
            : { templates: null, workerDocuments: null };
        nextUnsignedTemplate = nextTemplates?.find(
            (template) => !getMatchingWorkerDocument(template, nextWorkerDocuments)
        );
        nextTaskSlug = nextTask?.slug;
    }
    /**
     * For location or media requirement tasks, baseUrl is still ':slug' and either needs to be replaced
     * with the current task or the next task if all documents have been finished for the current task.
     */
    const onDocumentCompleteToUrl =
        !nextUnsignedTemplate &&
        (task.type === 'LOCATION_REQUIREMENTS' || task.type === 'MEDIA_REQUIREMENTS')
            ? baseUrl.replace(
                  ':slug',
                  task.type === 'LOCATION_REQUIREMENTS'
                      ? 'location-requirements'
                      : 'media-requirements'
              )
            : `${baseUrl.replace(':slug', nextTaskSlug ? nextTaskSlug : slug)}${
                  nextUnsignedTemplate ? `/document/${nextUnsignedTemplate.slug}` : ''
              }`;

    useEffect(() => {
        if (documentTemplate.htmlContent || documentTemplate.requirement === 'MEDIA') {
            setEnableCtas(true);
        }
    }, [documentTemplate]);

    const findFirstAvailableWorkerDocumentWithSignature = (): TaskWorkerDocumentType | null => {
        const currentTaskDocument = workerDocuments.find((wd) => !!wd.signature);
        if (currentTaskDocument) {
            return currentTaskDocument;
        }
        const cjoTask = tasks.find((t) => isConditionalJobOfferTask(t));
        if (cjoTask) {
            const { workerDocuments: cjoWorkerDocuments } = getTaskDocuments(cjoTask);
            const cjoTaskDocument = cjoWorkerDocuments
                ? cjoWorkerDocuments.find((wd) => !!wd.signature)
                : undefined;
            if (cjoTaskDocument) {
                return cjoTaskDocument;
            }
        }
        return null;
    };

    /**
     * In the future when the profile task is editable, this should come from there instead,
     * but for now, use existing worker documents to carry the same signature through multiple documents
     */
    const getAuthorizationSignature = (firstName: string, lastName: string): string => {
        const existingWorkerDocument = findFirstAvailableWorkerDocumentWithSignature();
        if (existingWorkerDocument?.signature) {
            return existingWorkerDocument.signature;
        }
        if (firstName && lastName) {
            return `${firstName} ${lastName}`;
        }
        throw new Error(
            'Unable to create signature. No previously existing worker documents and no name provided'
        );
    };

    /**
     * Returns a new list of documents. If the previousDocuments list contains a document of the same
     * templateType as createdWorkerDocument, the old document is replaced with the new one. Otherwise
     * the new document is just appended
     */
    const consolidateWorkerDocuments = (
        previousDocuments: TaskWorkerDocumentType[],
        createdWorkerDocument: TaskWorkerDocumentType
    ): TaskWorkerDocumentType[] => {
        return [
            ...previousDocuments.filter(
                (doc) => doc.templateType !== createdWorkerDocument.templateType
            ),
            createdWorkerDocument,
        ];
    };

    /**
     * Calls the createWorkerDocumentMutation and stops displaying CTAs to prevent duplicate submissions
     * @param mutationInput
     */
    const createWorkerDocument = (mutationInput: CreateWorkerDocumentInput): void => {
        // Don't allow duplicate submissions, user should refresh page if they have issues
        setEnableCtas(false);
        callCreateWorkerDocumentMutation({
            variables: {
                input: mutationInput,
            },
        })
            .then((result) => {
                const createdWorkerDocument = result.data?.createWorkerDocument?.result;
                if (createdWorkerDocument) {
                    const updatedTaskValue = {
                        ...task,
                        metadata: {
                            ...task.metadata,
                            workerDocuments: consolidateWorkerDocuments(
                                workerDocuments,
                                createdWorkerDocument
                            ),
                        } as LocationRequirementsMetadataType,
                    };
                    logDocumentEvent(task.type, 'signCheckboxClicked');
                    updateTask(updatedTaskValue);
                    history.push({
                        pathname: onDocumentCompleteToUrl,
                        search: window.location.search,
                    });
                } else {
                    reportErrorToThirdPartyTools(
                        new Error(
                            `Could not retrieve result from createWorkerDocument mutation given input: templateSlug:${templateSlug}, action:${mutationInput.action}, signature:${mutationInput.signature}, signatureDate:${mutationInput.signatureDate}`
                        ),
                        'documentDisplayPage',
                        'createWorkerDocument'
                    );
                    pushToast(
                        'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                        { status: 'warning' }
                    );
                }
            })
            .catch((apolloError) => {
                reportErrorToThirdPartyTools(
                    apolloError,
                    'documentDisplayPage',
                    'createWorkerDocument'
                );
                pushToast(
                    'There was an error while attempting to submit the information, please try again.',
                    { status: 'critical' }
                );
            });
    };

    const logAcknowledgeEvent = (): void => {
        const payload = {
            documentType: documentTemplate.type,
        };
        logDocumentEvent(task.type, 'submitBtnClicked', payload);
    };

    const signDocument = ({ firstName, lastName }: SignAndAcknowledgeValues): void => {
        createWorkerDocument({
            templateSlug,
            action: 'SIGNED',
            signature: getAuthorizationSignature(firstName, lastName),
            signatureDate: dayjs().format('YYYY-MM-DD'),
            taskSlug: task.slug,
        });
    };

    const performOtherDocumentAction = (workerAction: WorkerDocumentAction): void => {
        createWorkerDocument({
            templateSlug,
            action: workerAction,
            taskSlug: task.slug,
        });
    };

    const onAcknowledge = (values: SignAndAcknowledgeValues): void => {
        logAcknowledgeEvent();
        if (documentTemplate.requirement === 'ACKNOWLEDGE') {
            performOtherDocumentAction('ACKNOWLEDGED');
        } else if (documentTemplate.requirement === 'MEDIA') {
            history.push({ pathname: onDocumentCompleteToUrl, search: window.location.search });
        } else {
            signDocument(values);
        }
    };

    const onPdfButtonClick = (): void => {
        const payload = {
            documentType: documentTemplate.type,
        };
        logDocumentEvent(task.type, 'completeTrainingClicked', payload);
    };

    const onDecline = (): void => {
        // Avoid situation where a non sign-decline document could be declined
        if (documentTemplate.requirement === 'SIGN_OR_DECLINE') {
            performOtherDocumentAction('DECLINED');
        }
    };

    // If there have been no worker documents created for the user, show the name block
    // This should be replaced with showNameOnFirst once this hack is removed and signatures
    // are based off of legal name from the PROFILE task
    const showNameFields =
        !findFirstAvailableWorkerDocumentWithSignature() &&
        documentTemplate.requirement !== 'ACKNOWLEDGE' &&
        documentTemplate.requirement !== 'MEDIA';
    const signBlock = (
        // Use a key to ensure that the component is remounted and clears fields om document change
        <Box
            sx={{
                position:
                    documentTemplate.requirement === 'SIGN_AND_SCROLL' ||
                    documentTemplate.htmlContent
                        ? 'relative'
                        : 'sticky',
                bottom: '0',
                left: '0',
                right: '0',
                backgroundColor: 'neutral.0',
                padding: documentTemplate.requirement === 'ACKNOWLEDGE' ? '1rem' : null,
                margin: documentTemplate.requirement === 'ACKNOWLEDGE' ? '0 -1rem' : null,
            }}
        >
            <SignAndAcknowledgeBlock
                key={templateSlug}
                onAcknowledge={onAcknowledge}
                onDecline={onDecline}
                documentRequirement={documentTemplate.requirement}
                showNameFields={showNameFields}
                isToggleable={isDocumentToggleable(documentTemplate)}
                existingWorkerDocument={workerDocument}
            />
        </Box>
    );

    const getTemplateContent = (): JSX.Element => {
        if (documentTemplate.htmlContent) {
            return <HtmlContentDisplay htmlContent={documentTemplate.htmlContent} />;
        } else if (documentTemplate.documentSlug) {
            return (
                <PdfDisplay
                    templateSlug={documentTemplate.slug}
                    onPageRendered={() => {
                        if (!enableCtas) {
                            setEnableCtas(true);
                        }
                    }}
                    documentCompleted={!!workerDocument}
                    onButtonClick={onPdfButtonClick}
                />
            );
        }
        throw new Error(`No content associated with document with slug ${templateSlug}`);
    };

    // If any toasts get pushed, the page gets re-rendered, causing the PDF to go through the whole
    // loading process again. Using useMemo to prevent this
    const templateContent = useMemo(getTemplateContent, [documentTemplate]);

    const displaySignBlock =
        (!workerDocument || isDocumentToggleable(documentTemplate)) && enableCtas;

    return (
        <>
            <Flex
                sx={{
                    flexDirection: 'column',
                    height: documentTemplate.requirement !== 'ACKNOWLEDGE' ? null : [null, '90vh'],
                }}
            >
                <BackButton
                    sx={{ justifyContent: 'flex-start' }}
                    onClick={() => {
                        logDocumentEvent(task.type, 'backBtnClicked');
                        history.push({
                            pathname:
                                task.type === 'LOCATION_REQUIREMENTS' ||
                                task.type === 'MEDIA_REQUIREMENTS'
                                    ? baseUrl.replace(
                                          ':slug',
                                          task.type === 'LOCATION_REQUIREMENTS'
                                              ? 'location-requirements'
                                              : 'media-requirements'
                                      )
                                    : baseUrl.replace(':slug', task.slug),
                            state: { skipRedirect: true },
                            search: window.location.search,
                        });
                    }}
                />

                {workerDocument && (
                    <DocumentDownloadModal
                        template={documentTemplate}
                        workerDocument={workerDocument}
                        taskType={task.type}
                    />
                )}

                {documentTemplate.title && <Heading level={4}>{documentTemplate.title}</Heading>}
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: documentTemplate.htmlContent ? null : ['clip', 'scroll'],
                    }}
                >
                    {templateContent}
                </Box>
                {displaySignBlock && documentTemplate.requirement !== 'MEDIA' && signBlock}
                {createWorkerDocumentLoading && <Loading />}
            </Flex>
        </>
    );
};

const DocumentDisplayPageWrapper = (props: DocumentDisplayPageProps): JSX.Element => (
    <ErrorBoundary
        onError={(error) =>
            reportErrorToThirdPartyTools(
                new Error(
                    `Could not load the display page for document ${props.baseUrl}. ${error}`
                ),
                'documentDisplayPage',
                'displayDocument'
            )
        }
        fallbackRender={() => (
            <Block>
                There was an error while attempting to display the document. Refresh the page and
                try again. If you continue having issues, please contact <FlexHelpEmail />
            </Block>
        )}
    >
        <DocumentDisplayPage {...props} />
    </ErrorBoundary>
);

export default DocumentDisplayPageWrapper;
