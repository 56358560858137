import * as FormikFields from 'client/components/FormikFields';
import BackButton from 'client/components/BackButton';
import FormRow from 'client/components/FormRow';
import React from 'react';
import useCompleteUkDocuments from 'client/hooks/useCompleteUkDocuments';
import { Box, Button, Flex, Heading, Text } from '@indeed/ifl-components';
import { Col } from '@indeed/itmp-components';
import { Form, Formik } from 'formik';
import {
    HealthFormValues,
    getInitialHealthFormValues,
    healthFormValidationSchema,
    toCompleteUkDocumentsInput,
} from 'client/pages/ukdocuments/components/FormUtils/formConstants';
import { UkDocumentsTaskTrackingEvents } from 'client/pages/ukdocuments/UkDocuments.tracking';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory } from 'react-router-dom';
import { yesNoOptions } from 'client/utils/options';

export interface HealthFormProps {
    task: ComplianceTask;
}

const agreeLabel = (
    <Flex sx={{ flexDirection: 'column' }}>
        <Text sx={{ marginBlockEnd: 4 }}>
            I declare that the information given in this health questionnaire is true and complete.
            I understand that any misleading information or any omissions could result in the
            termination of my contract.
        </Text>
        <Text sx={{ marginBlockEnd: 4 }}>
            I will notify you immediately if any of my answers change in the completed
            questionnaire.
        </Text>
        <Text sx={{ marginBlockEnd: 4 }}>
            The information provided by you on this form will be stored either on paper records or a
            computer system in accordance with GDPR and will be processed solely in connection with
            the recruitment process.
        </Text>
    </Flex>
);

const HealthForm = ({ task }: HealthFormProps): JSX.Element => {
    const history = useHistory();
    const isCompleted = task.status === 'COMPLETED';
    const [callCompleteUkDocuments] = useCompleteUkDocuments(
        task,
        `/uk-documents/${task.slug}`,
        'UkDocumentsHealthForm'
    );

    const onSubmit = async (values: HealthFormValues): Promise<void> => {
        datadogRum.addAction(UkDocumentsTaskTrackingEvents.healthFormSubmitBtnClicked);
        const input = toCompleteUkDocumentsInput(task.slug, values);
        await callCompleteUkDocuments(input);
    };

    return (
        <Box>
            <BackButton onClick={() => history.push(`/uk-documents/${task.slug}`)} />
            <Heading level={4}>Health Questionnaire</Heading>
            <Box sx={{ marginBlockStart: 4 }}>
                <Formik
                    initialValues={getInitialHealthFormValues(task)}
                    validationSchema={healthFormValidationSchema}
                    onSubmit={onSubmit}
                >
                    {(formik) => (
                        <Form>
                            <FormRow>
                                <Col>
                                    <Text level={3}>
                                        It is a legal requirement to know your health history for
                                        selected occupations. You need to complete this
                                        questionnaire to apply for jobs.
                                    </Text>
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col>
                                    <FormikFields.RadioField
                                        label="Do you have any medical condition which may affect your work?"
                                        name="medicalCondition"
                                        items={yesNoOptions}
                                        radioProps={{
                                            disabled: isCompleted,
                                            showRequiredIndicator: true,
                                            className: 'dd-privacy-hidden',
                                            onChange: (event) => {
                                                const value = (event.target as HTMLInputElement)
                                                    .value;
                                                if (value === 'false') {
                                                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                                    formik.setFieldValue('medicalAdjustments', '');
                                                }
                                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                                formik.setFieldValue('medicalCondition', value);
                                            },
                                        }}
                                    />
                                </Col>
                            </FormRow>
                            {formik.values.medicalCondition === 'true' && (
                                <>
                                    <FormRow>
                                        <Col>
                                            <Text as="p">Please tell us if:</Text>
                                            <Text as="p">
                                                There are any reasonable adjustments we can make to
                                                assist you while at work?
                                            </Text>
                                            <Text as="p">
                                                There are any reasonable adjustments we can make to
                                                the job itself to help you carry it out?
                                            </Text>
                                        </Col>
                                    </FormRow>
                                    <FormRow>
                                        <Col>
                                            <FormikFields.TextField
                                                name="medicalAdjustments"
                                                label="Please provide details below"
                                                inputProps={{
                                                    className: 'dd-privacy-hidden',
                                                    disabled: isCompleted,
                                                }}
                                            />
                                        </Col>
                                    </FormRow>
                                </>
                            )}
                            <FormRow sx={{ marginBlockStart: 4 }}>
                                <Col>
                                    <FormikFields.CheckboxField
                                        name="agree"
                                        items={[
                                            {
                                                label: agreeLabel,
                                                value: 'true',
                                                disabled: isCompleted,
                                            },
                                        ]}
                                    />
                                </Col>
                            </FormRow>
                            {!isCompleted && (
                                <FormRow>
                                    <Col>
                                        <Button
                                            type={'submit'}
                                            loading={formik.isSubmitting}
                                            disabled={
                                                !formik.isValid ||
                                                !formik.dirty ||
                                                formik.isSubmitting
                                            }
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </FormRow>
                            )}
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default HealthForm;
