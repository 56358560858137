import Form from 'client/pages/bankdetails/components/Form';
import React, { useEffect } from 'react';
import { A11yPageTitle } from 'client/components/Page';
import {
    BANK_DETAILS_VALIDATION_SCHEMA,
    BankDetailsFormValues,
    getInitialBankDetailsValues,
    toCompleteBankDetailsSsnInput,
} from 'client/pages/bankdetails/components/Form/formConstants';
import { Box, Heading } from '@indeed/ifl-components';
import { DatadogAction } from 'client/utils/logging';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { Route, useRouteMatch } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useClientConfig } from 'client/contexts/ClientConfigContext';
import { useCompleteBankDetailsSsnMutation } from 'client/hooks/graphql';
import { useToast } from 'client/contexts/ToastContext';

type ProfileFormRouterProps = {
    task: ComplianceTask;
};

const FormRouter = ({ task }: ProfileFormRouterProps): JSX.Element => {
    const { pushToast } = useToast();
    const { path } = useRouteMatch();
    const [callCompleteBankDetailsSsnMutation] = useCompleteBankDetailsSsnMutation();
    const config = useClientConfig();
    const onSubmit = (
        values: BankDetailsFormValues,
        formikHelpers: FormikHelpers<BankDetailsFormValues>
    ): void => {
        datadogRum.addAction(DatadogAction.bankDetailsSubmitted);
        const input = toCompleteBankDetailsSsnInput(task, values);
        callCompleteBankDetailsSsnMutation({
            variables: { input },
            refetchQueries: ['GetTasks'],
        })
            .then(
                ({ data }) => {
                    const result = data?.completeBankDetailsSsn?.result;
                    if (result) {
                        // redirect to pay to collect bank details
                        window.location.replace(config.payrollBankDetailsUrl!);
                    } else {
                        reportErrorToThirdPartyTools(
                            new Error('Error submitting ssn'),
                            'bankDetailsPage',
                            'onFormSubmit'
                        );
                        pushToast(
                            'There was an error while submitting your ssn, please try again.'
                        );
                    }
                },
                (apolloError) => {
                    reportErrorToThirdPartyTools(apolloError, 'bankDetailsPage', 'onFormSubmit');
                    pushToast(
                        apolloError?.graphQLErrors[0]?.message ??
                            'An error has occurred, please try again.',
                        { status: 'critical' }
                    );
                }
            )
            .finally(() => formikHelpers.setSubmitting(false));
    };
    useEffect(() => {
        if (task.externalStatus) {
            // SSN step has already been completed
            window.location.replace(config.payrollBankDetailsUrl!);
        }
    });

    return (
        <Formik
            initialValues={getInitialBankDetailsValues()}
            validationSchema={BANK_DETAILS_VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <FormikForm>
                    <Route path={path} exact>
                        <Box
                            sx={{
                                maxWidth: ['auto', '24rem'],
                                mx: [0, 'auto'],
                            }}
                        >
                            <A11yPageTitle title="SSN Form" />
                            <Heading level={4}>Bank Details</Heading>
                            <Form formik={formik} />
                        </Box>
                    </Route>
                </FormikForm>
            )}
        </Formik>
    );
};

export default FormRouter;
