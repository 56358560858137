import FormRouter from 'client/pages/profile/components/FormRouter';
import React from 'react';
import { Box, Heading, Text } from '@indeed/ifl-components';
import { formatDateWithDayOfWeek } from 'client/utils/dayjs';
import { useTasks } from 'client/contexts/TasksContext';

type PageContentProps = {
    task: ComplianceTask;
};

const DEPENDENT_TASK_TYPES: TaskType[] = ['BACKGROUND_CHECK', 'FORM_I9', 'RIGHT_TO_WORK'];

const PageContent = ({ task }: PageContentProps): JSX.Element => {
    const { tasks } = useTasks();

    const canEdit =
        task.status === 'NOT_COMPLETED' ||
        tasks
            .filter((t) => DEPENDENT_TASK_TYPES.includes(t.type))
            .every((t) => t.status === 'NOT_COMPLETED');

    if (canEdit) {
        return <FormRouter task={task} />;
    }

    if (task.status === 'COMPLETED') {
        return (
            <Box>
                <Heading level={4}>Profile</Heading>
                <Text>
                    Your profile was completed on {formatDateWithDayOfWeek(task.completedDate)}.
                </Text>
            </Box>
        );
    }

    throw new Error(`Invalid status for profile: ${task.status}`);
};

export default PageContent;
