import Cookies from 'js-cookie';
import useBackendUrl from 'client/hooks/useBackendUrl';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { RetryValue } from 'react-query/types/core/retryer';
import { useClientConfig } from 'client/contexts/ClientConfigContext';

/**
 * Utility method for retrying query calls. This is done for a maximum of 3 retries
 * after initial failure, and if the error has no response (for example, due to client
 * side network failure), or if the response status is not 4xx or 500. A 4xx status
 * is not repeated because it is likely to always remain the same. A 500 status is
 * most likely occurring due to an unhandled error and that will not change even as
 * the call gets repeated.
 * @param failureCount - failure count, starting with 0
 * @param error
 */
export const queryRetry: RetryValue<AxiosError> = (failureCount, error) => {
    return failureCount < 3 && (!error?.response || error?.response?.status > 500);
};

/**
 * Utility method to update axios call configuration with suitable defaults. It is ALSO
 * ASSUMED that all calls made from it are only to the TACO backend. Since local and
 * qa multibranch environments make CORS calls, so `withCredentials` is updated to
 * be true.
 * @param axiosRequestConfig
 */
const useAxiosConfig = (axiosRequestConfig: AxiosRequestConfig): AxiosRequestConfig => {
    const clientConfig = useClientConfig();
    let withCredentials = false;
    if (clientConfig.level === 'local' || clientConfig.level === 'qa') {
        withCredentials = true;
    }
    const url = useBackendUrl(axiosRequestConfig.url);

    const headers =
        clientConfig.passportAuthHeader && clientConfig.passportAuthHeader.length !== 0
            ? {
                  ...(axiosRequestConfig.headers || {}),
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'X-CSRFToken': Cookies.get('csrftoken'),
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  Authorization: clientConfig.passportAuthHeader,
              }
            : {
                  ...(axiosRequestConfig.headers || {}),
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'X-CSRFToken': Cookies.get('csrftoken'),
              };

    return {
        ...axiosRequestConfig,
        headers: headers,
        url,
        withCredentials,
    };
};

export default useAxiosConfig;
