import useQueryParams from 'client/hooks/useQueryParams';
let idCounter = 0;
/**
 * Generates a unique ID. If `prefix` is given, the ID is appended to it.
 * Lovingly adapted from lodash :)
 *
 * @param {string} [prefix=''] The value to prefix the ID with.
 * @returns {string} Returns the unique ID.
 * @example
 *
 * _.uniqueId('contact_');
 * // => 'contact_104'
 *
 * _.uniqueId();
 * // => '105'
 */
export const uniqueId = (prefix?: string): string => {
    idCounter += 1;
    const id = idCounter;
    return `${prefix || ''}${id}`;
};

export const checkHeader = (): boolean => {
    const params = useQueryParams();
    if (params.noheader) {
        const noHeader = params.noheader!.toString().toLowerCase() === 'true';
        localStorage.setItem('noheader', noHeader.toString());
    }
    const stored = localStorage.getItem('noheader');
    return stored === 'true';
};

export const getDateDiffInDays = (date: Date): number => {
    const now: Date = new Date();
    return Math.round((now.valueOf() - date.valueOf()) / (1000 * 60 * 60 * 24));
};
