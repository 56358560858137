import * as FormikFields from 'client/components/FormikFields';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { TextLink } from '@indeed/ifl-components';
import { UsSsnParagraph } from 'client/components/UsSsnFields/UsSsnParagraph/UsSsnParagraph';

export interface UsSsnAppliedForProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>;
    ssnFieldProps?: Partial<FormikFields.MaskedTextFieldProps>;
    ssnAppliedForFieldProps?: Partial<FormikFields.CheckboxFieldProps>;
}

export const UsSsnAppliedFor = ({
    formik,
    ssnFieldProps,
    ssnAppliedForFieldProps,
    children,
}: PropsWithChildren<UsSsnAppliedForProps>): JSX.Element => {
    const [showCheckbox, setShowCheckbox] = useState(false);
    const ssnFieldName = ssnFieldProps?.name || 'ssn';
    const ssnAppliedForFieldName = ssnAppliedForFieldProps?.name || 'ssnAppliedFor';

    const ssnAppliedForValue = formik.values[ssnAppliedForFieldName];

    useEffect(() => {
        // Always show section if this field is selected
        if (ssnAppliedForValue.includes('true')) {
            setShowCheckbox(true);
        }
    }, [ssnAppliedForValue]);

    if (showCheckbox) {
        return (
            <>
                {children}
                <FormikFields.CheckboxField
                    name="ssnAppliedFor"
                    items={[
                        { label: 'I have applied for my Social Security Number.', value: 'true' },
                    ]}
                    {...ssnAppliedForFieldProps}
                    checkboxProps={{
                        className: 'dd-privacy-hidden',
                        onChange: (event) => {
                            const checked = (event.target as HTMLInputElement).checked;
                            if (checked === true) {
                                /* eslint-disable @typescript-eslint/no-floating-promises */
                                formik.setFieldValue(ssnFieldName, '');
                                formik.setFieldTouched(ssnFieldName, false);
                                /* eslint-enable @typescript-eslint/no-floating-promises */
                            }
                            formik.handleChange(event);
                        },
                        ...ssnAppliedForFieldProps?.checkboxProps,
                    }}
                />
            </>
        );
    }

    const onToggleCheckbox = (): void => {
        /* eslint-disable @typescript-eslint/no-floating-promises */
        formik.setFieldTouched(ssnFieldName, false);
        /* eslint-enable @typescript-eslint/no-floating-promises */
        setShowCheckbox((prev) => !prev);
    };

    return (
        <>
            <UsSsnParagraph sx={{ marginBlockStart: 2 }}>
                If you have applied for your social security number,{' '}
                <TextLink
                    as="button"
                    onClick={onToggleCheckbox}
                    inline
                    sx={{
                        color: 'inherit',
                        fontWeight: 'bold',
                        paddingInline: 0,
                        border: 0,
                        backgroundColor: 'transparent',
                        textDecoration: 'underline',
                    }}
                >
                    please click here.
                </TextLink>
            </UsSsnParagraph>
        </>
    );
};
