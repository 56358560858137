import dayjs from 'client/utils/dayjs';
import { AnyObjectSchema, object, ref, string } from 'yup';

export const getFormValidationSchema = (
    requireGender: boolean,
    requireSsn: boolean
): AnyObjectSchema =>
    object().shape({
        bornInPuertoRico: string()
            .trim()
            .required('A response to "Born in Puerto Rico" is required.'),
        mothersMaidenName: string()
            .trim()
            .when('bornInPuertoRico', {
                is: 'true',
                then: string().required("Your mother's maiden name is required."),
            }),
        ssn: string().when([], {
            is: () => requireSsn,
            then: string()
                .trim()
                .max(9, 'Social security number must be at most 9 digits.')
                .min(9, 'Social security number must be at least 9 digits.')
                .matches(/^\d+$/, 'Social security number should contain digits only.')
                .required('Social security number is required and must be 9 numerical characters.'),
            otherwise: string().nullable(),
        }),
        ssnReenter: string().when([], {
            is: () => requireSsn,
            then: string()
                .oneOf([ref('ssn'), null], "Social security numbers don't match")
                .required('Re-enter social security number is required'),
            otherwise: string().nullable(),
        }),
        gender: string().when([], {
            is: () => requireGender,
            then: string().trim().required('Gender is required.'),
        }),
    });

export interface BackgroundCheckFormValues {
    bornInPuertoRico: string;
    mothersMaidenName: string;
    signature?: string;
    currentDate: string;
    gender?: string;
    ssn?: string;
    ssnReenter?: string;
}

const defaultValues: BackgroundCheckFormValues = {
    bornInPuertoRico: '',
    mothersMaidenName: '',
    signature: '',
    currentDate: '',
};

export const toBackgroundCheckFormValues = (
    worker: ComplianceWorker
): Partial<BackgroundCheckFormValues> => {
    const { mothersMaidenName, ssn, gender } = worker;

    const result: Partial<BackgroundCheckFormValues> = {};
    if (mothersMaidenName) {
        result.bornInPuertoRico = 'true';
        result.mothersMaidenName = mothersMaidenName;
    }
    if (!gender || gender === 'BLANK') {
        result.gender = '';
    }
    if (!ssn) {
        result.ssn = '';
        result.ssnReenter = '';
    }
    const currentDate = dayjs().format('MMMM DD, YYYY');
    result.currentDate = currentDate;

    return result;
};

export const toCompleteBackgroundCheckInput = (
    slug: string,
    values: BackgroundCheckFormValues
): CompleteBackgroundCheckInput => {
    return {
        slug,
        metadata: {
            backgroundCheck: {},
            worker: {
                ...(values.gender
                    ? {
                          gender: values.gender as WorkerGender,
                      }
                    : {}),
                ...(values.mothersMaidenName
                    ? {
                          mothersMaidenName: values.mothersMaidenName,
                      }
                    : {}),
            },
            ...(values.ssn
                ? {
                      workerGovernmentId: {
                          idNumber: values.ssn,
                      },
                  }
                : {}),
        } as CompleteBackgroundCheckMetadataInput,
    };
};

export const getInitialValues = (worker: ComplianceWorker): BackgroundCheckFormValues => {
    const initial = toBackgroundCheckFormValues(worker);
    return { ...defaultValues, ...initial };
};
