import React from 'react';

import StatusTag from 'client/components/StatusTag';
import {
    RequiresBankDetailsPageContent,
    RequiresCjoPageContent,
    RequiresFormI9PageContent,
    RequiresProfilePageContent,
} from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import { useClientConfig } from 'client/contexts/ClientConfigContext';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';

type UnableToBeWorkedProps = {
    task: ComplianceTask;
};

const UnableToBeWorked = ({ task }: UnableToBeWorkedProps): JSX.Element => {
    return (
        <>
            <StatusTag task={task} sx={{ mb: '1.5rem' }} />
            <p>Check back soon to start your federal tax onboarding process.</p>
        </>
    );
};

type SuccessProps = {
    task: ComplianceTask;
};

const Success = ({ task }: SuccessProps): JSX.Element => {
    return (
        <>
            <StatusTag task={task} sx={{ mb: '1.5rem' }} />
            <p>
                You've successfully completed your federal taxes task. Please complete the remaining
                onboarding tasks.
            </p>
        </>
    );
};

type PageContentProps = {
    task: ComplianceTask;
};

const PageContent = ({ task }: PageContentProps): JSX.Element => {
    const config = useClientConfig();
    const disabledReason = useTaskDisabledReason(task);
    switch (disabledReason) {
        case 'INCOMPLETE_PROFILE':
            return <RequiresProfilePageContent task={task} taskName="federal taxes setup" />;
        case 'INCOMPLETE_CJO':
            return <RequiresCjoPageContent task={task} taskName="federal taxes setup" />;
        case 'INCOMPLETE_FORM_I9':
            return <RequiresFormI9PageContent task={task} taskName="federal taxes setup" />;
        case 'INCOMPLETE_BANK_DETAILS':
            return <RequiresBankDetailsPageContent task={task} taskName="federal taxes setup" />;
        default:
            switch (task.status) {
                case 'NOT_COMPLETED':
                    window.location.replace(config.payrollFederalTaxElectionsUrl!);
                    return <></>;
                case 'DISABLED':
                case 'NEEDS_REVIEW':
                    return <UnableToBeWorked task={task} />;
                case 'COMPLETED':
                    return <Success task={task} />;
                default:
                    throw Error(`Invalid status for payroll: ${task.status}`);
            }
    }
};

export default PageContent;
