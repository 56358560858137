import React from 'react';
import { CareersOrCandidates5 } from '@indeed/ipl-illustrations-spot';
import {
    ContentSection,
    GraphicContentSection,
    TextContentSection,
} from 'client/pages/i9/WorkBrightI9/ContentSection';
import { DatadogAction } from 'client/utils/logging';
import { LogRenderDatadogAction } from 'client/components/LogRenderDatadogAction';
import { Text } from '@indeed/ifl-components';

const WorkBrightI9NotComplete = (): JSX.Element => {
    return (
        <LogRenderDatadogAction action={DatadogAction.i9NotCompletedRendered}>
            <GraphicContentSection>
                <CareersOrCandidates5 sx={{ display: 'flex' }} />
            </GraphicContentSection>
            <TextContentSection
                summaryHeading="Why is it required:"
                heading="Every U.S. employer is required to fill out a Form I-9 for each person they hire for employment."
                text="During this step we will thoroughly verify your identity and employment authorization."
                omitBottomPadding={true}
            />
            <ContentSection>
                <Text
                    level={3}
                    variant="alert"
                    sx={{
                        fontWeight: 'bold',
                    }}
                >
                    Please complete within 3 days, or progress will be lost.
                </Text>
            </ContentSection>
            <TextContentSection
                summaryHeading="Important to know:"
                text="You'll need someone over 18 to review your document(s) in person."
            />
        </LogRenderDatadogAction>
    );
};
export default WorkBrightI9NotComplete;
