import { useClientConfig } from 'client/contexts/ClientConfigContext';

/**
 * Utility method ONLY to get the URL for making the backend call.
 * For local environment, the backend url is used because the webapp is
 * hosted on a different port compared to the Django backend. For Production, since
 * the sourcing webapp is hosted on same domain, so using the relativized urls
 * provided by the code should be sufficient. For QA, using relativized url is not
 * sufficient when using multibranch settings. Hence, backend url is used, just like
 * local.
 * @param url
 */
const useBackendUrl = (url = ''): string => {
    const clientConfig = useClientConfig();
    if (clientConfig.level === 'local' || clientConfig.level === 'qa') {
        return `${clientConfig.backendUrl}${url}`;
    } else {
        return url;
    }
};

export default useBackendUrl;
