import Confirm from 'client/pages/ukpayroll/components/Confirm';
import Form from 'client/pages/ukpayroll/components/Form';
import React from 'react';
import StatusTag from 'client/components/StatusTag/StatusTag';
import { A11yPageTitle } from 'client/components/Page';
import { ApolloError } from '@apollo/client';
import { Box, Heading } from '@indeed/ifl-components';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import {
    PayrollFieldValues,
    generatePayload,
    getInitialValues,
    payrollFormValidationSchema,
} from 'client/pages/ukpayroll/components/Form/formConstants';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { UkPayrollTaskTrackingEvents } from 'client/pages/ukpayroll/UkPayroll.tracking';
import { datadogRum } from '@datadog/browser-rum';
import { getFormikErrors } from 'client/utils/graphql';
import { indexLocation } from 'client/utils/redirect';
import { redirect } from 'client/utils/auth';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useCompleteUkPayrollMutation } from 'client/hooks/graphql';
import { useToast } from 'client/contexts/ToastContext';
import { useWorker } from 'client/contexts/WorkerContext';

type UkPayrollFormRouterProps = {
    task: ComplianceTask;
};

const FormRouter = ({ task }: UkPayrollFormRouterProps): JSX.Element => {
    const { pushToast } = useToast();
    const { url, path } = useRouteMatch();
    const { worker } = useWorker();
    const history = useHistory();
    const [callCompleteUkPayroll] = useCompleteUkPayrollMutation();

    const reportError = (error: Error | ApolloError): void => {
        reportErrorToThirdPartyTools(error, 'ukPayrollPage', 'onFormSubmit');
        pushToast(
            'There was an error while attempting to submit the information, please try again.',
            { status: 'critical' }
        );
    };
    const onSubmit = async (
        values: PayrollFieldValues,
        formikHelpers: FormikHelpers<PayrollFieldValues>
    ): Promise<void> => {
        datadogRum.addAction(UkPayrollTaskTrackingEvents.confirmBtnClicked);
        const input = generatePayload(task.slug, values);
        try {
            const { data } = await callCompleteUkPayroll({
                variables: { input },
                refetchQueries: ['GetTasks'],
            });
            const result = data?.completeUkPayroll?.result;
            if (result) {
                if (task.redirectUrl) {
                    redirect(task.redirectUrl);
                }
                history.push(indexLocation);
            } else {
                reportError(
                    new Error(
                        `Could not retrieve result from complete uk payroll mutation given input: ${JSON.stringify(
                            input
                        )}`
                    )
                );
            }
        } catch (err) {
            const apolloError = err as ApolloError;
            const formikErrors = getFormikErrors<PayrollFieldValues>(apolloError);
            if (formikErrors) {
                formikHelpers.setErrors(formikErrors);
                history.push({ pathname: url, search: window.location.search });
            } else {
                reportError(apolloError);
            }
        }
    };

    return (
        <Formik
            initialValues={getInitialValues(worker!, task)}
            validationSchema={payrollFormValidationSchema}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <FormikForm>
                    <Route path={path} exact>
                        <Box
                            sx={{
                                maxWidth: ['auto', '24rem'],
                                mx: [0, 'auto'],
                            }}
                        >
                            <A11yPageTitle title="Payment Details Form" />
                            <StatusTag task={task} />
                            <Form formik={formik} baseUrl={url} task={task} />
                        </Box>
                    </Route>
                    <Route path={`${path}/confirm`}>
                        <A11yPageTitle title="Payment Details Confirm" />
                        <Heading level={4}>Confirm your information</Heading>
                        <Box sx={{ mb: '1rem' }}>Please review before submitting.</Box>
                        <Confirm
                            data={formik.values}
                            dirty={formik.dirty}
                            baseUrl={url}
                            isSubmitting={formik.isSubmitting}
                        />
                    </Route>
                </FormikForm>
            )}
        </Formik>
    );
};

export default FormRouter;
