import React, { PropsWithChildren } from 'react';
import { Box } from '@indeed/ifl-components';

type BlockProps = React.ComponentPropsWithoutRef<typeof Box>;

const Block = ({ children, ...props }: PropsWithChildren<BlockProps>): JSX.Element => {
    const { sx, ...rest } = props;
    return (
        <Box
            sx={{
                /* eslint-disable @typescript-eslint/naming-convention */
                backgroundColor: 'neutral.200',
                p: '1rem',
                my: '1rem',
                mx: ['-1rem', 0],
                '&': {
                    p: { mb: '1rem' },
                    // https://github.com/storybookjs/storybook/issues/6998#issuecomment-511724143
                    '> :first-of-type:not(style), > style:first-of-type + *': { mt: 0 },
                    '> :last-child': { mb: 0 },
                    /* eslint-enable-next-line @typescript-eslint/naming-convention */
                },
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Box>
    );
};

export default Block;
