import React, { PropsWithChildren } from 'react';
import { Box, Card, CardSection } from '@indeed/ifl-components';

const ButtonsCard = ({ children }: PropsWithChildren<{}>): JSX.Element => {
    return (
        <>
            <Card sx={{ display: ['none', 'flex'], justifyContent: 'flex-end' }}>
                <CardSection>{children}</CardSection>
            </Card>
            <Box sx={{ display: ['block', 'none'], textAlign: 'end', py: '1rem' }}>{children}</Box>
        </>
    );
};

export default ButtonsCard;
