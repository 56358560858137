import ComplianceContainer from 'client/components/ComplianceContainer';
import DocumentDisplayPage from 'client/pages/documentdisplay';
import HealthForm from 'client/pages/ukdocuments/components/HealthForm';
import OverseasForm from 'client/pages/ukdocuments/components/OverseasForm';
import Page from 'client/components/Page';
import PageContent from 'client/pages/ukdocuments/components/PageContent';
import React from 'react';
import { LoadingPage } from 'client/components/Loading';
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { isUkDocumentsTask } from 'client/utils/task';
import { useTasks } from 'client/contexts/TasksContext';

type SlugParam = {
    slug?: string;
};

const UkDocumentsPage = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();
    const { completed, loading, tasks } = useTasks();
    const { path } = useRouteMatch();
    const location = useLocation();

    location.state = { skipRedirect: true };

    if (!completed || loading) {
        return <LoadingPage />;
    }

    const task = tasks.find((t) => t.slug === slug);

    if (!slug || !task || !isUkDocumentsTask(task)) {
        return <Redirect to="/" />;
    }

    return (
        <Page title="Onboarding Documents">
            <ComplianceContainer>
                <Switch>
                    <Route path={`${path}/document/:templateSlug`}>
                        <DocumentDisplayPage baseUrl={path} />
                    </Route>
                    <Route path={`${path}/health-questionnaire`} exact>
                        <HealthForm task={task} />
                    </Route>
                    <Route path={`${path}/overseas-questionnaire`} exact>
                        <OverseasForm task={task} />
                    </Route>
                    <Route path={`${path}`}>
                        <PageContent task={task!} />
                    </Route>
                    <Route path="*" exact>
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </ComplianceContainer>
        </Page>
    );
};

export default UkDocumentsPage;
