import * as FormikFields from 'client/components/FormikFields';
import ButtonsCard from 'client/pages/bgc/components/ButtonsCard';
import FormRow from 'client/components/FormRow';
import React from 'react';
import { BankDetailsFormValues } from 'client/pages/bankdetails/components/Form/formConstants';
import { Box, Button, Label } from '@indeed/ifl-components';
import { Col } from '@indeed/itmp-components';
import { FormikProps } from 'formik';
import { useClientConfig } from 'client/contexts/ClientConfigContext';

export interface FormProps {
    formik: FormikProps<BankDetailsFormValues>;
}

const Form = ({ formik }: FormProps): JSX.Element => {
    const clientConfig = useClientConfig();

    return (
        <>
            <Box>
                <FormRow>
                    <Col>
                        <Label size="md">
                            I authorize Indeed Flex to collect and process my Social Security Number
                            (SSN) for the following purposes:
                            <ul>
                                <li>Payroll</li>
                                <li>Benefits</li>
                            </ul>
                        </Label>
                        <FormikFields.CheckboxField
                            name="agree"
                            items={[
                                {
                                    label: `I understand that my SSN will only be used for the above purposes and that I may revoke my consent at any time by contacting Indeed Flex at ${clientConfig.flexComplianceUsEmail}.`,
                                    value: 'true',
                                    required: true,
                                },
                            ]}
                        />
                    </Col>
                </FormRow>
                <FormRow>
                    <Col>
                        <FormikFields.MaskedTextField
                            inputProps={{
                                autoComplete: 'off',
                                required: true,
                            }}
                            label="Social Security Number"
                            name="ssn"
                            mask="999999999"
                            removeCharRegex="-"
                        />
                    </Col>
                </FormRow>
                <FormRow>
                    <Col>
                        <ButtonsCard>
                            <Button
                                type="submit"
                                disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                                sx={{ width: ['100%', 'auto'] }}
                            >
                                Agree and continue
                            </Button>
                        </ButtonsCard>
                    </Col>
                </FormRow>
            </Box>
        </>
    );
};

export default Form;
