import React from 'react';
import {
    Box,
    Button,
    ButtonLink,
    DisclosurePopup,
    DisclosurePopupContent,
    DisclosurePopupTrigger,
    Flex,
    Heading,
    InteractiveList,
    InteractiveListAction,
    Text,
} from '@indeed/ifl-components';
import { Close, Hamburger } from '@indeed/ifl-icons';
import { Link, useHistory } from 'react-router-dom';

import Image from 'client/components/Image';
import { URLS } from 'client/constants/urls';
import { hasUkAgentRole, hasUsAgentRole, redirect } from 'client/utils/auth';
import { useAuthentication } from 'client/contexts/AuthenticationContext';

/**
 * Component to render a button with spinner, which can be used while user details are
 * loading
 * @constructor
 */
const LoadingUser = (): JSX.Element => (
    <Button size="md" variant="tertiary" loading={true} disabled />
);

/**
 * Component for use by a logged in user. It shows a button with user email,
 * followed by a collapsible menu with logout button.
 * NOTE that currently, pressing the logout button goes to a different webpage
 */
const LoggedInUser = (): JSX.Element => {
    const { user, logoutUrl } = useAuthentication();
    const history = useHistory();
    return (
        <DisclosurePopup>
            {({ isOpen }) => (
                <>
                    <DisclosurePopupTrigger
                        variant="tertiary"
                        iconAfter={isOpen ? <Close /> : <Hamburger />}
                        sx={{ color: 'neutral.1000' }}
                        aria-label="Header menu"
                    >
                        <Text sx={{ display: ['none', 'block'], color: 'neutral.1000' }}>
                            {' '}
                            {user!.email}
                        </Text>
                    </DisclosurePopupTrigger>
                    <DisclosurePopupContent>
                        <InteractiveList>
                            {(hasUkAgentRole(user) || hasUsAgentRole(user)) && (
                                <InteractiveListAction
                                    onClick={() => history.push(URLS.adminWorkers)}
                                >
                                    Admin portal
                                </InteractiveListAction>
                            )}
                            <InteractiveListAction onClick={() => redirect(logoutUrl)}>
                                Sign out
                            </InteractiveListAction>
                        </InteractiveList>
                    </DisclosurePopupContent>
                </>
            )}
        </DisclosurePopup>
    );
};

/**
 * Component to render a login button for use by a logged out user
 */
const LoggedOutUser = (): JSX.Element => {
    const { loginUrl } = useAuthentication();
    return (
        <ButtonLink size="md" variant="tertiary" href={loginUrl}>
            Sign in
        </ButtonLink>
    );
};

export type HeaderProps = {
    logoTo?: string;
    content?: JSX.Element;
};

/**
 * Header component to retrieve and show the user details if the user is logged in,
 * and to provide a place from where the user can log in or log out
 */
const Header = ({ logoTo = '/', content }: HeaderProps): JSX.Element => {
    const { user, loading } = useAuthentication();

    return (
        <Flex
            as="header"
            sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'neutral.0',
                height: ['auto', '65px'],
                borderBottom: '1px grey solid',
                borderColor: 'neutral.300',
                padding: '1rem',
                mb: '1rem',
            }}
        >
            <Flex sx={{ flexDirection: ['column', 'row'], alignItems: ['flex-start', 'center'] }}>
                <Link to={logoTo}>
                    <Image path="indeed-flex-logo.svg" alt="Indeed Flex logo" width="133" />
                </Link>
                <Heading
                    level={3}
                    sx={{ marginBlockEnd: 0, marginInlineStart: 2, display: ['none', 'block'] }}
                >
                    ONBOARDING PORTAL
                </Heading>
                <Box>{content}</Box>
            </Flex>

            <Box>{loading ? <LoadingUser /> : !!user ? <LoggedInUser /> : <LoggedOutUser />}</Box>
        </Flex>
    );
};

export default Header;
