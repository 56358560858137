import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import useAxiosConfig from 'client/hooks/useAxiosConfig';
import { LoadingPage } from 'client/components/Loading';
import { PayrollTrackingEvents } from 'client/pages/payroll/Payroll.tracking';
import { Redirect } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useToast } from 'client/contexts/ToastContext';

type PayrollOnboardingUrlSuccessResponse = {
    url: string;
};
type PayrollOnboardingUrlErrorResponse = {
    detail: string; // server-side error if present
};
type PayrollOnboardingUrlResponse =
    | PayrollOnboardingUrlSuccessResponse
    | PayrollOnboardingUrlErrorResponse;

const OnboardingUrlLoader = (): JSX.Element => {
    const { pushToast } = useToast();
    const [errorRetrievingOnboardingUrl, setErrorRetrievingOnboardingUrl] =
        useState<boolean>(false);

    const source = axios.CancelToken.source();
    const onboardingUrlConfig = useAxiosConfig({
        url: '/api/payroll/onboarding-url',
        method: 'GET',
        cancelToken: source.token,
    });

    useEffect(() => {
        axios(onboardingUrlConfig)
            .then((response: AxiosResponse<PayrollOnboardingUrlResponse>) => {
                const defaultError = `Did not receive expected response in ${
                    onboardingUrlConfig.url
                } api call, response data: ${JSON.stringify(response.data)}`;
                const url =
                    'url' in response.data
                        ? (response.data as PayrollOnboardingUrlSuccessResponse)?.url
                        : null;
                if (url) {
                    datadogRum.addAction(PayrollTrackingEvents.redirectUrlViewed, { url: url });
                    window.location.replace(url!);
                } else {
                    const error =
                        (response.data as PayrollOnboardingUrlErrorResponse)?.detail ||
                        defaultError;
                    reportErrorToThirdPartyTools(error, 'payroll-page', 'get-onboarding-url');
                    pushToast(
                        'There was an error accessing your payroll task, please try again later.',
                        {
                            status: 'critical',
                        }
                    );
                    setErrorRetrievingOnboardingUrl(true);
                }
            })
            .catch((err) => {
                if (!axios.isCancel(err)) {
                    pushToast(
                        'There was an error accessing your payroll task, please try again later.',
                        { status: 'critical' }
                    );
                    reportErrorToThirdPartyTools(err, 'payroll-page', 'get-onboarding-url');
                    setErrorRetrievingOnboardingUrl(true);
                }
            });

        return () => {
            source.cancel(`Cancelling ${onboardingUrlConfig.url} api call on unmount`);
        };
    }, []);

    if (errorRetrievingOnboardingUrl) {
        return <Redirect to={'/'} />;
    }

    return <LoadingPage text="Retrieving payroll url..." sx={{ mt: '10rem' }} />;
};

export default OnboardingUrlLoader;
