// imported first to set webpack public path
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import BackgroundCheckPage from 'client/pages/bgc';
import BankDetailsPage from 'client/pages/bankdetails';
import ConditionalJobOfferPage from 'client/pages/cjo';
import EmergencyContactPage from 'client/pages/emergencycontact';
import ExternalPageRedirect from 'client/components/ExternalPageRedirect';
import FederalTaxesPage from 'client/pages/federaltaxes';
import I9Page from 'client/pages/i9';
import IndexPage from 'client/pages/index';
import LocationRequirementsPage from 'client/pages/location';
import MediaRequirementsPage from 'client/pages/media';
import PageNotFoundPage from 'client/components/PageNotFoundPage';
import PayrollPage from 'client/pages/payroll';
import ProfilePage from 'client/pages/profile';
import RightToWorkPage from 'client/pages/rtw/RightToWorkPage';
import StartOnboardingPage from 'client/pages/onboarding';
import UkDocumentsPage from 'client/pages/ukdocuments';
import UkPayrollPage from 'client/pages/ukpayroll/UkPayrollPage';
import useScrollToTop from 'client/hooks/useScrollToTop';
import { LoadingPage } from 'client/components/Loading';
import { getRedirectUrl, getRedirectUrlFromStorage } from 'client/utils/redirect';
import { matchesRole } from 'client/utils/auth';
import { useAuthentication } from 'client/contexts/AuthenticationContext';
import { useLocaleContext } from 'client/contexts/LocaleContext';
import { useTasks } from 'client/contexts/TasksContext';
/**
 * This component only performs the routing, and any access-control logic must be
 * added to the corresponding component getting rendered. This is suggested to
 * separate the concerns between routing and feature authorization, where the latter
 * is better handled by the corresponding feature / component.
 *
 * tl;dr - this component shouldn't contain any logic, just routing
 *
 * - log all page loads
 * - render loading page while preparing data
 * - create routes and components
 * - create possibly conditional redirects
 */
export const WorkerComplianceRoutes = (): JSX.Element => {
    useScrollToTop();
    const { tasks, loading, called, completed } = useTasks();
    const { location } = useHistory();
    const { country } = useLocaleContext();
    const { search } = useLocation();
    const { user } = useAuthentication();

    const redirectUrl = getRedirectUrlFromStorage() || getRedirectUrl();

    useEffect(() => {
        const redirectQueryParam = getRedirectUrl();
        if (redirectQueryParam) {
            sessionStorage.setItem('redirect_to', redirectQueryParam);
        }
    }, []);

    if ((loading && !completed) || (matchesRole(user, 'worker') && !called)) {
        return <LoadingPage />;
    }

    return (
        <Switch>
            <Route path="/" exact>
                {!tasks.length ? (
                    <Redirect to={{ ...location, pathname: '/start-onboarding' }} />
                ) : redirectUrl ? (
                    <ExternalPageRedirect redirectUrl={redirectUrl} />
                ) : (
                    <IndexPage />
                )}
            </Route>
            <Route path="/start-onboarding/:country" exact>
                {!!tasks.length ? (
                    <Redirect to={{ ...location, pathname: '/' }} />
                ) : (
                    <StartOnboardingPage />
                )}
            </Route>
            <Route path="/start-onboarding" exact>
                <Redirect to={`/start-onboarding/${country?.toLowerCase() || 'us'}${search}`} />
            </Route>
            <Route path="/background-check/:slug">
                <BackgroundCheckPage />
            </Route>
            <Route path="/i-9/:slug">
                <I9Page />
            </Route>
            <Route path="/payroll/:slug">
                <PayrollPage />
            </Route>
            <Route path="/uk-payroll/:slug">
                <UkPayrollPage />
            </Route>
            <Route path="/conditional-job-offer/:slug">
                <ConditionalJobOfferPage />
            </Route>
            <Route path="/additional-documents/:slug">
                <LocationRequirementsPage />
            </Route>
            <Route path="/additional-trainings/:slug">
                <MediaRequirementsPage />
            </Route>
            <Route path="/emergency-contact/:slug">
                <EmergencyContactPage />
            </Route>
            <Route path="/uk-documents/:slug">
                <UkDocumentsPage />
            </Route>

            <Route path="/profile/:slug">
                <ProfilePage />
            </Route>
            <Route path="/bank-details/:slug">
                <BankDetailsPage />
            </Route>
            <Route path="/federal-tax-elections/:slug">
                <FederalTaxesPage />
            </Route>
            <Route path="/right-to-work/:slug">
                <RightToWorkPage />
            </Route>
            <Route path="*" exact>
                <PageNotFoundPage />
            </Route>
        </Switch>
    );
};
