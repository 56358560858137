// https://code.corp.indeed.com/taco/sourcing-engine-webapp/-/blob/e1b4431450bec0c177a1e0a50e2b5c877ffe339c/src/client/utils/TypescriptUtils.ts#L1

export const noop: (...args: unknown[]) => void = () => {};

/**
 * This is a no-op utility function that can be used to fail at compile time if a switch statement (or if/else if chain) is not exhaustive
 * Example: https://stackoverflow.com/a/39419171
 * @param _val is the value you want typescript to check for never
 */
export const assertNever: (_val: never) => void = noop;

/**
 * Check if a strongly typed object contains a given key in TypeScript without it complaining
 */
export const isObjKey = <T extends object>(key: PropertyKey, obj: T): key is keyof T => key in obj;
