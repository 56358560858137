import { FormikErrors } from 'formik';

export const DUPLICATED_SSN_ERROR = 'SSN invalid for payroll. Reference Code: 1000';

export const getStatusIncludesDuplicatedSsn = (
    status: FormikErrors<Record<string, string[]>>,
    ssnFieldName = 'ssn'
): boolean => {
    const ssnError = status?.[ssnFieldName];

    return (
        (Array.isArray(ssnError) && ssnError.includes(DUPLICATED_SSN_ERROR)) ||
        ssnError === DUPLICATED_SSN_ERROR
    );
};
