import { FetchResult } from '@apollo/client';
import { useAuthentication } from 'client/contexts/AuthenticationContext';
import { useCreateWorkerMutation } from 'client/hooks/graphql';
import { useLocaleContext } from 'client/contexts/LocaleContext';

const useCreateWorker = (): [() => Promise<FetchResult<CreateWorkerMutation>>] => {
    const { user } = useAuthentication();
    const [callCreateWorkerMutation] = useCreateWorkerMutation();
    const { country } = useLocaleContext();

    const callCreateWorker = (): Promise<FetchResult<CreateWorkerMutation>> => {
        const variables = {
            input: {
                email: user!.email!,
                accountKey: user!.accountKey!,
                country: country,
            },
        };
        return callCreateWorkerMutation({ variables });
    };
    return [callCreateWorker];
};

export default useCreateWorker;
