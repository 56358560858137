import React from 'react';
import { Box, Heading } from '@indeed/ifl-components';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import Confirm from 'client/pages/bgc/components/Confirm';
import DocumentListPage from 'client/pages/documentlist';
import Form from 'client/pages/bgc/components/Form';
import first from 'lodash/first';
import { A11yPageTitle } from 'client/components/Page';
import {
    BackgroundCheckFormValues,
    getFormValidationSchema,
    getInitialValues,
    toCompleteBackgroundCheckInput,
} from 'client/pages/bgc/components/Form/formConstants';
import { BackgroundCheckTrackingEvents } from 'client/pages/bgc/BackgroundCheckPage.tracking';
import { datadogRum } from '@datadog/browser-rum';
import { getFormikErrors } from 'client/utils/graphql';
import { getMatchingWorkerDocument, getTaskDocuments } from 'client/utils/task';
import { indexLocation } from 'client/utils/redirect';
import { redirect } from 'client/utils/auth';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useCompleteBackgroundCheckMutation } from 'client/hooks/graphql';
import { useTasks } from 'client/contexts/TasksContext';
import { useToast } from 'client/contexts/ToastContext';
import { useWorker } from 'client/contexts/WorkerContext';

type BackgroundCheckFormRouterProps = {
    task: ComplianceTask;
};

const FormRouter = ({ task }: BackgroundCheckFormRouterProps): JSX.Element => {
    const { pushToast } = useToast();
    const history = useHistory();
    const { url, path } = useRouteMatch();
    const { updateTask } = useTasks();
    const { worker } = useWorker();
    const { templates, workerDocuments } = getTaskDocuments(task);
    const [callCompleteBackgroundCheckMutation] = useCompleteBackgroundCheckMutation();
    const alreadySuppliedGender = !!(worker?.gender && worker?.gender?.trim() !== 'BLANK');
    const alreadySuppliedSsn = !!(worker?.ssn && worker?.ssn !== '');
    const formValidationSchema = getFormValidationSchema(
        !alreadySuppliedGender,
        !alreadySuppliedSsn
    );
    const onSubmit = (
        values: BackgroundCheckFormValues,
        formikHelpers: FormikHelpers<BackgroundCheckFormValues>
    ): Promise<void> | void => {
        const input = toCompleteBackgroundCheckInput(task.slug, values);
        datadogRum.addAction(BackgroundCheckTrackingEvents.lastSubmitBtnClicked);
        if (!templates.find((template) => !getMatchingWorkerDocument(template, workerDocuments))) {
            return callCompleteBackgroundCheckMutation({
                variables: { input },
                refetchQueries: ['GetWorker'],
            })
                .then(({ data }) => {
                    const result = data?.completeBackgroundCheck?.result;
                    if (result) {
                        // @ts-ignore todo completeBackgroundCheck should return templates and workerDocuments
                        result.metadata.templates = templates;
                        // @ts-ignore
                        result.metadata.workerDocuments = workerDocuments;
                        updateTask(result as Partial<ComplianceTask>);
                        if (task.redirectUrl) {
                            redirect(task.redirectUrl);
                        }
                        history.push(indexLocation);
                    } else {
                        reportErrorToThirdPartyTools(
                            new Error(
                                `Could not retrieve result from complete background check mutation given input: ${JSON.stringify(
                                    input
                                )}`
                            ),
                            'backgroundCheckPage',
                            'onFormSubmit'
                        );
                        pushToast(
                            'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                            { status: 'warning' }
                        );
                    }
                })
                .catch((apolloError) => {
                    const formikErrors = getFormikErrors<BackgroundCheckFormValues>(apolloError);
                    if (formikErrors) {
                        formikHelpers.setErrors(formikErrors);
                        history.push({
                            pathname: url,
                            search: window.location.search,
                        });
                    } else {
                        if (
                            apolloError.message?.includes('City and State combination is invalid')
                        ) {
                            history.push({
                                pathname: url,
                                search: window.location.search,
                            });
                            pushToast('The specified city and state are invalid.', {
                                status: 'critical',
                            });
                        } else {
                            reportErrorToThirdPartyTools(
                                apolloError,
                                'backgroundCheckPage',
                                'onFormSubmit'
                            );
                            pushToast(
                                'There was an error while attempting to submit the background check, please try again.',
                                { status: 'critical' }
                            );
                        }
                    }
                });
        }
    };

    const firstTemplate = first(templates);
    if (
        firstTemplate &&
        templates.find((template) => !getMatchingWorkerDocument(template, workerDocuments))
    ) {
        return (
            <DocumentListPage
                tasks={[task]}
                pageTitle="Background Check Documents"
                showBackButton={false}
            />
        );
    }
    return (
        <Formik
            initialValues={getInitialValues(worker!)}
            validationSchema={formValidationSchema}
            validateOnMount
            onSubmit={onSubmit}
        >
            {(formik) => (
                <FormikForm>
                    <Switch>
                        <Box
                            sx={{
                                maxWidth: ['auto', '24rem'],
                                mx: [0, 'auto'],
                            }}
                        >
                            <Route path={`${path}`} exact>
                                <Box>
                                    <A11yPageTitle title="Background Check Form" />
                                    <Heading level={4}>Background Check</Heading>
                                    <Box sx={{ mb: '1rem' }}>
                                        Please complete and review the form below.
                                    </Box>
                                    <Form
                                        formik={formik}
                                        baseUrl={url}
                                        alreadySuppliedSsn={alreadySuppliedSsn}
                                        alreadySuppliedGender={alreadySuppliedGender}
                                    />
                                </Box>
                            </Route>
                            <Route path={`${path}/confirm`}>
                                <A11yPageTitle title="Background Check Confirm" />
                                <Heading level={4}>Confirm your information</Heading>
                                <Box sx={{ mb: '1rem' }}>Please review before submitting.</Box>
                                <Confirm
                                    data={formik.values}
                                    dirty={formik.dirty}
                                    baseUrl={url}
                                    disabled={formik.isSubmitting}
                                />
                            </Route>
                        </Box>
                    </Switch>
                </FormikForm>
            )}
        </Formik>
    );
};

export default FormRouter;
