import React from 'react';
import zIndex from 'client/constants/zIndex';
import { Toast, ToastProps } from '@indeed/ifl-components';

export type StaticToastProps = ToastProps;

/**
 * Position the toast statically on the bottom left side of the page
 * Type will default to 'polite' in a future version of IFL
 */
const StaticToast = ({ type = 'polite', sx, ...rest }: StaticToastProps): JSX.Element => {
    return (
        <Toast
            type={type}
            {...rest}
            sx={{
                position: 'fixed',
                bottom: '1.5rem',
                left: ['0', '1.5rem'],
                zIndex: zIndex.staticToast,
                ...sx,
            }}
        />
    );
};

export default StaticToast;
