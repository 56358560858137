import { DocumentTrackingEventType } from 'client/utils/datadog';

type UkDocumentsTrackingEventType = 'healthFormSubmitBtnClicked' | 'overseasFormSubmitBtnClicked';

export const UkDocumentsTaskTrackingEvents: Record<
    DocumentTrackingEventType | UkDocumentsTrackingEventType,
    string
> = {
    signCheckboxClicked: 'uk_documents_task.sign_checkbox.clicked',
    submitBtnClicked: 'uk_documents_task.submit_btn.clicked',
    backBtnClicked: 'uk_documents_task.back_btn.clicked',
    downloadDocumentClicked: 'uk_documents_task.download_document_btn.clicked',
    completeTrainingClicked: 'uk_documents_task.complete_training_btn.clicked',
    healthFormSubmitBtnClicked: 'uk_documents_task.health_form_submit_btn.clicked',
    overseasFormSubmitBtnClicked: 'uk_documents_task.overseas_form_submit_btn.clicked',
} as const;
