import React from 'react';
import { ButtonLink, Flex, Heading, Text } from '@indeed/ifl-components';
import { Col, Row } from '@indeed/itmp-components';

import ComplianceContainer from 'client/components/ComplianceContainer';
import Image from 'client/components/Image';
import Page from 'client/components/Page';

export interface PageNotFoundPageProps {
    showHeader?: boolean;
    showFooter?: boolean;
}
const PageNotFoundPage = ({
    showHeader = true,
    showFooter = false,
}: PageNotFoundPageProps): JSX.Element => {
    return (
        <Page title="Not Found" showHeader={showHeader} showFooter={showFooter}>
            <ComplianceContainer size="sm">
                <Row sx={{ mb: '2rem' }}>
                    <Col>
                        <Flex sx={{ justifyContent: 'center' }}>
                            <Image path="unknown.png" alt="" aria-hidden />
                        </Flex>
                    </Col>
                </Row>
                <Row sx={{ mb: '1rem' }}>
                    <Col>
                        <Heading level={4} sx={{ textAlign: 'center' }}>
                            We can't find this page
                        </Heading>
                    </Col>
                </Row>
                <Row sx={{ mb: '1rem' }}>
                    <Col>
                        <Text level={3}>
                            It looks like this page doesn't exist or isn’t available right now.
                            Check the URL for any typos or return home.
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ButtonLink variant="primary" full size="md" href="/">
                            Return Home
                        </ButtonLink>
                    </Col>
                </Row>
            </ComplianceContainer>
        </Page>
    );
};

export default PageNotFoundPage;
