import React from 'react';
import { Button, Flex, Heading, Text, TextLink } from '@indeed/ifl-components';
import { Col, Row } from '@indeed/itmp-components';
import { useLocation } from 'react-router';

import ComplianceContainer from 'client/components/ComplianceContainer';
import Page from 'client/components/Page';
import { ClientConfig } from 'client/contexts/ClientConfigContext';
import { NavigateToUrlTargets } from 'client/contexts/MobileBridgeContext';
import { useIsMobileBridgeInitialized } from '@indeed/flex-mobile-bridge';
import { useNavigateToUrlOnClick } from 'client/hooks/useNavigateToUrlOnClick';

export interface ApplicationErrorProps {
    clientConfig: ClientConfig;
}
export const ApplicationError = ({ clientConfig }: ApplicationErrorProps): JSX.Element => {
    const appLocation = useLocation();
    const isBridgeInitialized = useIsMobileBridgeInitialized();
    const handleOpenInBrowserOnClick = useNavigateToUrlOnClick(
        NavigateToUrlTargets.EXTERNAL,
        'application_error.browser_btn.clicked'
    );

    return (
        <Page title="Application Error" showHeader={false} showFooter={false}>
            <ComplianceContainer size="sm" sx={{ mt: '5rem' }}>
                <Row sx={{ mb: '38px' }}>
                    <Col>
                        <Flex sx={{ justifyContent: 'center' }}>
                            <img
                                src={`${clientConfig.staticUrl}images/alert.svg`}
                                alt="Error"
                                width="164px"
                            />
                        </Flex>
                    </Col>
                </Row>
                <Row sx={{ mb: 4 }}>
                    <Col>
                        <Heading level={4}>
                            <strong>Uh oh! Looks like something went wrong on our end.</strong>
                        </Heading>
                    </Col>
                </Row>
                <Row sx={{ mb: 4 }}>
                    <Col>
                        <Text level={3}>
                            Our team has been notified. Please refresh the page or try again later.
                        </Text>
                    </Col>
                </Row>
                <Row sx={{ mb: 4 }}>
                    <Col>
                        <Button
                            variant="primary"
                            full
                            id="signoutButton"
                            size="lg"
                            onClick={() => location.reload()}
                        >
                            Refresh page
                        </Button>
                    </Col>
                </Row>
                {isBridgeInitialized && (
                    <Row>
                        <Col>
                            <TextLink
                                href={`${clientConfig.appUrl}${appLocation.pathname}${
                                    appLocation.search || ''
                                }`}
                                onClick={handleOpenInBrowserOnClick}
                                sx={{ fontSize: '.85rem' }}
                            >
                                Open onboarding tasks in a web browser
                            </TextLink>
                        </Col>
                    </Row>
                )}
            </ComplianceContainer>
        </Page>
    );
};

export default ApplicationError;
