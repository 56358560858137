import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { Alert, AlertTitle, Box } from '@indeed/ifl-components';

interface FormAlertErrorProps {
    title: string;
}
export const FormAlertError = ({
    title,
    children,
}: PropsWithChildren<FormAlertErrorProps>): JSX.Element => {
    const alertRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        alertRef?.current?.focus();
    }, []);

    return (
        <Alert
            ref={alertRef}
            as="section"
            role="region"
            status="critical"
            tabIndex={-1}
            aria-labelledby="form-alert-error-title"
            aria-describedby="form-alert-error-content"
            sx={{ marginBlockEnd: 2 }}
        >
            <AlertTitle as="h2" id="form-alert-error-title">
                {title}
            </AlertTitle>
            <Box id="form-alert-error-content">{children}</Box>
        </Alert>
    );
};
