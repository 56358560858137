import React from 'react';
import { Heading } from '@indeed/ifl-components';
import { Redirect, useParams } from 'react-router-dom';

import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import Page from 'client/components/Page';
import PageContent from 'client/pages/payroll/components/PageContent';
import { LoadingPage } from 'client/components/Loading';
import { PayrollTrackingEvents } from './Payroll.tracking';
import { isPayrollTask } from 'client/utils/task';
import { useTasks } from 'client/contexts/TasksContext';

type SlugParam = {
    slug?: string;
};

const PayrollPage = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();
    const { completed, loading, tasks } = useTasks();
    const task = tasks.find((t) => t.slug === slug);

    if (!completed || loading) {
        return <LoadingPage />;
    }

    if (!isPayrollTask(task)) {
        return <Redirect to="/" />;
    }

    return (
        <Page title="Pay Status">
            <ComplianceContainer>
                <BackButton trackingEvent={PayrollTrackingEvents.backBtnClicked} />
                <Heading level={4}>Pay</Heading>
                <PageContent task={task!} />
            </ComplianceContainer>
        </Page>
    );
};

export default PayrollPage;
