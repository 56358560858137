import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

type PhoneNumberData = {
    phoneNumber: string;
    phoneCountry: CountryCode;
};
export const validatePhoneNumberLength = (data: PhoneNumberData): boolean => {
    if (data.phoneNumber && data.phoneCountry) {
        try {
            const phoneNumber = parsePhoneNumber(data.phoneNumber, data.phoneCountry);
            return phoneNumber.isPossible();
        } catch (err) {
            return false;
        }
    }
    return true;
};

export const validatePhoneNumber = (data: PhoneNumberData): boolean => {
    if (data.phoneNumber && data.phoneCountry) {
        try {
            const phoneNumber = parsePhoneNumber(data.phoneNumber, data.phoneCountry);
            return phoneNumber.isValid();
        } catch (err) {
            return false;
        }
    }
    return true;
};

export const scrollToFieldByName = (name: string, delay = 100): void => {
    const selector = `[name="${name}"]`;
    const inputElement: HTMLElement | null = document.querySelector(selector);
    setTimeout(() => {
        inputElement?.focus();
    }, delay);
};

export const formatUkPostcode = (postcode: string, autoFormatSpacing = false): string => {
    const cleaned = postcode.toUpperCase();

    if (!autoFormatSpacing) {
        return cleaned;
    }

    const match = cleaned.match(/^([A-Z]{1,2}\d[A-Z\d]?)\s?(\d[A-Z]{2})$/);
    if (match) {
        return `${match[1]} ${match[2]}`;
    }
    return cleaned;
};
