import React from 'react';
import { DatadogAction } from 'client/utils/logging';
import { Employer1 } from '@indeed/ipl-illustrations-spot';
import {
    GraphicContentSection,
    TextContentSection,
} from 'client/pages/i9/WorkBrightI9/ContentSection';
import { LogRenderDatadogAction } from 'client/components/LogRenderDatadogAction';

const WorkBrightI9NeedsReview = (): JSX.Element => {
    return (
        <LogRenderDatadogAction action={DatadogAction.i9NeedsReviewRendered}>
            <GraphicContentSection>
                <Employer1 sx={{ display: 'flex' }} />
            </GraphicContentSection>
            <TextContentSection
                heading="Sorry, there was an issue while processing your Form I-9. "
                text="Please review your Form I-9."
            />
        </LogRenderDatadogAction>
    );
};
export default WorkBrightI9NeedsReview;
