import React from 'react';
import { DatadogAction } from 'client/utils/logging';
import {
    GraphicContentSection,
    TextContentSection,
} from 'client/pages/i9/WorkBrightI9/ContentSection';
import { LogRenderDatadogAction } from 'client/components/LogRenderDatadogAction';
import { Time2 } from '@indeed/ipl-illustrations-spot';

export const WorkBrightI9Submitted = (): JSX.Element => {
    return (
        <LogRenderDatadogAction action={DatadogAction.i9SubmittedRendered}>
            <GraphicContentSection>
                <Time2 sx={{ display: 'flex' }} />
            </GraphicContentSection>
            <TextContentSection
                heading="Please allow up to 72 hours for us to complete this initial review."
                text="Check back later to see if your I-9 is complete or if any action is required."
            />
        </LogRenderDatadogAction>
    );
};
export default WorkBrightI9Submitted;
