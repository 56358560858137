import React, { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import ApplicationError from 'client/components/ApplicationError';
import { ClientConfig } from 'client/contexts/ClientConfigContext';

interface WorkerComplianceAppProvidersProps {
    config?: ClientConfig;
    onError?: (error: Error, errorInfo: string) => void;
}

interface State {
    error: null | Error;
}

class ErrorBoundary extends Component<PropsWithChildren<WorkerComplianceAppProvidersProps>, State> {
    public state: State = {
        error: null,
    };

    public static getDerivedStateFromError(error: Error): State {
        return { error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        if (this.props.onError) {
            this.props.onError(error, errorInfo?.componentStack || '');
        } else {
            datadogRum.addError(error, {
                errorInfo,
            });
        }
    }

    public render(): ReactNode {
        if (this.state.error && this.props.config) {
            return <ApplicationError clientConfig={this.props.config} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
