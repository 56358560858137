import React, { PropsWithChildren, createContext, useContext, useEffect, useReducer } from 'react';
import { BaseQueryResult, getNodes } from 'client/utils/graphql';
import { QueryLazyOptions } from '@apollo/client';
import { matchesRole } from 'client/utils/auth';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useAuthentication } from 'client/contexts/AuthenticationContext';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';
import { useGetTasksLazyQuery } from 'client/hooks/graphql';
import { useToast } from './ToastContext';
/*
 * ACTIONS AND REDUCERS
 */

export type State = BaseQueryResult<GetTasksQuery, GetTasksQueryVariables> & {
    tasks: ComplianceTask[];
    completed: boolean;
};

export type Action =
    | { type: 'SET_TASKS'; payload: { tasks: ComplianceTask[] } }
    | { type: 'UPDATE_TASK'; payload: { task: Partial<ComplianceTask> } };

export type Dispatches = {
    getTasks: (options?: QueryLazyOptions<GetTasksQueryVariables>) => void;
    setTasks: (tasks: ComplianceTask[]) => void;
    updateTask: (task: Partial<ComplianceTask>) => void;
};

export const reducer = (state: State, action: Action): State => {
    let tasks: ComplianceTask[] = [];
    switch (action.type) {
        case 'SET_TASKS':
            tasks = action.payload.tasks;
            const completed = true;
            return { ...state, tasks, completed };
        case 'UPDATE_TASK':
            const task = action.payload.task;
            tasks = state.tasks.map((t) => {
                return t.slug === task.slug ? { ...t, ...task } : t;
            });
            break;
    }
    return { ...state, tasks };
};

/*
 * CONTEXT
 */

export type TasksContextType = State & Dispatches;

const initialState: State = { tasks: [], called: false, loading: false, completed: false };

export const TasksContext = createContext<TasksContextType | null>(null);

export const useTasks = (): TasksContextType => {
    const context = useContext(TasksContext);
    if (!context) {
        throw new Error('Tasks context must be defined before being used');
    }

    return context;
};

type TasksProviderProps = LazyProviderProps;

/**
 * Tasks provider providing a lazy `getTasks()` function to allow us to call `getTasks()` when we want with different parameters
 *
 * @param lazy - True if you want to call `getTasks()` manually, false if you want it called on first render
 * @param children
 */
export const TasksProvider = ({
    lazy = false,
    children,
}: PropsWithChildren<TasksProviderProps>): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [getTasks, { data, previousData, error, called, loading }] = useGetTasksLazyQuery({
        notifyOnNetworkStatusChange: true,
    });
    const { pushToast } = useToast();
    const { user } = useAuthentication();

    // There is a lot of refetchQuery logic for GetTasks
    const dataValuePure = useDeepCompareMemoize(data);

    const setTasks = (tasks: ComplianceTask[]): void => {
        dispatch({ type: 'SET_TASKS', payload: { tasks } });
    };
    const updateTask = (task: Partial<ComplianceTask>): void => {
        dispatch({ type: 'UPDATE_TASK', payload: { task } });
    };

    useEffect(() => {
        if (!lazy && matchesRole(user, 'worker')) {
            // run query on first render
            getTasks().catch((apolloError) => {
                reportErrorToThirdPartyTools(apolloError, 'getTasks', 'onLoad');
                pushToast('There was an error while attempting to retrieve tasks data.', {
                    status: 'critical',
                });
            });
        }
    }, []);

    useEffect(() => {
        // `getTasks()` updates `data` on return
        // every time `data` changes, set the `tasks` in state to that value
        const tasks = getNodes<GetTasksQuery | undefined>(dataValuePure);
        if (dataValuePure && called) {
            setTasks(tasks);
        }
    }, [dataValuePure, called]);

    return (
        <TasksContext.Provider
            value={{
                tasks: state.tasks,
                called: called,
                loading,
                error,
                previousData,
                getTasks,
                setTasks,
                updateTask,
                completed: state.completed,
            }}
        >
            {children}
        </TasksContext.Provider>
    );
};
