import FormRouter from 'client/pages/bankdetails/components/FormRouter';
import React from 'react';
import { Box, Heading, Text } from '@indeed/ifl-components';
import {
    RequiresFormI9PageContent,
    RequiresProfilePageContent,
} from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import { formatDateWithDayOfWeek } from 'client/utils/dayjs';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';

type PageContentProps = {
    task: ComplianceTask;
};

const PageContent = ({ task }: PageContentProps): JSX.Element => {
    const disabledReason = useTaskDisabledReason(task);

    if (disabledReason === 'INCOMPLETE_PROFILE') {
        return (
            <RequiresProfilePageContent
                task={task}
                taskName="bank details"
                heading="Bank details"
            />
        );
    } else if (disabledReason === 'INCOMPLETE_FORM_I9') {
        return (
            <RequiresFormI9PageContent task={task} taskName="bank details" heading="Bank details" />
        );
    }
    switch (task.status) {
        case 'NOT_COMPLETED':
            return <FormRouter task={task} />;
        case 'COMPLETED':
            return (
                <Box>
                    <Heading level={4}>Bank Details</Heading>
                    <Text>
                        Your bank details were completed on{' '}
                        {formatDateWithDayOfWeek(task.completedDate)}.
                    </Text>
                </Box>
            );
        default:
            throw Error(`Invalid status for bank details: ${task.status}`);
    }
};

export default PageContent;
