import dayjs from 'client/utils/dayjs';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { array, date, object, string } from 'yup';

export interface UpdateWorkerValues {
    nameRequired: boolean;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    noMiddleName: string[];
    dobRequired: boolean;
    dateOfBirth: string | null;
    ssnRequired: boolean;
    ssnAppliedFor: string[];
    ssn: string | null;
}

export const getUpdateWorkerDefaultValues = (
    nameRequired: boolean,
    dobRequired: boolean,
    ssnRequired: boolean
): UpdateWorkerValues => ({
    nameRequired,
    firstName: '',
    lastName: '',
    noMiddleName: [],
    middleName: '',
    dobRequired: dobRequired,
    dateOfBirth: null,
    ssnRequired: ssnRequired,
    ssnAppliedFor: [],
    ssn: '',
});

export const updateWorkerModalValidationSchema = object().shape({
    firstName: string()
        .trim()
        .when('nameRequired', {
            is: true,
            then: string().trim().required('Legal first name is required.'),
        }),
    middleName: string()
        .trim()
        .when('nameRequired', {
            is: true,
            then: string()
                .trim()
                .test('isEmpty', 'Legal middle name is required.', (val) => {
                    return !isEmpty(val) || !isUndefined(val?.length);
                }),
        }),
    lastName: string()
        .trim()
        .when('nameRequired', {
            is: true,
            then: string().trim().required('Legal last name is required.'),
        }),
    dateOfBirth: date()
        .nullable()
        .when('dobRequired', {
            is: true,
            then: date()
                .required('Date of birth is required.')
                .max(dayjs().subtract(18, 'year').toDate(), 'You must be at least 18 years old'),
        }),
    ssnAppliedFor: array(),
    ssn: string()
        .trim()
        .when('ssnRequired', {
            is: true,
            then: string()
                .trim()
                .when('$ssnAppliedFor', {
                    is: (ssnAppliedFor: string[]) => !ssnAppliedFor.includes('true'),
                    then: string()
                        .trim()
                        .max(9, 'Social security number must be at most 9 digits.')
                        .min(9, 'Social security number must be at least 9 digits.')
                        .matches(/^\d+$/, 'Social security number should contain digits only.')
                        .required(
                            'Social security number is required and must be 9 numerical characters.'
                        ),
                }),
        }),
});

export const toUpdateWorkerInput = (
    accountKey: string,
    values: UpdateWorkerValues
): UpdateWorkerInput => {
    return {
        accountKey,
        firstName: values.nameRequired ? values.firstName : undefined,
        middleName: values.nameRequired
            ? values.middleName === 'N/A'
                ? ''
                : values.middleName
            : undefined,
        lastName: values.nameRequired ? values.lastName : undefined,
        dateOfBirth: values.dobRequired ? values.dateOfBirth : undefined,
        governmentIds:
            // If SSN Applied for is clicked, we want to ensure any existing SSN is cleared out
            values.ssnRequired && (values.ssn || values.ssnAppliedFor.includes('true'))
                ? [
                      {
                          country: 'US',
                          type: 'SSN',
                          idNumber: values.ssn,
                      },
                  ]
                : undefined,
        country: 'US',
    };
};
