const ALLOWED_REDIRECT_DOMAINS = ['indeed.com', 'indeed.net', 'indeedflex.com'];

const getUrlHostname = (maybeUrl: string): string | null => {
    try {
        return new URL(maybeUrl).hostname;
    } catch (e) {
        return null;
    }
};
export const verifyAllowedHostName = (maybeUrl: string): boolean => {
    const hostName = getUrlHostname(maybeUrl);
    if (!hostName) return false;
    const domainPlusTld = hostName.split('.').slice(-2).join('.');

    return ALLOWED_REDIRECT_DOMAINS.includes(domainPlusTld);
};

export const getRedirectUrl = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('redirect_to');
};

export const getRedirectUrlFromStorage = (): string | null => {
    return sessionStorage.getItem('redirect_to');
};

export const indexLocation = {
    pathname: '/',
    search: window.location.search,
};
