/**
 * The module defines types, components, context and utility methods related to
 * accessing the configuration data for use by the client side rendered application.
 */

import React, { PropsWithChildren, createContext, useContext } from 'react';

/* eslint-disable @typescript-eslint/naming-convention */
type DatadogType = {
    'worker-compliance-webapp.datadog.applicationId'?: string;
    'worker-compliance-webapp.datadog.clientToken'?: string;
    'worker-compliance-webapp.datadog.proxy'?: string;
};

type ReleaseType = {
    'worker-compliance-webapp.release'?: string;
};
/* eslint-enable @typescript-eslint/naming-convention */

export type ClientFeatureFlags = {
    formI9FeatureFlag?: boolean;
    payFeatureFlag?: boolean;
    cjoFeatureFlag?: boolean;
    bgcFeatureFlag?: boolean;
    taskEligibilityFeatureFlag?: boolean;
    profileFeatureFlag?: boolean;
    ukComplianceFeatureFlag?: boolean;
};

export type FeatureFlags = ClientFeatureFlags & {
    payCustomOnboardingFeatureFlag?: boolean;
    profileGoogleAddressValidationFeatureFlag?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'admin.tasks_and_placements_page'?: boolean;
};

export type GoogleAddressValidationConfig = {
    googleAddressValidationApiKey?: string;
    googleAddressValidationUrl?: string;
};

/**
 * Type for the configuration data
 */
export type ClientConfig = DatadogType &
    ReleaseType &
    GoogleAddressValidationConfig & {
        level?: string;
        sharedCookieDomain?: string;
        appUrl?: string;
        backendUrl?: string;
        publicPath?: string;
        staticUrl?: string;
        passportLoginUrl?: string;
        passportLogoutUrl?: string;
        passportAuthHeader?: string;
        oneLoginLoginUrl?: string;
        oneLoginLogoutUrl?: string;
        flexHelpEmail?: string;
        flexComplianceUsEmail?: string;
        flexComplianceUkEmail?: string;
        flexAppDownloadUrl?: string;
        googlePlacesApiKey?: string;
        flexAcpUkUrl?: string;
        flexAcpUsUrl?: string;
        credasUrl?: string;
        payrollFederalTaxElectionsUrl?: string;
        payrollBankDetailsUrl?: string;
        featureFlags?: ClientFeatureFlags;
    };

/**
 * Context object storing the configuration data
 */
export const ClientConfigContext = createContext<ClientConfig | null>(null);

/**
 * Utility hook to provide the client configuration context value.  If no
 * configuration is available before the hook is dynamically invoked, then an error
 * is raised
 */
export const useClientConfig = (): ClientConfig => {
    const context = useContext(ClientConfigContext);
    if (!context) {
        throw new Error('Client configuration context must be defined before being used');
    }
    return context;
};

interface ClientConfigProviderProps {
    clientConfig: ClientConfig;
}

/**
 * A Provider higher order component that makes the client configuration context
 * available to children component.
 * @param clientConfig
 * @param children
 * @constructor
 */
export const ClientConfigProvider = ({
    clientConfig,
    children,
}: PropsWithChildren<ClientConfigProviderProps>): JSX.Element => {
    return (
        <ClientConfigContext.Provider value={clientConfig}>{children}</ClientConfigContext.Provider>
    );
};
