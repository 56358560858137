import React, { PropsWithChildren, createContext, useContext, useEffect, useReducer } from 'react';
import { BaseQueryResult, getNode } from 'client/utils/graphql';
import { LoadingPage } from 'client/components/Loading';
import { QueryLazyOptions } from '@apollo/client';
import { matchesRole } from 'client/utils/auth';
import { setDatadogUserPropertiesForWorker } from 'client/utils/datadog';
import { useAuthentication } from 'client/contexts/AuthenticationContext';
import { useDeepCompareMemoize } from 'use-deep-compare-effect';
import { useGetWorkerLazyQuery } from 'client/hooks/graphql';

/*
 * ACTIONS AND REDUCERS
 */

export type State = BaseQueryResult<GetWorkerQuery, GetWorkerQueryVariables> & {
    worker: ComplianceWorker | null;
    completed: boolean;
};

export type Action = { type: 'SET_WORKER'; payload: { worker: ComplianceWorker } };

export type Dispatches = {
    getWorker: (options?: QueryLazyOptions<GetTasksQueryVariables>) => void;
    setWorker: (worker: ComplianceWorker) => void;
};

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_WORKER':
            return { ...state, worker: action.payload.worker, completed: true };
    }
};

/*
 * CONTEXT
 */

export type WorkerContextType = State & Dispatches;

const initialState: State = { worker: null, called: false, loading: false, completed: false };

export const WorkerContext = createContext<WorkerContextType | null>(null);

export const useWorker = (): WorkerContextType => {
    const context = useContext(WorkerContext);
    if (!context) {
        throw new Error('Worker context must be defined before being used');
    }

    return context;
};

type WorkerProviderProps = LazyProviderProps;

export const WorkerProvider = ({
    lazy = false,
    children,
}: PropsWithChildren<WorkerProviderProps>): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [getWorker, { data, previousData, error, called, loading }] = useGetWorkerLazyQuery();
    const { user } = useAuthentication();

    // There is a lot of refetchQuery logic for GetWorker
    const dataValuePure = useDeepCompareMemoize(data);

    const setWorker = (worker: ComplianceWorker): void => {
        dispatch({ type: 'SET_WORKER', payload: { worker } });
    };

    useEffect(() => {
        if (!lazy && matchesRole(user, 'worker')) {
            // run query on first render
            getWorker().catch(() => {});
        }
    }, []);

    useEffect(() => {
        const worker = getNode<GetWorkerQuery | undefined>(dataValuePure);
        setWorker(worker);
        if (!previousData && worker) {
            setDatadogUserPropertiesForWorker(worker);
        }
    }, [dataValuePure, called]);

    return (
        <WorkerContext.Provider
            value={{
                worker: state.worker,
                previousData,
                error,
                called: called,
                loading,
                getWorker,
                setWorker,
                completed: state.completed,
            }}
        >
            {loading ? <LoadingPage /> : children}
        </WorkerContext.Provider>
    );
};
