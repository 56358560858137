import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import Page from 'client/components/Page';
import PageContent from 'client/pages/bankdetails/components/PageContent';
import React from 'react';
import { LoadingPage } from 'client/components/Loading';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { indexLocation } from 'client/utils/redirect';
import { isBankDetailsTask } from 'client/utils/task';
import { useTasks } from 'client/contexts/TasksContext';

type SlugParam = {
    slug?: string;
};

const BankDetailsPage = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();

    const { completed, loading, tasks } = useTasks();
    const history = useHistory();

    if (!completed || loading) {
        return <LoadingPage />;
    }

    const task = tasks.find((t) => t.slug === slug);
    if (!isBankDetailsTask(task)) {
        return <Redirect to={indexLocation} />;
    }

    const previous = (): void => {
        if (history.location.pathname.includes('next')) {
            history.push(indexLocation);
        } else {
            history.goBack();
        }
    };

    return (
        <Page title="Bank Details">
            <ComplianceContainer>
                <BackButton onClick={previous} />
                <PageContent task={task!} />
            </ComplianceContainer>
        </Page>
    );
};

export default BankDetailsPage;
