import React from 'react';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import DocumentDisplayPage from 'client/pages/documentdisplay';
import Page from 'client/components/Page';
import PageContent from 'client/pages/bgc/components/PageContent';

import { LoadingPage } from 'client/components/Loading';
import { indexLocation } from 'client/utils/redirect';
import { isBackgroundCheckTask } from 'client/utils/task';
import { logDocumentEvent } from 'client/utils/datadog';
import { useTasks } from 'client/contexts/TasksContext';
type SlugParam = {
    slug?: string;
};

const BackgroundCheckPage = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();
    const { completed, loading, tasks } = useTasks();
    const { path, isExact } = useRouteMatch();
    const history = useHistory();

    if (!completed || loading) {
        return <LoadingPage />;
    }

    const task = tasks.find((t) => t.slug === slug);

    if (!slug || !task || !isBackgroundCheckTask(task)) {
        return <Redirect to={indexLocation} />;
    }

    // On the root bgc page redirect to index otherwise go back to previous
    const onBackButtonClick = (): void => {
        logDocumentEvent(task.type, 'backBtnClicked');
        if (isExact) {
            history.push(indexLocation);
        } else {
            history.goBack();
        }
    };

    return (
        <Page title="Background Check">
            <ComplianceContainer>
                <Switch>
                    <Route path={`${path}/document/:templateSlug`}>
                        <DocumentDisplayPage baseUrl={path} />
                    </Route>
                    <Route path={`${path}`}>
                        <BackButton onClick={onBackButtonClick} />
                        <PageContent task={task!} />
                    </Route>
                    <Route path="*" exact>
                        <Redirect to={indexLocation} />
                    </Route>
                </Switch>
            </ComplianceContainer>
        </Page>
    );
};

export default BackgroundCheckPage;
