import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import DocumentDisplayPage from 'client/pages/documentdisplay';
import DocumentListPage from 'client/pages/documentlist';
import Page from 'client/components/Page';
import PageContent from 'client/pages/cjo/components/PageContent';
import React, { useEffect } from 'react';
import { ConditionalJobOfferTrackingEvents } from './ConditionalJobOffer.tracking';
import { Heading } from '@indeed/ifl-components';
import { LoadingPage } from 'client/components/Loading';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { RequiresProfilePageContent } from 'client/components/RequiresTaskPageContent/RequiresTaskPageContent';
import {
    getMatchingWorkerDocument,
    getTaskDocuments,
    isConditionalJobOfferTask,
} from 'client/utils/task';
import { indexLocation } from 'client/utils/redirect';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useTaskDisabledReason } from 'client/hooks/useTaskDisabledReason';
import { useTasks } from 'client/contexts/TasksContext';
import { useToast } from 'client/contexts/ToastContext';
import { useUpdateTaskStatusMutation } from 'client/hooks/graphql';
type SlugParam = {
    slug?: string;
};

const ConditionalJobOfferPage = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();
    const history = useHistory();
    const { completed, loading, tasks, updateTask } = useTasks();
    const { path } = useRouteMatch();
    const { pushToast } = useToast();
    const task = tasks.find((t) => t.slug === slug);
    const disabledReason = useTaskDisabledReason(task);
    const [callUpdateTaskStatusMutation, { loading: updateTaskStatusLoading }] =
        useUpdateTaskStatusMutation();

    /**
     * Completes the task when there are no more templates without a corresponding worker document. Done here rather
     * in the document display page so that the user can "resubmit" the task completion in case of an error by refreshing
     */
    useEffect(() => {
        if (task && !disabledReason && task.status !== 'COMPLETED') {
            const { templates, workerDocuments } = getTaskDocuments(task);
            if (
                !templates.find((template) => !getMatchingWorkerDocument(template, workerDocuments))
            ) {
                callUpdateTaskStatusMutation({
                    variables: { slug: task.slug, status: 'COMPLETED', progressStatus: 'APPROVED' },
                })
                    .then(({ data: taskData }) => {
                        const taskResult = taskData?.updateTask?.result;
                        if (taskResult) {
                            updateTask(taskResult as Partial<ComplianceTask>);
                            history.push(indexLocation);
                        } else {
                            reportErrorToThirdPartyTools(
                                new Error(
                                    `Could not retrieve result from updateTask mutation given input: taskSlug:${task.slug}, taskStatus:COMPLETED`
                                ),
                                'conditionalJobOfferPage',
                                'checkAndPossiblyCompleteTask'
                            );
                            pushToast(
                                'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                                { status: 'warning' }
                            );
                        }
                    })
                    .catch((apolloError) => {
                        reportErrorToThirdPartyTools(
                            apolloError,
                            'conditionalJobOfferPage',
                            'checkAndPossiblyCompleteTask'
                        );
                        pushToast(
                            'There was an error while attempting to submit the information, please try again.',
                            { status: 'critical' }
                        );
                    });
            }
        }
    }, [task]);

    if (!completed || loading || updateTaskStatusLoading) {
        return <LoadingPage />;
    }

    if (!slug || !task || !isConditionalJobOfferTask(task)) {
        return <Redirect to={indexLocation} />;
    }

    // We know the CJO is legacy if it is already complete but without any worker documents
    const isLegacyCjo =
        getTaskDocuments(task).workerDocuments.length === 0 && task.status === 'COMPLETED';
    if (!isLegacyCjo) {
        if (disabledReason === 'INCOMPLETE_PROFILE') {
            return (
                <Page title="Conditional Job Offer Status">
                    <ComplianceContainer>
                        <BackButton
                            trackingEvent={ConditionalJobOfferTrackingEvents.backBtnClicked}
                        />
                        <RequiresProfilePageContent
                            heading="Conditional job offer"
                            task={task}
                            taskName="conditional job offer"
                        />
                    </ComplianceContainer>
                </Page>
            );
        }

        return (
            <Page title="Conditional Job Offer Status">
                <ComplianceContainer>
                    <Switch>
                        <Route path={`${path}/document/:templateSlug`}>
                            <DocumentDisplayPage baseUrl={path} />
                        </Route>
                        <Route path={`${path}`}>
                            <DocumentListPage tasks={[task]} pageTitle="Conditional job offer" />
                        </Route>
                        <Route path="*" exact>
                            <Redirect to={indexLocation} />
                        </Route>
                    </Switch>
                </ComplianceContainer>
            </Page>
        );
    }

    // Else show the legacy CJO UI
    return (
        <Page title="Conditional Job Offer Status">
            <ComplianceContainer>
                <BackButton trackingEvent={ConditionalJobOfferTrackingEvents.backBtnClicked} />
                <Heading>Conditional job offer</Heading>
                <PageContent task={task!} />
            </ComplianceContainer>
        </Page>
    );
};

export default ConditionalJobOfferPage;
