import React, { useState } from 'react';
import UpdateWorkerForm from 'client/pages/i9/WorkBrightI9/UpdateWorkerModal/UpdateWorkerForm';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Modal, ModalBody } from '@indeed/ipl-modal';
import {
    UpdateWorkerValues,
    getUpdateWorkerDefaultValues,
    updateWorkerModalValidationSchema,
} from 'client/pages/i9/WorkBrightI9/UpdateWorkerModal/formConstants';

export interface UpdateWorkerModalProps {
    open: boolean;
    setOpen: (show: boolean) => void;
    needsDob: boolean;
    needsSsn: boolean;
    needsName: boolean;
    onSubmit: (
        values: UpdateWorkerValues,
        formikHelpers: FormikHelpers<UpdateWorkerValues>
    ) => Promise<void>;
}
const UpdateWorkerModal = ({
    open,
    setOpen,
    needsDob,
    needsSsn,
    needsName,
    onSubmit,
}: UpdateWorkerModalProps): JSX.Element => {
    const [submitting, setSubmitting] = useState<boolean>(false);

    const onExit = (): void => {
        setOpen(false);
        setSubmitting(false);
    };

    const initialValues = getUpdateWorkerDefaultValues(needsName, needsDob, needsSsn);

    return (
        <>
            <Modal isOpen={open} onExit={onExit} title={'Update I-9 Information'}>
                <ModalBody>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={updateWorkerModalValidationSchema}
                    >
                        {(formik: FormikProps<UpdateWorkerValues>) => (
                            <UpdateWorkerForm
                                formik={formik}
                                submitting={submitting}
                                needsName={needsName}
                                needsDob={needsDob}
                                needsSsn={needsSsn}
                            />
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};
export default UpdateWorkerModal;
