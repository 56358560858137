import React from 'react';
import { Box, Button, Heading, Text } from '@indeed/ifl-components';

import { RightToWorkTaskTrackingEvents } from 'client/pages/rtw/RightToWork.tracking';
import { datadogRum } from '@datadog/browser-rum';
import { formatDateWithDayOfWeek } from 'client/utils/dayjs';
import { hasWorkerPassedCredas } from 'client/utils/task';

type RightToWorkDetailSubPageContentProps = {
    task: ComplianceTask;
};

type RightToWorkDetailPageContentProps = RightToWorkDetailSubPageContentProps & {
    displayTypeFormLink: boolean;
};

const Incomplete = (): JSX.Element => {
    return (
        <Box>
            {/* needs to be updated */}
            <Heading level={4}>Right to work</Heading>
            <p>
                <Text>We need to check your right to work during an interview.</Text>
            </p>
            <p>
                <Text>
                    You will need your passport, any Biometric Residency Permit documents and Visa
                    Share Code ready.
                </Text>
            </p>
        </Box>
    );
};

const Success = ({ task }: RightToWorkDetailSubPageContentProps): JSX.Element => {
    return (
        <>
            <Heading level={4}>Right to work</Heading>
            <Text>Your right to work was </Text>
            <Text sx={{ fontWeight: 'bold' }}>completed</Text>
            <Text> on {formatDateWithDayOfWeek(task.completedDate)}.</Text>
        </>
    );
};

const Expired = (): JSX.Element => {
    return (
        <>
            {/* needs to be updated */}
            <Heading level={4}>Right to work</Heading>
            <p>
                <Text>Let's get your Right to work check done!</Text>
            </p>
            <Button
                sx={{ width: ['100%', 'auto'], marginBlockStart: 4 }}
                onClick={() => {
                    datadogRum.addAction(RightToWorkTaskTrackingEvents.startBtnClicked);
                    window.open(
                        `https://form.typeform.com/to/IyZiWrNx?typeform-source=indeedflex.typeform.com`,
                        '_blank'
                    );
                }}
            >
                Start
            </Button>
        </>
    );
};

const NeedsReview = (): JSX.Element => {
    return (
        <>
            {/* needs to be updated */}
            <Heading level={4}>Right to work</Heading>
            <Text>
                {' '}
                Unfortunately, we need to review your Right to work documents. Please arrange an
                interview to discuss further.
            </Text>
        </>
    );
};

const PageContent = ({
    task,
    displayTypeFormLink,
}: RightToWorkDetailPageContentProps): JSX.Element => {
    const ukCitizenship: boolean | null = task?.metadata.rightToWork.ukCitizen;

    if (displayTypeFormLink) {
        // TODO: use the util function to see if they passed the share code
        return <Expired />;
    } else if (
        hasWorkerPassedCredas(task!.status) ||
        task!.status === 'COMPLETED' ||
        task!.status === 'APPROVED' ||
        task!.status === 'FLEX_APPROVED'
    ) {
        return <Success task={task} />;
    } else if (task!.status === 'NEEDS_REVIEW' || (!ukCitizenship && task!.status === 'REJECTED')) {
        return <NeedsReview />;
    } else {
        return <Incomplete />;
    }
};

export default PageContent;
