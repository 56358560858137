import React from 'react';
import { Text } from '@indeed/ifl-components';

import Block from 'client/components/Block';
import { FlexHelpEmail } from 'client/components/Emails';
import { useAuthentication } from 'client/contexts/AuthenticationContext';

const NoTasksBlock = (): JSX.Element => {
    const { logoutUrl } = useAuthentication();
    return (
        <Block>
            <Text level={2}>
                Are you expecting a task? Make sure you are logged in with the same email as your
                Indeed Flex account and <a href={logoutUrl}>try again</a>. If you continue having
                issues, please contact <FlexHelpEmail />.
            </Text>
        </Block>
    );
};

export default NoTasksBlock;
