import countryList from 'react-select-country-list';
import { IUSAState } from 'typed-usa-states/dist/typings';
import { capitalizePhrase } from './transforms';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { usaStatesFull } from 'typed-usa-states';
import { useMemo } from 'react';

export const withDisabledOption = (
    label: string,
    options: SelectFormatType[]
): SelectFormatType[] => {
    return [{ label, value: '', disabled: true }, ...options];
};

type GenderMapType = Record<Exclude<WorkerGender, 'BLANK'>, string>;
type LegalGenderMapType = Record<Exclude<WorkerLegalGender, 'BLANK'>, string>;

export type OptionItem = {
    id: string;
    name: string;
};

export const TITLE_LIST = ['Mr.', 'Mrs.', 'Ms.'];

export const genderMap: GenderMapType = {
    /* eslint-disable @typescript-eslint/naming-convention */
    MALE: 'Male',
    FEMALE: 'Female',
    OTHER: 'Non-binary/third gender',
    UNSPECIFIED: 'Prefer to not disclose',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const legalGenderMap: LegalGenderMapType = {
    /* eslint-disable @typescript-eslint/naming-convention */
    MALE: 'Male',
    FEMALE: 'Female',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const getGenderOptions = (
    map: GenderMapType | LegalGenderMapType = genderMap
): SelectFormatType[] =>
    Object.entries(map).map(([key, value]) => ({
        value: key,
        label: value,
    }));

export const getTitleOptions = (): SelectFormatType[] =>
    TITLE_LIST.map((title) => ({ value: title, label: title }));

type CitizenshipMapType = Record<Exclude<CitizenshipStatus, 'BLANK'>, string>;

export const citizenshipMap: CitizenshipMapType = {
    /* eslint-disable @typescript-eslint/naming-convention */
    US_CITIZEN: 'A citizen of the United States',
    NON_US_CITIZEN: 'A noncitizen national of the United States (See instructions)',
    LAWFUL_PERMANENT_RESIDENT: 'A lawful permanent resident',
    ALIEN_AUTHORIZED_TO_WORK: 'An alien authorized to work (See instructions)',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const getCitizenshipOptions = (): SelectFormatType[] =>
    Object.entries(citizenshipMap).map(([key, value]) => ({
        value: key,
        label: value,
    }));

type GovernmentIdImageStatusMapType = Pick<
    Record<GovernmentIdImageStatus, string>,
    'APPROVED' | 'REJECTED'
>;

const statuses: GovernmentIdImageStatusMapType = {
    /* eslint-disable @typescript-eslint/naming-convention */
    APPROVED: 'Pass',
    REJECTED: 'Fail',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const getPhotoVerifyOptions = (): SelectFormatType[] =>
    Object.entries(statuses).map(([key, value]) => ({
        value: key,
        label: value,
    }));

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const getMonthOptions = (): SelectFormatType[] => {
    // Months start at 0=January for js Dates
    return months.map((month, idx) => ({
        label: month,
        value: idx.toString(),
    }));
};

export const getDayOfMonthOptions = (): SelectFormatType[] => {
    return [...Array(31).keys()].map((i) => ({
        label: (i + 1).toString(),
        // Day of month starts at 1 for js Dates
        value: (i + 1).toString(),
    }));
};

const sortStates = (a: SelectFormatType, b: SelectFormatType): number => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
};

export const getUsaStateOptions = (fullNameLabel = true): SelectFormatType[] => {
    return [
        { label: '', value: '', disabled: true },
        ...usaStatesFull.map((s: IUSAState) => ({
            label: fullNameLabel ? s.name : s.abbreviation,
            value: s.abbreviation,
        })),
    ];
};

export const getI9UsaStateOptions = (fullNameLabel = true): SelectFormatType[] => {
    const statesToRemove = ['FM', 'PW', 'MH'];
    const states = getUsaStateOptions(fullNameLabel)
        .filter((stateOption) => {
            if (statesToRemove.includes(stateOption.value)) {
                return false;
            }
            return true;
        })
        .sort(sortStates);
    states.push(
        { label: fullNameLabel ? 'Canada' : 'CAN', value: 'CAN' },
        { label: fullNameLabel ? 'Mexico' : 'MEX', value: 'MEX' }
    );
    return states;
};

export const getUsaStateCodes = (): SelectFormatType[] => {
    return getUsaStateOptions(false);
};

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const gbFirst = (a: CountryData, b: CountryData): number => {
    return a.value === 'GB' ? -1 : 0;
};

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const usGbFirst = (a: CountryData, b: CountryData): number => {
    if (a.value === 'US') {
        return -1;
    }
    return a.value === 'GB' ? -1 : 0;
};

const sortCountries = (a: CountryData, b: CountryData): number => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
};

/**
 * Return list of country options
 * @param sortGbFirst Sort GB first, below the empty disabled option
 */
export const useCountryCodes = (sortGbFirst = true): SelectFormatType[] => {
    let options = useMemo(
        () => [{ value: 'XK', label: 'Kosovo' }, ...countryList().getData()].sort(sortCountries),
        []
    );
    if (sortGbFirst) {
        // create new array since sort is in place and won't get new list from memo()
        options = [...options].sort(gbFirst);
    }
    return withDisabledOption('', options);
};

export const getCountryFromCountryCode = (countryCode: string): string => {
    const countries = [{ value: 'XK', label: 'Kosovo' }, ...countryList().getData()];
    const result = countries.filter((country) => country.value === countryCode);
    if (result.length === 0) {
        return 'Country not found';
    }
    return result[0].label;
};

export const useCountryCallingCodes = (sortUsGbFirst = true): SelectFormatType[] => {
    let countries = getCountries().map(
        (country) =>
            ({
                value: country,
                label: `${country} +${getCountryCallingCode(country)}`,
            } as CountryData)
    );
    if (sortUsGbFirst) {
        countries = countries.sort(usGbFirst);
    }
    return countries;
};

const securityQuestions = [
    "What is your mother's name?",
    "What is your father's name?",
    'In what city were you born?',
    'What is the birth date of your son?',
    'What is the birth date of your daughter?',
];

export const getSecurityQuestionOptions = (): SelectFormatType[] => {
    return securityQuestions.map((question) => ({
        label: question,
        value: question,
    }));
};

export const getFormI9DocumentOptions = (includeOrText = false): SelectFormatType[] => {
    const labels = [
        { label: 'Alien registration/USCIS Number', value: 'ALIEN_NUMBER' },
        { label: 'Form I-94 Admission Number', value: 'ADMISSION_NUMBER' },
        { label: 'Foreign passport', value: 'PASSPORT' },
    ];
    if (includeOrText) {
        labels.map((item, index) => {
            const isLastItem = index === labels.length - 1;
            if (!isLastItem) {
                item.label = `${item.label} OR`;
            }
            return item;
        });
    }
    return labels;
};

const sortBgcPackages = (a: SelectFormatType, b: SelectFormatType): number => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
};

export const getBackgroundCheckPackageOptions = (
    packages: BackgroundCheckPackage[]
): SelectFormatType[] => {
    const options = packages
        .map(
            (backgroundCheckPackage) =>
                ({
                    value: backgroundCheckPackage.package,
                    label: backgroundCheckPackage.title,
                } as SelectFormatType)
        )
        .sort(sortBgcPackages);
    return withDisabledOption('', options);
};

export const taskLocationOptions = [{ label: 'California', value: 'CA' }];

export const yesNoOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

type WorkingHourLimitOption = {
    label: string;
    value: WorkingHourLimitEnum;
};

export const workingHourLimitOptions: WorkingHourLimitOption[] = [
    { label: 'Unlimited', value: 'DEFAULT' },
    { label: '10', value: 'TEN_HOURS' },
    { label: '20', value: 'TWENTY_HOURS' },
];

export const workingHourLimitLabels: Record<WorkingHourLimitEnum, string> = {
    /* eslint-disable @typescript-eslint/naming-convention */
    DEFAULT: 'Unlimited',
    TEN_HOURS: '10 hours',
    TWENTY_HOURS: '20 hours',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const mapClientOrRoleToOptions = (data: Role[] | Client[]): OptionItem[] =>
    data.map((item) => ({
        id: item.flexId!,
        name: item.name!,
    }));

export const mapProgressStatusCountsToOptions = (
    progressStatusCounts:
        | Array<{
              agentDisplayStatus: string;
              count: number;
              progressStatus: ProgressStatus;
          }>
        | null
        | undefined
): OptionItem[] =>
    progressStatusCounts?.map((progressStatusCount) => ({
        id: progressStatusCount.progressStatus,
        name:
            progressStatusCount.agentDisplayStatus ||
            capitalizePhrase(progressStatusCount.progressStatus),
    })) || [];

export const mapPlacementsStatusCountsToOptions = (
    statusCounts?: Array<{
        count: number;
        status: PlacementStatus;
        displayStatus: string;
    }> | null
): OptionItem[] =>
    statusCounts?.map((statusCount) => ({
        id: statusCount.status,
        name: statusCount.displayStatus,
    })) || [];
