/**
This will take a dictionary where the keys are snake case and convert it to camle case
i.e. {'this_is_a_key': True, 'another_key': False} -> {'thisIsAKey': True, 'anotherKey': False} 
*/

const toCamel = (s: string): string => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};

export const keysToCamel = (o: unknown): unknown => {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n = {};
        // @ts-expect-error: Let's ignore a compile error
        Object.keys(o).forEach((k) => {
            // @ts-expect-error: Let's ignore a compile error
            n[toCamel(k)] = keysToCamel(o[k]);
        });
        return n;
    } else if (Array.isArray(o)) {
        return o.map((i) => {
            return keysToCamel(i);
        });
    }
    return o;
};
