import ItemProgressIcon from 'client/components/ItemProgressIcon';
import React from 'react';
import StatusTag from 'client/components/StatusTag';
import { Box, Flex, Heading } from '@indeed/ifl-components';
import { ButtonLinkProps } from 'client/components/ButtonLink';
import { ChevronRight } from '@indeed/ifl-icons';
import { Link } from 'react-router-dom';
import { getTaskTitle } from 'client/utils/task';

export const getPathname = (task: ComplianceTask): string => {
    const { slug, type } = task;
    switch (type) {
        case 'BACKGROUND_CHECK':
            return `/background-check/${slug}`;
        case 'CONDITIONAL_JOB_OFFER':
            return `/conditional-job-offer/${slug}`;
        case 'FORM_I9':
            return `/i-9/${slug}`;
        case 'PAYROLL':
            return `/payroll/${slug}`;
        case 'UK_PAYROLL':
            return `/uk-payroll/${slug}`;
        case 'PROFILE':
            return `/profile/${slug}`;
        case 'EMERGENCY_CONTACT':
            return `/emergency-contact/${slug}`;
        case 'LOCATION_REQUIREMENTS':
            return `/additional-documents/location-requirements`;
        case 'MEDIA_REQUIREMENTS':
            return `/additional-trainings/media-requirements`;
        case 'UK_DOCUMENTS':
            return `/uk-documents/${slug}`;
        case 'BANK_DETAILS':
            return `/bank-details/${slug}`;
        case 'FEDERAL_TAX_ELECTIONS':
            return `/federal-tax-elections/${slug}`;
        case 'STATE_TAX_ELECTIONS':
            return `/state-tax-elections/${slug}`;
        case 'RIGHT_TO_WORK':
            return `/right-to-work/${slug}`;
        case 'ONBOARDED':
            return task.redirectUrl ?? '';
        default:
            // @ts-ignore
            throw new Error(`Unsupported task type ${type} getting task card to`);
    }
};

const getTo = (task: ComplianceTask): { pathname: string; search: Location['search'] } => {
    return {
        pathname: getPathname(task),
        search: window.location.search,
    };
};

const getLinkProps = (task: ComplianceTask): ButtonLinkProps => {
    if (task.type === 'PAYROLL') {
        // payroll link should open in new tab since it redirects away from the webapp
        return { to: getTo(task), target: '_blank', rel: 'nofollow' };
    }
    return { to: getTo(task) };
};

/**
 * Determine whether or not to show the status tag on the task item on the index page
 * Unrelated to the status tag on the task page
 */
const getShowStatusTag = (task: ComplianceTask): boolean => {
    if (
        (task.type === 'LOCATION_REQUIREMENTS' ||
            task.type === 'CONDITIONAL_JOB_OFFER' ||
            task.type === 'MEDIA_REQUIREMENTS') &&
        task.completedDate
    ) {
        // to avoid a "completed" tag or something similar
        return false;
    }
    if (
        ['BACKGROUND_CHECK', 'FORM_I9'].includes(task.type) &&
        ['REJECTED', 'NEEDS_REVIEW'].includes(task.status)
    ) {
        // since there are actions to take if rejected/needs review, show the tag
        return true;
    }
    // otherwise, show if not processed yet
    return !task.processedDate;
};

interface TaskItemProps {
    tasks: ComplianceTask[];
}

interface TaskItemIconProps {
    task: ComplianceTask;
}

const TaskItemIcon = ({ task }: TaskItemIconProps): JSX.Element => {
    const isCompleted =
        task.status !== 'EXPIRING' && task.status !== 'EXPIRED' && task.completedDate;

    const itemStatus = isCompleted ? 'COMPLETED' : task.status;

    return <ItemProgressIcon status={itemStatus} />;
};

const TaskItem = ({ tasks }: TaskItemProps): JSX.Element => {
    // Making assumption that all tasks in the list are the same type
    const title = getTaskTitle(tasks[0]);
    const linkProps = getLinkProps(tasks[0]);

    const showStatusTag = getShowStatusTag(tasks[0]);
    return (
        <Link {...linkProps}>
            <Flex
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '.5rem',
                    mx: '-1rem',
                    padding: '1rem 1rem',
                    borderBottom: ({ colors }) => `1px solid ${colors.neutral['300']}`,
                }}
            >
                <Box sx={{ height: '1.5rem' }}>
                    <TaskItemIcon task={tasks[0]} />
                </Box>
                <Box sx={{ flex: '1' }}>
                    <Heading level={3} sx={{ m: 0 }}>
                        {title}
                    </Heading>
                    {showStatusTag && <StatusTag task={tasks[0]} sx={{ mt: '.5rem' }} />}
                </Box>
                <Box sx={{ height: '1.5rem', alignSelf: 'center' }}>
                    <ChevronRight color="neutral.700" aria-hidden />
                </Box>
            </Flex>
        </Link>
    );
};

export default TaskItem;
