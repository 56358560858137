import React from 'react';
import { DatadogAction } from 'client/utils/logging';
import { Error4 } from '@indeed/ipl-illustrations-spot';
import {
    GraphicContentSection,
    TextContentSection,
} from 'client/pages/i9/WorkBrightI9/ContentSection';
import { LogRenderDatadogAction } from 'client/components/LogRenderDatadogAction';

export const WorkBrightI9Rejected = (): JSX.Element => {
    return (
        <LogRenderDatadogAction action={DatadogAction.i9RejectedRendered}>
            <GraphicContentSection>
                <Error4 sx={{ display: 'flex' }} />
            </GraphicContentSection>
            <TextContentSection
                heading="Your I-9 form has been rejected."
                text="Your case is now closed. We apologize but you won't be able to work with Indeed Flex US."
                omitSummaryHeading
            />
        </LogRenderDatadogAction>
    );
};
export default WorkBrightI9Rejected;
