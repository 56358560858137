import { FetchResult } from '@apollo/client';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useCallback } from 'react';
import { useImportMissingFlexTaskDataMutation } from 'client/hooks/graphql';
import { useToast } from 'client/contexts/ToastContext';

const useImportMissingFlexData = (
    workerSlug: string,
    scopeKey: string,
    scopeValue: string
): [() => Promise<FetchResult<ImportMissingFlexTaskDataMutation> | void>] => {
    const [callImportMissingFlexTaskDataMutation] = useImportMissingFlexTaskDataMutation();
    const { pushToast } = useToast();
    const callImportMissingFlexTaskData =
        useCallback((): Promise<FetchResult<ImportMissingFlexTaskDataMutation> | void> => {
            const variables = {
                input: {
                    workerSlug: workerSlug,
                },
            };
            return callImportMissingFlexTaskDataMutation({ variables }).catch((apolloError) => {
                reportErrorToThirdPartyTools(apolloError, scopeKey, scopeValue);
                pushToast(
                    'There was an error while attempting to retrieve your data, please try again.',
                    { status: 'critical' }
                );
            });
        }, [workerSlug]);
    return [callImportMissingFlexTaskData];
};

export default useImportMissingFlexData;
