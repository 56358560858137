import React from 'react';
import { Button } from '@indeed/ifl-components';

import ConfirmEntry from 'client/components/ConfirmEntry';
import { BackgroundCheckFormValues } from 'client/pages/bgc/components/Form/formConstants';
import { genderMap } from 'client/utils/options';
import { useHistory } from 'react-router-dom';

interface ConfirmEntriesProps {
    data: BackgroundCheckFormValues;
}

const ConfirmEntries = ({ data }: ConfirmEntriesProps): JSX.Element => {
    const values = {
        ...(data.ssn ? { ssn: `*** - ** - ${data.ssn.toString().slice(-4)}` } : {}),
        // @ts-ignore: cannot choose BLANK in form
        ...(data.gender ? { gender: genderMap[data.gender as WorkerGender] } : {}),
        bornInPuertoRico: data.bornInPuertoRico === 'true' ? 'Yes' : 'No',
        mothersMaidenName: data.mothersMaidenName,
    };
    return (
        <div>
            {!!values.gender && <ConfirmEntry title="Gender">{values.gender}</ConfirmEntry>}
            {!!values.ssn && (
                <ConfirmEntry title="Social security number">{values.ssn}</ConfirmEntry>
            )}
            <ConfirmEntry title="Were you born in Puerto Rico?">
                {values.bornInPuertoRico}
            </ConfirmEntry>
            {!!values.mothersMaidenName && (
                <ConfirmEntry title="Mother's maiden name">{values.mothersMaidenName}</ConfirmEntry>
            )}
        </div>
    );
};

interface ConfirmProps {
    data: BackgroundCheckFormValues;
    dirty: boolean;
    baseUrl: string;
    disabled: boolean;
}

const Confirm = ({ data, dirty, baseUrl, disabled }: ConfirmProps): JSX.Element => {
    const history = useHistory();

    if (!dirty) {
        // loaded page directly, needs to start from page one
        history.push({ pathname: baseUrl, search: window.location.search });
    }

    return (
        <>
            <ConfirmEntries data={data} />
            <Button
                type="submit"
                loading={disabled}
                disabled={disabled}
                sx={{ marginBlockStart: 2 }}
                full
            >
                Confirm and submit
            </Button>
        </>
    );
};

export default Confirm;
