import * as FormikFields from 'client/components/FormikFields';
import FormRow from 'client/components/FormRow';
import React from 'react';
import { Box, Button, Divider, Heading, Text } from '@indeed/ifl-components';
import { Col } from '@indeed/itmp-components';
import { FormikProps } from 'formik';
import {
    PayrollFieldValues,
    getStudentLoanOptions,
    getTaxInfoOptions,
    isNiNumberRequired,
    waitingForNiNumberLabel,
} from 'client/pages/ukpayroll/components/Form/formConstants';
import { UkPayrollTaskTrackingEvents } from 'client/pages/ukpayroll/UkPayroll.tracking';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory } from 'react-router-dom';

export interface FormProps {
    formik: FormikProps<PayrollFieldValues>;
    baseUrl: string;
    task: ComplianceTask;
}

const Form = ({ formik, baseUrl, task }: FormProps): JSX.Element => {
    const history = useHistory();

    const onAccept = (): void => {
        datadogRum.addAction(UkPayrollTaskTrackingEvents.submitBtnClicked);
        history.push({
            pathname: `${baseUrl}/confirm`,
            search: window.location.search,
        });
    };

    const niNumberFieldDisabled =
        !isNiNumberRequired(formik.values) ||
        (!!formik.initialValues.niNumber && task.status === 'COMPLETED');

    return (
        <>
            <Box>
                <Box sx={{ marginBlockEnd: 6 }}>
                    <Heading level={4}>Payment details</Heading>
                    <Text>Please complete and review the form below.</Text>
                </Box>
                <FormRow>
                    <Col>
                        <FormikFields.TextField
                            inputProps={{
                                autoComplete: 'name',
                                required: true,
                                className: 'dd-privacy-hidden',
                            }}
                            label="Account holder name"
                            name="name"
                        />
                    </Col>
                </FormRow>
                <FormRow>
                    <Col>
                        <FormikFields.MaskedTextField
                            inputProps={{
                                autoComplete: 'off',
                                required: true,
                                className: 'dd-privacy-hidden',
                                /* eslint-disable-next-line @typescript-eslint/naming-convention */
                                'aria-label': 'Sort code',
                            }}
                            label="Sort code"
                            name="sortCode"
                            mask="99-99-99"
                            showInitialValue={true}
                        />
                    </Col>
                </FormRow>
                <FormRow>
                    <Col>
                        <FormikFields.TextField
                            inputProps={{
                                autoComplete: 'off',
                                required: true,
                                className: 'dd-privacy-hidden',
                                maxLength: 8,
                                minLength: 8,
                            }}
                            label="Account number"
                            name="bankAccountNumber"
                        />
                    </Col>
                </FormRow>
                <Divider sx={{ my: '1.5rem' }} />
                <Box sx={{ marginBlockEnd: 6 }}>
                    <Heading level={4}>Tax information</Heading>
                </Box>
                <FormRow>
                    <Col>
                        <FormikFields.TextField
                            inputProps={{
                                autoComplete: 'off',
                                required: !niNumberFieldDisabled,
                                disabled: niNumberFieldDisabled,
                                className: 'dd-privacy-hidden',
                                /* eslint-disable-next-line @typescript-eslint/naming-convention */
                                'aria-label': 'National Insurance number',
                                onChange: (event) => {
                                    event.target.value = (
                                        event.target as HTMLInputElement
                                    ).value.toUpperCase();
                                    formik.handleChange(event);
                                },
                            }}
                            label="National Insurance number"
                            name="niNumber"
                        />
                    </Col>
                </FormRow>
                <FormRow>
                    <Col>
                        <FormikFields.CheckboxField
                            checkboxProps={{
                                className: 'dd-privacy-hidden',
                                showRequiredIndicator: !formik.values.waitingForNiNumber,
                                onChange: (event) => {
                                    const checked = (event.target as HTMLInputElement).checked;
                                    if (checked) {
                                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                        formik.setFieldValue('niNumber', '');
                                    }
                                    formik.handleChange(event);
                                },
                                disabled: !!formik.values.niNumber,
                            }}
                            items={[
                                {
                                    value: 'WAITING',
                                    label: waitingForNiNumberLabel,
                                },
                            ]}
                            name="waitingForNiNumber"
                        />
                    </Col>
                </FormRow>
                <Divider sx={{ my: '1.5rem' }} />
                <FormRow>
                    <Col>
                        <FormikFields.RadioField
                            label="Since the sixth of April"
                            items={getTaxInfoOptions()}
                            name="taxInfo"
                            radioProps={{
                                showRequiredIndicator: true,
                                className: 'dd-privacy-hidden',
                            }}
                        />
                    </Col>
                </FormRow>
                <Divider sx={{ my: '1.5rem' }} />
                <FormRow>
                    <Col>
                        <FormikFields.RadioField
                            label="Do you have a student loan?"
                            items={getStudentLoanOptions()}
                            name="studentLoan"
                            radioProps={{
                                showRequiredIndicator: true,
                                className: 'dd-privacy-hidden',
                            }}
                        />
                    </Col>
                </FormRow>
            </Box>
            <FormRow sx={{ marginBlock: 8 }}>
                <Col>
                    <Button
                        type="button"
                        onClick={onAccept}
                        loading={formik.isSubmitting}
                        disabled={!formik.isValid || !formik.dirty}
                        full
                    >
                        Submit
                    </Button>
                </Col>
            </FormRow>
        </>
    );
};

export default Form;
