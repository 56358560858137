import qs, { ParsedQs } from 'qs';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const useQueryParams = (): ParsedQs => {
    const { search } = useLocation();
    return useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
};

export default useQueryParams;
