import Confirm from 'client/pages/profile/components/Confirm';
import ConfirmAddress from 'client/pages/profile/components/ConfirmAddress';
import Form from 'client/pages/profile/components/Form';
import React from 'react';
import { A11yPageTitle } from 'client/components/Page';
import { ApolloError } from '@apollo/client';
import { Box, Heading } from '@indeed/ifl-components';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import {
    ProfileFormValues,
    getInitialValues,
    getValidationSchema,
    toUpdateWorkerInput,
} from 'client/pages/profile/components/Form/formConstants';
import { ProfileTaskTrackingEvents } from 'client/pages/profile/Profile.tracking';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { UsSsnDuplicatedAlert } from 'client/components/UsSsnFields/UsSsnDuplicatedAlert/UsSsnDuplicatedAlert';
import { datadogRum } from '@datadog/browser-rum';
import { getFormikErrors } from 'client/utils/graphql';
import { getStatusIncludesDuplicatedSsn } from 'client/components/UsSsnFields/UsSsnDuplicatedAlert/UsSsnDuplicatedAlert.utils';
import { indexLocation } from 'client/utils/redirect';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useFeatureFlagContext } from 'client/contexts/FeatureFlagContext';
import { useLocaleContext } from 'client/contexts/LocaleContext';
import { useTasks } from 'client/contexts/TasksContext';
import { useToast } from 'client/contexts/ToastContext';
import { useUpdateTaskStatusMutation, useUpdateWorkerMutation } from 'client/hooks/graphql';
import { useWorker } from 'client/contexts/WorkerContext';
type ProfileFormRouterProps = {
    task: ComplianceTask;
};

const FormRouter = ({ task }: ProfileFormRouterProps): JSX.Element => {
    const { pushToast } = useToast();
    const { url, path } = useRouteMatch();
    const { worker } = useWorker();
    const { updateTask } = useTasks();
    const history = useHistory();
    const [callUpdateWorkerMutation] = useUpdateWorkerMutation();
    const [callUpdateTaskStatusMutation] = useUpdateTaskStatusMutation();
    const { country } = useLocaleContext();
    const { reloadFeatureFlags } = useFeatureFlagContext();
    const onSubmit = async (
        values: ProfileFormValues,
        formikHelpers: FormikHelpers<ProfileFormValues>
    ): Promise<void> => {
        datadogRum.addAction(ProfileTaskTrackingEvents.submitBtnClicked);
        const input = toUpdateWorkerInput(worker!.accountKey!, values);
        input.country = country;
        try {
            const { data } = await callUpdateWorkerMutation({
                variables: { updateWorkerInput: input },
                refetchQueries: ['GetWorker'],
            });

            const result = data?.updateWorker?.result;
            if (result) {
                callUpdateTaskStatusMutation({
                    variables: { slug: task.slug, status: 'COMPLETED', progressStatus: 'APPROVED' },
                })
                    .then(({ data: taskData }) => {
                        const taskResult = taskData?.updateTask?.result;
                        if (taskResult) {
                            updateTask(taskResult as Partial<ComplianceTask>);
                            reloadFeatureFlags()
                                .then(() => {
                                    history.push(indexLocation);
                                })
                                .catch(() => {
                                    reportErrorToThirdPartyTools(
                                        new Error(
                                            `Unable to reload feature flags after profile update`
                                        ),
                                        'profilePage',
                                        'form-submit'
                                    );
                                    pushToast(
                                        'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                                        { status: 'warning' }
                                    );
                                });
                        } else {
                            reportErrorToThirdPartyTools(
                                new Error(
                                    `Could not retrieve result from update task mutation given input: taskSlug:${task.slug}, taskStatus:${task.status}`
                                ),
                                'profilePage',
                                'form-submit'
                            );
                            pushToast(
                                'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                                { status: 'warning' }
                            );
                        }
                    })
                    .catch((apolloError) => {
                        reportErrorToThirdPartyTools(apolloError, 'profilePage', 'onFormSubmit');

                        pushToast(
                            'There was an error while attempting to submit the information, please try again.',
                            { status: 'critical' }
                        );
                    });
            } else {
                reportErrorToThirdPartyTools(
                    new Error(
                        `Could not retrieve result from update worker mutation given input ${JSON.stringify(
                            input
                        )}`
                    ),
                    'profilePage',
                    'onFormSubmit'
                );
                pushToast(
                    'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                    { status: 'warning' }
                );
            }
        } catch (err) {
            const apolloError = err as ApolloError;
            const formikErrors = getFormikErrors<ProfileFormValues>(apolloError);
            if (formikErrors) {
                // If SSN duplication set it in status which doesn't clear up on blur/change
                if (getStatusIncludesDuplicatedSsn(formikErrors)) {
                    formikHelpers.setStatus(formikErrors);
                    reportErrorToThirdPartyTools(
                        new Error('SSN duplicated'),
                        'profilePage',
                        'onFormSubmit'
                    );
                } else {
                    formikHelpers.setErrors(formikErrors);
                }

                // going back to base url (profile task) to skip the confirm address screen if present
                history.push({ pathname: url });
            } else {
                reportErrorToThirdPartyTools(apolloError, 'profilePage', 'onFormSubmit');
                pushToast(
                    'There was an error while attempting to submit the information, please try again.',
                    { status: 'critical' }
                );
            }
        }
    };

    return (
        <Formik
            initialValues={getInitialValues(worker!, country)}
            validationSchema={getValidationSchema(country)}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <FormikForm>
                    <Route path={path} exact>
                        <Box
                            sx={{
                                maxWidth: ['auto', '24rem'],
                                mx: [0, 'auto'],
                            }}
                        >
                            <A11yPageTitle title="Profile Form" />
                            <UsSsnDuplicatedAlert formik={formik} />
                            <Heading level={4}>Profile</Heading>
                            <Box sx={{ mb: '1rem' }}>
                                Please complete and review the form below.
                            </Box>
                            <Form formik={formik} baseUrl={url} country={country} />
                        </Box>
                    </Route>
                    <Route path={`${path}/confirm-address`}>
                        <A11yPageTitle title="Profile Address Confirm" />
                        <Heading level={4}>Confirm your address</Heading>
                        <Box sx={{ marginBlockEnd: 4 }}>
                            Please review the recommended changes before submitting:
                        </Box>
                        <ConfirmAddress
                            data={formik.values}
                            formik={formik}
                            dirty={formik.dirty}
                            isSubmitting={formik.isSubmitting}
                            baseUrl={url}
                        />
                    </Route>
                    <Route path={`${path}/confirm`}>
                        <A11yPageTitle title="Profile Confirm" />
                        <Heading level={4}>Confirm your information</Heading>
                        <Box sx={{ mb: '1rem' }}>Please review before submitting.</Box>
                        <Confirm
                            data={formik.values}
                            dirty={formik.dirty}
                            baseUrl={url}
                            isSubmitting={formik.isSubmitting}
                        />
                    </Route>
                </FormikForm>
            )}
        </Formik>
    );
};

export default FormRouter;
