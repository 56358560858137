import React from 'react';
import { Button } from '@indeed/ifl-components';
import { useHistory } from 'react-router-dom';

import ConfirmEntry from 'client/components/ConfirmEntry';
import { Box } from '@indeed/ifl-components';
import {
    PayrollFieldValues,
    isNiNumberRequired,
    studentLoanOptions,
    taxInfoOptions,
    waitingForNiNumberLabel,
} from 'client/pages/ukpayroll/components/Form/formConstants';

interface ConfirmEntriesProps {
    data: PayrollFieldValues;
}

const ConfirmEntries = ({ data }: ConfirmEntriesProps): JSX.Element => {
    return (
        <div>
            <ConfirmEntry title="Account holder name">{data.name}</ConfirmEntry>
            <ConfirmEntry title="Sort code">{data.sortCode}</ConfirmEntry>
            <ConfirmEntry title="Account number">{data.bankAccountNumber}</ConfirmEntry>
            <ConfirmEntry title="National Insurance number">
                {isNiNumberRequired(data) ? data.niNumber : waitingForNiNumberLabel}
            </ConfirmEntry>
            <ConfirmEntry title="Tax declaration">{taxInfoOptions[data.taxInfo!]}</ConfirmEntry>
            {data.studentLoan && (
                <ConfirmEntry title="Student loan">
                    {studentLoanOptions[data.studentLoan]}
                </ConfirmEntry>
            )}
        </div>
    );
};

interface ConfirmProps {
    data: PayrollFieldValues;
    dirty: boolean;
    isSubmitting: boolean;
    baseUrl: string;
}

const Confirm = ({ data, dirty, baseUrl, isSubmitting }: ConfirmProps): JSX.Element => {
    const history = useHistory();

    if (!dirty) {
        // loaded page directly, needs to start from page one
        history.push({ pathname: baseUrl, search: window.location.search });
    }

    return (
        <>
            <ConfirmEntries data={data} />
            <Box sx={{ marginBlock: 8 }}>
                <Button type="submit" full loading={isSubmitting}>
                    Confirm and submit
                </Button>
            </Box>
        </>
    );
};

export default Confirm;
