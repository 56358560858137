const INTERNAL_TOOLS_BASE_URL = '/internal/admin';
const ADMIN_WORKERS_URL = `${INTERNAL_TOOLS_BASE_URL}/workers`;
export const URLS = {
    base: INTERNAL_TOOLS_BASE_URL,
    rtw: (slug: string) => `${ADMIN_WORKERS_URL}/${slug}/rtw`,
    adminWorkers: ADMIN_WORKERS_URL,
    tasks: `${INTERNAL_TOOLS_BASE_URL}/tasks`,
    placements: `${INTERNAL_TOOLS_BASE_URL}/placements`,
    adminWorkerTasks: (slug: string) => `${ADMIN_WORKERS_URL}/${slug}/tasks`,
    adminWorkerOnboardedTask: (workerSlug: string, taskSlug: string) =>
        `${ADMIN_WORKERS_URL}/${workerSlug}/tasks/${taskSlug}`,
    adminWorkerTasksDocuments: (workerSlug: string, taskSlug: string) =>
        `${ADMIN_WORKERS_URL}/${workerSlug}/tasks/${taskSlug}/documents`,
};
