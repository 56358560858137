import React, { useState } from 'react';
import { Box, Button, Checkbox, Text } from '@indeed/ifl-components';
import { useHistory } from 'react-router-dom';

import ConfirmAddressEntry from 'client/components/ConfirmAddressEntry';
import ConfirmEntry from 'client/components/ConfirmEntry';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { ProfileFormValues } from 'client/pages/profile/components/Form/formConstants';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';
import { genderMap } from 'client/utils/options';
import { getWorkerFullName } from 'client/utils/worker';
import { ssnFormat } from 'client/utils/ssnFormat';

interface ConfirmEntriesProps {
    data: ProfileFormValues;
}

const ConfirmEntries = ({ data }: ConfirmEntriesProps): JSX.Element => {
    const values = {
        name: getWorkerFullName(data, !data.noMiddleName.includes('true')),
        address: (
            <ConfirmAddressEntry
                address1={data.address1}
                address2={data.address2}
                city={data.city}
                state={data.state}
                postalCode={data.postalCode}
            />
        ),
        // @ts-ignore: cannot choose BLANK in form
        gender: genderMap[data.gender as WorkerGender],
        dateOfBirth: data.dateOfBirth,
        ssn: data.ssn,
        phoneCountry: data.phoneCountry,
        phoneNumber: data.phoneNumber,
        optOut48hrs: data.optOut48hrs.includes('true') ? 'Yes' : 'No',
        title: data.title,
        legalGender: data.legalGender,
    };

    return (
        <div>
            {data.country === SupportedCountryCode.GB && (
                <ConfirmEntry title="Title">{values.title}</ConfirmEntry>
            )}
            <ConfirmEntry title="Full legal name">{values.name}</ConfirmEntry>
            <ConfirmEntry title="Current address">{values.address}</ConfirmEntry>
            <ConfirmEntry title="Gender">
                {data.country === SupportedCountryCode.GB ? values.legalGender : values.gender}
            </ConfirmEntry>
            {values.dateOfBirth && (
                <ConfirmEntry title="Date of birth">{values.dateOfBirth}</ConfirmEntry>
            )}
            {values.ssn && (
                <ConfirmEntry title="Social Security Number">{ssnFormat(values.ssn)}</ConfirmEntry>
            )}
            {values.phoneNumber && values.phoneCountry && (
                <ConfirmEntry title="Phone number">
                    {parsePhoneNumber(
                        values.phoneNumber,
                        values.phoneCountry as CountryCode
                    ).formatNational()}
                </ConfirmEntry>
            )}
            {data.country === SupportedCountryCode.GB && (
                <ConfirmEntry title="Opt-out of the 48 hours work week?">
                    {values.optOut48hrs}
                </ConfirmEntry>
            )}
        </div>
    );
};

interface ConfirmProps {
    data: ProfileFormValues;
    dirty: boolean;
    isSubmitting: boolean;
    baseUrl: string;
}

const Confirm = ({ data, dirty, isSubmitting, baseUrl }: ConfirmProps): JSX.Element => {
    const [confirmed, setConfirmed] = useState(false);
    const history = useHistory();

    if (!dirty) {
        // loaded page directly, needs to start from page one
        history.push({ pathname: baseUrl });
    }

    return (
        <>
            <ConfirmEntries data={data} />
            <Box>
                <Checkbox onChange={({ target: { checked } }) => setConfirmed(checked)}>
                    <Text as="p" sx={{ color: 'foreground.strong' }} level={3}>
                        Please review all information carefully before submitting. Check the box to
                        confirm accuracy and submit. Incorrect information will delay onboarding.
                    </Text>
                </Checkbox>
            </Box>
            <Button type="submit" full loading={isSubmitting} disabled={!confirmed}>
                Confirm and submit
            </Button>
        </>
    );
};

export default Confirm;
