import useQueryParams from 'client/hooks/useQueryParams';
import {
    BgcPackage,
    getBackgroundCheckInput,
    getConditionalJobOfferInput,
    getEmergencyContactInput,
    getFormI9Input,
    getLocationRequirementsInput,
    getPayrollInput,
    getProfileInput,
    getRightToWorkInput,
    getUkDocumentsInput,
    getUkPayrollInput,
} from 'client/utils/graphql';
import {
    filterByTaskType,
    findTaskByType,
    getBackgroundCheckMetadata,
    getLocationRequirementsMetadata,
} from 'client/utils/task';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useAuthentication } from 'client/contexts/AuthenticationContext';
import { useCallback } from 'react';
import { useCreateTasksMutation } from 'client/hooks/graphql';
import { useHistory } from 'react-router-dom';
import { useTaskEligibility } from 'client/contexts/TaskEligibilityContext';
import { useTasks } from 'client/contexts/TasksContext';
import { useToast } from 'client/contexts/ToastContext';
import { useWorker } from 'client/contexts/WorkerContext';

export const isNewBackgroundCheck = (
    bgcTasks: ComplianceTask[],
    bgcPackage: string = BgcPackage.READY2WORK_COUNTY
): boolean => {
    if (bgcPackage === BgcPackage.READY2WORK_COUNTY && bgcTasks.length > 0) {
        return false;
    }
    return !bgcTasks.find((bgc) => getBackgroundCheckMetadata(bgc)?.package === bgcPackage);
};

export const isNewLocation = (
    locationTasks: ComplianceTask[],
    country: string,
    state: string | undefined
): boolean => {
    return !locationTasks.find(
        (lt) =>
            getLocationRequirementsMetadata(lt)?.location?.country === country &&
            getLocationRequirementsMetadata(lt)?.location?.state === state
    );
};

export const getCreateMissingTasksInput = (
    accountKey: string,
    eligibleTaskTypes: TaskType[],
    existingTasks: ComplianceTask[],
    taskStatusMap: Record<string, TaskStatus>,
    worker: ComplianceWorker | null,
    bgcPackage: string | string[],
    state?: string,
    country?: string
): CreateTasksInput => {
    const tasks = [];
    const bgcTasks = filterByTaskType(existingTasks, 'BACKGROUND_CHECK');
    const locationRequirementsTasks = filterByTaskType(existingTasks, 'LOCATION_REQUIREMENTS');

    const cjoExists = findTaskByType(existingTasks, 'CONDITIONAL_JOB_OFFER');
    const i9Exists = findTaskByType(existingTasks, 'FORM_I9');
    const payExists = findTaskByType(existingTasks, 'PAYROLL');
    const profileExists = findTaskByType(existingTasks, 'PROFILE');
    const emergencyContactExists = findTaskByType(existingTasks, 'EMERGENCY_CONTACT');
    const ukPayrollExists = findTaskByType(existingTasks, 'UK_PAYROLL');
    const ukDocumentsExists = findTaskByType(existingTasks, 'UK_DOCUMENTS');
    const rightToWorkExists = findTaskByType(existingTasks, 'RIGHT_TO_WORK');
    if (
        country &&
        isNewLocation(locationRequirementsTasks, country, state) &&
        eligibleTaskTypes.includes('LOCATION_REQUIREMENTS')
    ) {
        tasks.push(
            getLocationRequirementsInput(
                accountKey,
                country!,
                state,
                taskStatusMap.LOCATION_REQUIREMENTS
            )
        );
    }
    if (eligibleTaskTypes.includes('BACKGROUND_CHECK')) {
        if (Array.isArray(bgcPackage)) {
            bgcPackage.forEach((p) => {
                if (isNewBackgroundCheck(bgcTasks, p)) {
                    tasks.push(
                        getBackgroundCheckInput(
                            accountKey,
                            p,
                            country,
                            state,
                            taskStatusMap.BACKGROUND_CHECK
                        )
                    );
                }
            });
        } else if (isNewBackgroundCheck(bgcTasks, bgcPackage)) {
            tasks.push(
                getBackgroundCheckInput(
                    accountKey,
                    bgcPackage,
                    country,
                    state,
                    taskStatusMap.BACKGROUND_CHECK
                )
            );
        }
    }
    if (!cjoExists && eligibleTaskTypes.includes('CONDITIONAL_JOB_OFFER')) {
        tasks.push(getConditionalJobOfferInput(accountKey, taskStatusMap.CONDITIONAL_JOB_OFFER));
    }
    if (!i9Exists && eligibleTaskTypes.includes('FORM_I9')) {
        tasks.push(getFormI9Input(accountKey, taskStatusMap.FORM_I9));
    }

    if (!profileExists && eligibleTaskTypes.includes('PROFILE')) {
        tasks.push(getProfileInput(accountKey, worker, taskStatusMap.PROFILE));
    }
    if (!emergencyContactExists && eligibleTaskTypes.includes('EMERGENCY_CONTACT')) {
        tasks.push(getEmergencyContactInput(accountKey, worker, taskStatusMap.EMERGENCY_CONTACT));
    }
    if (!ukPayrollExists && eligibleTaskTypes.includes('UK_PAYROLL')) {
        tasks.push(getUkPayrollInput(accountKey, taskStatusMap.UK_PAYROLL));
    }
    if (!ukDocumentsExists && eligibleTaskTypes.includes('UK_DOCUMENTS')) {
        tasks.push(getUkDocumentsInput(accountKey, taskStatusMap.UK_DOCUMENTS));
    }

    if (!rightToWorkExists && eligibleTaskTypes.includes('RIGHT_TO_WORK')) {
        tasks.push(getRightToWorkInput(accountKey, taskStatusMap.RIGHT_TO_WORK));
    }

    // Creates Payroll Task
    if (!payExists && eligibleTaskTypes.includes('PAYROLL')) {
        tasks.push(getPayrollInput(accountKey, eligibleTaskTypes, taskStatusMap.PAYROLL));
    }

    return { tasks };
};

interface QueryParamsType {
    bgcPackage?: string | string[];
    state?: string;
    country?: string;
    taskStatusMap?: Record<string, TaskStatus>;
}

const useCreateMissingTasks = (
    scopeKey: string = 'startOnboardingPage',
    scopeValue: string = 'onNextClick'
): [() => Promise<void>] => {
    const {
        bgcPackage = BgcPackage.READY2WORK_COUNTY,
        state,
        country,
        taskStatusMap = {},
    }: QueryParamsType = useQueryParams();
    const { eligibleTaskTypes } = useTaskEligibility();
    const { user } = useAuthentication();
    const { tasks } = useTasks();
    const { worker } = useWorker();

    const accountKey = user?.accountKey;
    const [callCreateTasksMutation] = useCreateTasksMutation();
    const { pushToast } = useToast();
    const history = useHistory();

    const callCreateMissingTasks = useCallback((): Promise<void> => {
        const variables = getCreateMissingTasksInput(
            accountKey!,
            eligibleTaskTypes,
            tasks,
            taskStatusMap,
            worker,
            bgcPackage,
            state,
            country
        );

        if (variables.tasks.length === 0) {
            return Promise.resolve();
        }

        return callCreateTasksMutation({ variables, refetchQueries: ['GetTasks'] })
            .then(({ data }) => {
                const result = data?.createTasks?.result;
                const errors = data?.createTasks?.errors;
                if (errors && errors.length > 0) {
                    if (errors.some((message) => !message.includes('Worker can have only one'))) {
                        pushToast(
                            'There was an error while attempting to create your compliance tasks, but the data may have saved. Refresh the page and try again.',
                            { status: 'warning' }
                        );
                    }
                } else if (result) {
                    if (history.location.pathname.startsWith('/start-onboarding')) {
                        // replace window location instead of router history to force a refresh of page and data
                        // which i think is ok since this will only happen once per user, and a new page load after creation is not uncommon
                        window.location.replace('/');
                    }
                } else {
                    reportErrorToThirdPartyTools(
                        new Error(
                            `Could not retrieve result from create tasks mutation given input: ${JSON.stringify(
                                variables
                            )}`
                        ),
                        scopeKey,
                        scopeValue
                    );
                    pushToast(
                        'There was an error while attempting to create your compliance tasks, but the data may have saved. Refresh the page and try again.',
                        { status: 'warning' }
                    );
                }
            })
            .catch((apolloError) => {
                reportErrorToThirdPartyTools(apolloError, scopeKey, scopeValue);
                pushToast(
                    'There was an error while attempting to create your compliance tasks, please try again.',
                    { status: 'critical' }
                );
            });
    }, [tasks, eligibleTaskTypes]);
    return [callCreateMissingTasks];
};

export default useCreateMissingTasks;
