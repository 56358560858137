import * as FormikFields from 'client/components/FormikFields';
import FormRow from 'client/components/FormRow';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Col } from '@indeed/itmp-components';
import { UsSsnAppliedFor, UsSsnAppliedForProps } from './UsSsnAppliedFor/UsSsnAppliedFor';
import { UsSsnParagraph } from './UsSsnParagraph/UsSsnParagraph';
import { getStatusIncludesDuplicatedSsn } from './UsSsnDuplicatedAlert/UsSsnDuplicatedAlert.utils';

const SsnLegalText = (): JSX.Element => {
    return (
        <>
            <UsSsnParagraph>
                I authorize Indeed Flex to collect and process my Social Security Number (SSN) for
                the following purposes: <br />
                Payroll / Benefits
            </UsSsnParagraph>
            <UsSsnParagraph sx={{ marginBlockEnd: 0 }}>
                I understand that my SSN will only be used for the above purposes and that I may
                revoke my consent at any time by contacting Indeed Flex via Support.
            </UsSsnParagraph>
        </>
    );
};

export interface UsSsnFieldsProps extends UsSsnAppliedForProps {
    hideLegalText?: boolean;
    withConfirmationField?: boolean;
}

const fieldLabel = 'U.S. Social Security Number';
const fieldName = 'ssn';

export const UsSsnFields = ({
    formik,
    ssnFieldProps,
    ssnAppliedForFieldProps,
    hideLegalText,
    children,
    withConfirmationField,
}: PropsWithChildren<UsSsnFieldsProps>): JSX.Element => {
    const [showAppliedFor, setShowAppliedFor] = useState(true);
    const ssnAppliedForFieldName = ssnAppliedForFieldProps?.name || 'ssnAppliedFor';

    const ssnFieldName = ssnFieldProps?.name || fieldName;
    const hasDuplicateError = getStatusIncludesDuplicatedSsn(formik.status, ssnFieldName);

    useEffect(() => {
        // This will disable the submit button until a field is touched
        if (hasDuplicateError) {
            formik.setFieldError(ssnFieldName, '');
        }
    }, [hasDuplicateError]);

    const ssnMeta = formik.getFieldMeta(ssnFieldName);
    const ssnValid = ssnMeta.touched && !ssnMeta.error;

    useEffect(() => {
        // Hide "Applied for" if ssn valid or if duplicated error
        setShowAppliedFor(!hasDuplicateError && !ssnValid);
    }, [ssnValid, setShowAppliedFor, hasDuplicateError]);

    const ssnAppliedFor = !!formik.values[ssnAppliedForFieldName]?.includes('true');

    const commonFieldProps = {
        removeCharPattern: /[^0-9]/g,
        showRequiredIndicator: true,
        ...ssnFieldProps,
        inputProps: {
            autoComplete: 'off',
            required: !ssnAppliedFor,
            className: 'dd-privacy-hidden',
            disabled: ssnAppliedFor,
            placeholder: ssnAppliedFor ? 'N/A' : '',
            ...ssnFieldProps?.inputProps,
        },
    };

    const showConfirmationField = withConfirmationField && !ssnAppliedFor;

    return (
        <>
            <FormRow>
                <Col>
                    <FormikFields.TextField
                        label={fieldLabel}
                        name={fieldName}
                        aria-valuemax={9}
                        showInitialValue
                        {...commonFieldProps}
                    />
                </Col>
            </FormRow>
            {showConfirmationField ? (
                <FormRow>
                    <Col>
                        <FormikFields.TextField
                            label={`Confirm ${fieldLabel}`}
                            name="ssnConfirmation"
                            aria-valuemax={9}
                            {...commonFieldProps}
                        />
                    </Col>
                </FormRow>
            ) : null}
            <FormRow>
                <Col>
                    {showAppliedFor && (
                        <UsSsnAppliedFor
                            formik={formik}
                            ssnAppliedForFieldProps={ssnAppliedForFieldProps}
                            ssnFieldProps={ssnFieldProps}
                        >
                            {children}
                        </UsSsnAppliedFor>
                    )}
                    {!hideLegalText && <SsnLegalText />}
                </Col>
            </FormRow>
        </>
    );
};
