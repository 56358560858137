import * as FormikFields from 'client/components/FormikFields';
import AddressField from 'client/components/AddressField';
import EditIcon from '@indeed/ifl-icons/Edit';
import FormRow from 'client/components/FormRow';
import React from 'react';
import { Box, Button, Label } from '@indeed/ifl-components';
import { Col } from '@indeed/itmp-components';
import { FormikProps } from 'formik';
import { ProfileFormValues } from 'client/pages/profile/components/Form/formConstants';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';
import { formatUkPostcode } from 'client/utils/form';
import { getUsaStateOptions } from 'client/utils/options';

export interface AddressFieldsProps {
    formik: FormikProps<ProfileFormValues>;
    country: SupportedCountryCode;
    onEditManuallyClick?: (flag: boolean) => void;
    onAddressSuggestionSelected?: (address: string) => void;
}

const AddressFields = ({
    formik,
    country,
    onEditManuallyClick,
    onAddressSuggestionSelected,
}: AddressFieldsProps): JSX.Element => {
    const usaStateOptions = getUsaStateOptions(false);
    const { values, setFieldValue, validateForm } = formik;
    const isEditingManually = values.manualAddressEdit;
    return (
        <Box>
            <Label
                size="md"
                htmlFor="address1"
                aria-label="Current address"
                showRequiredIndicator
                sx={{ marginBlockEnd: 2 }}
            >
                Current address
            </Label>
            <Box>
                {country === SupportedCountryCode.US ? (
                    <>
                        <FormRow>
                            <Col>
                                <AddressField
                                    formik={formik}
                                    name="address1"
                                    label="Address (Street Number and Name)"
                                    country={country}
                                    onAddressSuggestionSelected={onAddressSuggestionSelected}
                                />
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col>
                                <FormikFields.TextField
                                    inputProps={{
                                        autoComplete: 'off',
                                        className: 'dd-privacy-hidden',
                                    }}
                                    label="Apt. Number"
                                    name="address2"
                                />
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col>
                                <FormikFields.TextField
                                    inputProps={{
                                        autoComplete: 'off',
                                        required: true,
                                        className: 'dd-privacy-hidden',
                                    }}
                                    label="City or Town"
                                    name="city"
                                />
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col>
                                <FormikFields.SelectField
                                    selectProps={{ required: true, className: 'dd-privacy-hidden' }}
                                    options={usaStateOptions}
                                    label="State"
                                    name="state"
                                />
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col>
                                <FormikFields.TextField
                                    inputProps={{
                                        autoComplete: 'off',
                                        required: true,
                                        className: 'dd-privacy-hidden',
                                        maxLength: formik.values.state === 'CAN' ? 6 : 5,
                                    }}
                                    name="postalCode"
                                    label="ZIP Code"
                                />
                            </Col>
                        </FormRow>
                    </>
                ) : (
                    <>
                        <FormRow>
                            <Col>
                                {isEditingManually ? (
                                    <FormikFields.TextField
                                        inputProps={{
                                            autoComplete: 'off',
                                            className: 'dd-privacy-hidden',
                                            required: true,
                                        }}
                                        label="Address first line"
                                        name="address1"
                                    />
                                ) : (
                                    <AddressField
                                        country={country}
                                        formik={formik}
                                        name="address1"
                                        label="Address first line"
                                        helperText="Start entering your address to get suggestions and select it from the dropdown"
                                        onAddressSuggestionSelected={onAddressSuggestionSelected}
                                    />
                                )}
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col>
                                <FormikFields.TextField
                                    inputProps={{
                                        autoComplete: 'off',
                                        className: 'dd-privacy-hidden',
                                    }}
                                    label="Address second line (optional)"
                                    name="address2"
                                />
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col>
                                <FormikFields.TextField
                                    inputProps={{
                                        autoComplete: 'off',
                                        disabled: !isEditingManually,
                                        required: isEditingManually,
                                        className: 'dd-privacy-hidden',
                                        sx: {
                                            opacity: isEditingManually ? 1 : 0.5,
                                        },
                                    }}
                                    label="City or Town"
                                    helperText={
                                        !isEditingManually
                                            ? 'This field will be auto-populated when an address is selected from the suggestion dropdown'
                                            : ''
                                    }
                                    name="city"
                                />
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col>
                                <FormikFields.TextField
                                    inputProps={{
                                        autoComplete: 'off',
                                        className: 'dd-privacy-hidden',
                                        minLength: 5,
                                        disabled: !isEditingManually,
                                        required: isEditingManually,
                                        maxLength: 8,
                                        onChange: (event) => {
                                            if (country === SupportedCountryCode.GB) {
                                                event.target.value = formatUkPostcode(
                                                    event.target.value,
                                                    isEditingManually
                                                );
                                            }
                                            formik.handleChange(event);
                                        },
                                        sx: {
                                            opacity: isEditingManually ? 1 : 0.5,
                                        },
                                    }}
                                    name="postalCode"
                                    label="Postcode"
                                    helperText={
                                        !isEditingManually
                                            ? 'Postcode will be auto-populated when an address is selected from the suggestion dropdown'
                                            : ''
                                    }
                                />
                            </Col>
                        </FormRow>
                        <FormRow>
                            <Col>
                                <Button
                                    type="button"
                                    variant="tertiary"
                                    iconBefore={!isEditingManually ? <EditIcon /> : null}
                                    onClick={async () => {
                                        await setFieldValue(
                                            'manualAddressEdit',
                                            !isEditingManually
                                        );
                                        await validateForm();
                                        onEditManuallyClick?.(isEditingManually);
                                    }}
                                >
                                    {isEditingManually
                                        ? 'Use address suggestions'
                                        : 'Edit address manually'}
                                </Button>
                            </Col>
                        </FormRow>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default AddressFields;
