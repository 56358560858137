import React from 'react';
import { DatadogAction } from 'client/utils/logging';
import { Error1 } from '@indeed/ipl-illustrations-spot';
import {
    GraphicContentSection,
    TextContentSection,
} from 'client/pages/i9/WorkBrightI9/ContentSection';
import { LogRenderDatadogAction } from 'client/components/LogRenderDatadogAction';

const WorkBrightI9ErrorPage = (): JSX.Element => {
    return (
        <LogRenderDatadogAction action={DatadogAction.i9ErroredRendered}>
            <GraphicContentSection>
                <Error1 />
            </GraphicContentSection>
            <TextContentSection
                heading="Sorry, we are experiencing some technical difficulties."
                text="Please try again later."
                omitSummaryHeading
            />
        </LogRenderDatadogAction>
    );
};
export default WorkBrightI9ErrorPage;
