import ComplianceContainer, {
    ComplianceContainerProps,
} from 'client/components/ComplianceContainer';
import Page from 'client/components/Page';
import React, { useEffect } from 'react';
import { Box, Spinner } from '@indeed/ifl-components';
import { DatadogAction } from 'client/utils/logging';
import { datadogRum } from '@datadog/browser-rum';

export interface LoadingProps extends ComplianceContainerProps {
    text?: string;
    valign?: 'top' | 'middle' | 'bottom';
    ddActionMillis?: number;
}
/* eslint-disable @typescript-eslint/no-use-before-define */
export const LoadingPage = ({
    text = 'Loading...',
    valign = 'middle',
    ddActionMillis = 15000,
    ...props
}: LoadingProps): JSX.Element => (
    <Page title={text} showHeader={false} showFooter={false}>
        <Loading text={text} valign={valign} ddActionMillis={ddActionMillis} {...props} />
    </Page>
);
/* eslint-enable @typescript-eslint/no-use-before-define */

const Loading = ({
    text = 'Loading...',
    valign = 'top',
    ddActionMillis = 30000,
    ...props
}: LoadingProps): JSX.Element => {
    const { sx, ...rest } = props;
    const middle = valign === 'middle';
    const bottom = valign === 'bottom';

    // Send action to datadog if this component is visible for more than ddActionMillis milliseconds.
    // We will use this action to alert if loading screen is stuck for users.
    useEffect(() => {
        const timer = setTimeout(() => {
            datadogRum.addAction(DatadogAction.loadingStuck);
        }, ddActionMillis);
        return () => clearTimeout(timer);
    }, []);

    return (
        <ComplianceContainer
            aria-busy="true"
            aria-live="polite"
            aria-label={text ? text : 'Loading...'}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: middle ? 'center' : bottom ? 'flex-end' : 'flex-start',
                height: middle ? '100%' : 'auto',
                ...sx,
            }}
            {...rest}
        >
            <Box sx={{ textAlign: 'center' }}>
                <Spinner color="dark" title="Loading" />
                {text && <Box sx={{ m: '.5rem 0' }}>{text}</Box>}
            </Box>
        </ComplianceContainer>
    );
};

export default Loading;
