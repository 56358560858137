import { FormAlertError } from 'client/components/FormAlertError/FormAlertError';

import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { getStatusIncludesDuplicatedSsn } from './UsSsnDuplicatedAlert.utils';

export interface UsSsnDuplicatedAlertProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>;
    ssnFieldName?: string;
}

export const UsSsnDuplicatedAlert = ({
    formik,
    ssnFieldName = 'ssn',
}: UsSsnDuplicatedAlertProps): JSX.Element | null => {
    const [showAlert, setShowAlert] = useState(false);

    const hasError = getStatusIncludesDuplicatedSsn(formik.status, ssnFieldName);

    useEffect(() => {
        setShowAlert(hasError);
    }, [hasError]);

    if (!showAlert) {
        return null;
    }

    return (
        <FormAlertError title="Account already exists">
            An account with these details already exists, and we are unable to create another
            account. Please sign in to your existing account.
        </FormAlertError>
    );
};
