import Confirm from 'client/pages/emergencycontact/components/Confirm';
import Form from 'client/pages/emergencycontact/components/Form';
import React from 'react';
import StatusTag from 'client/components/StatusTag';
import { A11yPageTitle } from 'client/components/Page';
import { ApolloError } from '@apollo/client';
import { Box, Heading, ToastStatus } from '@indeed/ifl-components';
import { EmergencyContactTaskTrackingEvents } from 'client/pages/emergencycontact/EmergencyContact.tracking';
import {
    EmergencyFormValues,
    getDefaultValues,
    getEmergencyContactFormValidationSchema,
    toCompleteEmergencyContactInput,
} from 'client/pages/emergencycontact/components/Form/formConstants';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { getFormikErrors } from 'client/utils/graphql';
import { indexLocation } from 'client/utils/redirect';
import { redirect } from 'client/utils/auth';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useCompleteEmergencyContactMutation } from 'client/hooks/graphql';
import { useLocaleContext } from 'client/contexts/LocaleContext';
import { useToast } from 'client/contexts/ToastContext';
import { useWorker } from 'client/contexts/WorkerContext';

type ProfileFormRouterProps = {
    task: ComplianceTask;
};

const FormRouter = ({ task }: ProfileFormRouterProps): JSX.Element => {
    const { pushToast } = useToast();
    const { url, path } = useRouteMatch();
    const history = useHistory();
    const { worker } = useWorker();
    const { country } = useLocaleContext();
    const [callCompleteEmergencyContactMutation] = useCompleteEmergencyContactMutation();

    const reportError = (error: Error, message: string, status: ToastStatus): void => {
        reportErrorToThirdPartyTools(error, 'emergencyContactPage', 'onFormSubmit');
        pushToast(message, { status });
    };

    const onSubmit = async (
        values: EmergencyFormValues,
        formikHelpers: FormikHelpers<EmergencyFormValues>
    ): Promise<void> => {
        datadogRum.addAction(EmergencyContactTaskTrackingEvents.confirmBtnClicked);
        const input = toCompleteEmergencyContactInput(task.slug, values);
        try {
            const { data } = await callCompleteEmergencyContactMutation({
                variables: { input },
                refetchQueries: ['GetTasks'],
            });
            const result = data?.completeEmergencyContact?.result;
            if (result) {
                if (task.redirectUrl) {
                    redirect(task.redirectUrl);
                }
                history.push(indexLocation);
            } else {
                reportError(
                    new Error(
                        `Could not retrieve result from complete emergency contact mutation given input: ${JSON.stringify(
                            input
                        )}`
                    ),
                    'There was an error while retrieving the response from submission, but the data may have saved. Refresh the page and try again.',
                    'warning'
                );
            }
        } catch (err) {
            const apolloError = err as ApolloError;
            const formikErrors = getFormikErrors<EmergencyFormValues>(apolloError);
            if (formikErrors) {
                formikHelpers.setErrors(formikErrors);
                history.push({ pathname: url, search: window.location.search });
            } else {
                reportError(
                    apolloError,
                    'There was an error while attempting to submit the emergency contact, please try again.',
                    'critical'
                );
            }
        }
    };

    return (
        <Formik
            initialValues={getDefaultValues(country, task)}
            validationSchema={getEmergencyContactFormValidationSchema(worker!)}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <FormikForm>
                    <Route path={path} exact>
                        <Box
                            sx={{
                                maxWidth: ['auto', '24rem'],
                                mx: [0, 'auto'],
                            }}
                        >
                            <A11yPageTitle title="Emergency contact form" />
                            <Heading level={4}>Emergency contact</Heading>
                            <StatusTag task={task} />
                            <Box sx={{ mb: '1rem' }}>
                                This information is required for legal reasons:
                            </Box>
                            <Form formik={formik} baseUrl={url} />
                        </Box>
                    </Route>
                    <Route path={`${path}/confirm`}>
                        <A11yPageTitle title="Emergency contact confirm" />
                        <Heading level={4}>Confirm your information</Heading>
                        <Box sx={{ mb: '1rem' }}>Please review before submitting.</Box>
                        <Confirm
                            values={formik.values}
                            dirty={formik.dirty}
                            baseUrl={url}
                            isSubmitting={formik.isSubmitting}
                        />
                    </Route>
                </FormikForm>
            )}
        </Formik>
    );
};

export default FormRouter;
