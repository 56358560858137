import { DocumentTrackingEventType } from 'client/utils/datadog';

export const BackgroundCheckTrackingEvents: Record<
    DocumentTrackingEventType | 'lastSubmitBtnClicked',
    string
> = {
    signCheckboxClicked: 'bgc_task.sign_checkbox.clicked',
    submitBtnClicked: 'bgc_task.submit_btn.clicked',
    backBtnClicked: 'bgc_task.back_btn.clicked',
    downloadDocumentClicked: 'bgc_task.download_document_btn.clicked',
    completeTrainingClicked: 'bgc_task.complete_training_btn.clicked',
    lastSubmitBtnClicked: 'bgc_task.last_submit_btn.clicked',
} as const;
