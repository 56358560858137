import BackButton from 'client/components/BackButton';
import ComplianceContainer from 'client/components/ComplianceContainer';
import Page from 'client/components/Page';
import PageContent from 'client/pages/profile/components/PageContent';
import React from 'react';
import { LoadingPage } from 'client/components/Loading';
import { ProfileTaskTrackingEvents } from './Profile.tracking';
import { Redirect, useParams } from 'react-router-dom';
import { indexLocation } from 'client/utils/redirect';
import { isProfileTask } from 'client/utils/task';
import { useTasks } from 'client/contexts/TasksContext';

type SlugParam = {
    slug?: string;
};

const ProfilePage = (): JSX.Element => {
    const { slug } = useParams<SlugParam>();

    const { completed, loading, tasks } = useTasks();

    if (!completed || loading) {
        return <LoadingPage />;
    }

    const task = tasks.find((t) => t.slug === slug);
    if (!isProfileTask(task)) {
        return <Redirect to={indexLocation} />;
    }

    return (
        <Page title="Profile">
            <ComplianceContainer>
                <BackButton trackingEvent={ProfileTaskTrackingEvents.backBtnClicked} />
                <PageContent task={task!} />
            </ComplianceContainer>
        </Page>
    );
};

export default ProfilePage;
