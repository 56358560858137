export const ProfileTaskTrackingEvents = {
    acceptBtnClicked: 'profile_task.accept_btn.clicked',
    submitBtnClicked: 'profile_task.submit_btn.clicked',
    backBtnClicked: 'profile_task.back_btn.clicked',
    addressSuggestionClicked: 'profile_task.address_suggestion.clicked',
    manualAddressEditClicked: 'profile_task.manual_address_edit.clicked',
    useAddressSuggestionsClicked: 'profile_task.use_address_suggestions.clicked',
    useGoogleFormattedAddressClicked: 'profile_task.use_google_formatted_address.clicked',
    keepOriginalAddressClicked: 'profile_task.keep_original_address.clicked',
} as const;
