import ComplianceContainer from 'client/components/ComplianceContainer';
import GlobalFooter from '@indeed/frontend-components-react-transpiled/dist/cjs/GlobalFooter';
import React from 'react';

const Footer = (): JSX.Element => {
    return (
        /* eslint-disable @typescript-eslint/naming-convention */
        <ComplianceContainer sx={{ '& .icl-GlobalFooter-nav': { justifyContent: 'center' } }}>
            <GlobalFooter
                isNav={false}
                footerLinks={[
                    {
                        description: 'About',
                        href: 'https://www.indeed.com/about',
                        title: 'About',
                    },
                    {
                        description: 'Do not sell my info',
                        href: 'https://www.indeed.com/legal/ccpa-dns',
                        title: 'Do not sell my info',
                    },
                    {
                        description: 'Accessibility at Indeed',
                        href: 'https://www.indeed.com/accessibility',
                        title: 'Accessibility at Indeed',
                    },
                    {
                        description: 'Terms and conditions',
                        href: 'https://www.indeed.com/legal',
                        title: 'Terms and conditions',
                    },
                ]}
                impressumData={{
                    copyright: '',
                    legalDescription: `©${new Date().getFullYear()} Indeed - Cookies, privacy, and terms`,
                    legalHref: 'https://www.indeed.com/legal',
                    legalTitle: 'Cookies, privacy, and terms for this website',
                }}
            />
        </ComplianceContainer>
    );
};

export default Footer;
