import ComplianceContainer from 'client/components/ComplianceContainer';
import Image from 'client/components/Image';
import Page from 'client/components/Page';
import React, { useState } from 'react';
import UnknownUserPage from 'client/components/UnknownUserPage';
import useCreateMissingTasks from 'client/hooks/useCreateMissingTasks';
import useCreateWorker from 'client/hooks/useCreateWorker';
import { ArrowRight } from '@indeed/ifl-icons';
import { Box, Button, Flex, Text } from '@indeed/ifl-components';
import { DatadogAction } from 'client/utils/logging';
import { GraphQLError } from 'graphql';
import { datadogRum } from '@datadog/browser-rum';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useAuthentication } from 'client/contexts/AuthenticationContext';
import { useTaskEligibility } from 'client/contexts/TaskEligibilityContext';
import { useTasks } from 'client/contexts/TasksContext';
import { useToast } from 'client/contexts/ToastContext';
import { useWorker } from 'client/contexts/WorkerContext';

const StartOnboardingPage = (): JSX.Element => {
    const [disableButton, setDisableButton] = useState(false);
    const [callCreateMissingTasks] = useCreateMissingTasks();

    const [callCreateWorker] = useCreateWorker();
    const { tasks, getTasks, error: errorTasks, completed } = useTasks();
    const { eligibleTaskTypes, isVerified } = useTaskEligibility();
    const { worker, getWorker } = useWorker();
    const { user } = useAuthentication();
    const { pushToast } = useToast();

    const createTasks = (): void => {
        // We'll only record sessions for users that click the "start onboarding" button.
        datadogRum.startSessionReplayRecording();
        datadogRum.addAction(DatadogAction.onboardingStartClicked);
        setDisableButton(true);
        if (!worker) {
            callCreateWorker()
                .then(() => {
                    callCreateMissingTasks().finally(() => {
                        setDisableButton(false);
                    });
                })
                .catch((apolloError) => {
                    const workerAlreadyExistsError = apolloError?.graphQLErrors?.some(
                        (error: GraphQLError) =>
                            error?.extensions?.errors?.account_id &&
                            error.extensions.errors.account_id.includes(
                                'worker with this account id already exists.'
                            )
                    );
                    if (workerAlreadyExistsError) {
                        getWorker();
                        getTasks();
                        callCreateMissingTasks().finally(() => {
                            reportErrorToThirdPartyTools(
                                apolloError,
                                'startOnboardingPage',
                                'onNextClick'
                            );
                            window.location.reload();
                        });
                    } else {
                        reportErrorToThirdPartyTools(
                            apolloError,
                            'startOnboardingPage',
                            'onNextClick'
                        );
                        pushToast(
                            'There was an error while attempting to create your compliance tasks, please refresh and try again.',
                            { status: 'critical' }
                        );
                    }
                });
        } else {
            callCreateMissingTasks().finally(() => {
                setDisableButton(false);
            });
        }
    };

    if (errorTasks && !completed) {
        throw new Error(`Error retrieving Tasks: ${errorTasks}`);
    }

    if (!tasks.length && !eligibleTaskTypes.length) {
        return <UnknownUserPage user={user} expectingRoles={['worker']} />;
    }

    const text = isVerified
        ? "We're introducing a new task section. If you have any additional tasks to complete, you can finish them here. All completed tasks will have a green checkmark."
        : 'Congratulations and welcome to Indeed Flex! Complete a few more steps to finish your onboarding. We recommend completing these steps as soon as possible to be fully verified on our platform.';
    return (
        <Page title="Start Onboarding" showHeader={false} showFooter={false}>
            <ComplianceContainer size="sm">
                <Box sx={{ mt: '2rem', mb: '6rem' }}>
                    <Flex sx={{ justifyContent: 'center' }}>
                        <Image path="indeed-flex-logo.svg" alt="Indeed Flex logo" width="177" />
                    </Flex>
                </Box>
                <Box sx={{ mb: '3rem' }}>
                    <Flex sx={{ justifyContent: 'center' }}>
                        <Image path="onboarding.svg" alt="onboarding" width="245" />
                    </Flex>
                </Box>
                <Box sx={{ marginBlockEnd: 4 }}>
                    <Text level={3}>{text}</Text>
                </Box>
                <Button
                    variant="primary"
                    full
                    id="nextButton"
                    disabled={disableButton}
                    loading={disableButton}
                    size="lg"
                    onClick={createTasks}
                    iconAfter={<ArrowRight />}
                >
                    Next
                </Button>
            </ComplianceContainer>
        </Page>
    );
};

export default StartOnboardingPage;
