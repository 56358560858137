import React from 'react';
import StatusTag from 'client/components/StatusTag';
import dayjs from 'dayjs';
import { FlexHelpEmail } from 'client/components/Emails';
import { Heading } from '@indeed/ifl-components';
import { formatDateWithDayOfWeek } from 'client/utils/dayjs';

type LegacyI9PageContentProps = {
    task: ComplianceTask;
};

const FormI9Processed = ({ task }: LegacyI9PageContentProps): JSX.Element => {
    return (
        <>
            <Heading level={4}>Form I-9</Heading>
            <StatusTag task={task} />
            {task.status === 'APPROVED' && (
                <p>
                    Your Form I-9 was successfully processed and verified on{' '}
                    {formatDateWithDayOfWeek(dayjs(task.processedDate))}.
                </p>
            )}
            {task.status === 'REJECTED' && (
                <>
                    <p>
                        There were some issues with your Form I-9. Please look for an email in order
                        to make corrections.
                    </p>
                    <p>Once your Form I-9 Section 1 passes, return here to continue onboarding.</p>
                </>
            )}
        </>
    );
};

export const LegacyI9PageContent = ({ task }: LegacyI9PageContentProps): JSX.Element => {
    if (['REJECTED', 'APPROVED'].includes(task.status)) {
        return <FormI9Processed task={task} />;
    }

    return (
        <>
            <Heading level={4}>Form I-9</Heading>
            <p>
                A new Form I-9 needs to be issued. Please contact support at <FlexHelpEmail />.
            </p>
        </>
    );
};
