import React, { PropsWithChildren, createContext, useContext, useEffect, useReducer } from 'react';
import { BaseQueryResult } from 'client/utils/graphql';
import { LoadingPage } from 'client/components/Loading';
import { QueryLazyOptions } from '@apollo/client';
import { useGetUserEligibilityLazyQuery } from 'client/hooks/graphql';
import { useLocaleContext } from 'client/contexts/LocaleContext';

/*
 * ACTIONS AND REDUCERS
 */

export type State = BaseQueryResult<GetUserEligibilityQuery, GetUserEligibilityQueryVariables> & {
    eligibleTaskTypes: TaskType[];
    isVerified: boolean;
    completed: boolean;
};

export type Action = {
    type: 'SET_TASK_ELIGIBILITY';
    payload: { eligibleTaskTypes: TaskType[]; isVerified: boolean };
};

export type Dispatches = {
    getTaskEligibility: (options?: QueryLazyOptions<GetUserEligibilityQueryVariables>) => void;
    setTaskEligibility: (eligibleTaskTypes: TaskType[], isVerified: boolean) => void;
};

export const reducer = (state: State, action: Action): State => {
    let eligibleTaskTypes: TaskType[] = [];
    let isVerified: boolean = false;
    switch (action.type) {
        case 'SET_TASK_ELIGIBILITY':
            eligibleTaskTypes = action.payload.eligibleTaskTypes;
            isVerified = action.payload.isVerified;
            const completed = true;
            return { ...state, eligibleTaskTypes, isVerified, completed };
    }
    return { ...state, eligibleTaskTypes };
};

/*
 * CONTEXT
 */

export type TaskEligibilityContextType = State & Dispatches;

const initialState: State = {
    eligibleTaskTypes: [],
    isVerified: false,
    called: false,
    loading: false,
    completed: false,
};

export const TaskEligibilityContext = createContext<TaskEligibilityContextType | null>(null);

export const useTaskEligibility = (): TaskEligibilityContextType => {
    const context = useContext(TaskEligibilityContext);
    if (!context) {
        throw new Error('Task eligibility context must be defined before being used');
    }

    return context;
};

type TaskEligibilityProviderProps = LazyProviderProps;

export const TaskEligibilityProvider = ({
    lazy = false,
    children,
}: PropsWithChildren<TaskEligibilityProviderProps>): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { country } = useLocaleContext();
    const [getTaskEligibility, { data, loading, called, error }] = useGetUserEligibilityLazyQuery({
        variables: { countryCode: country as string },
    });

    const setTaskEligibility = (eligibleTaskTypes: TaskType[], isVerified: boolean): void => {
        dispatch({ type: 'SET_TASK_ELIGIBILITY', payload: { eligibleTaskTypes, isVerified } });
    };

    useEffect(() => {
        if (!lazy && country) {
            // run query on first render
            getTaskEligibility().catch(() => {});
        }
    }, [country]);

    useEffect(() => {
        const eligibleTaskTypes = data?.getUserEligibility?.eligibleTasks;
        const isVerified = data?.getUserEligibility?.isVerified ?? false;
        if (eligibleTaskTypes && called) {
            setTaskEligibility(eligibleTaskTypes, isVerified);
        }
    }, [data, called]);

    if (!called || loading) {
        return <LoadingPage />;
    }

    return (
        <TaskEligibilityContext.Provider
            value={{
                eligibleTaskTypes: state.eligibleTaskTypes,
                isVerified: state.isVerified,
                called,
                loading,
                error,
                getTaskEligibility,
                setTaskEligibility,
                completed: state.completed,
            }}
        >
            <>{children}</>
        </TaskEligibilityContext.Provider>
    );
};
