import React, { useState } from 'react';
import { Box, RadioFormField } from '@indeed/ifl-components';
import { ButtonVariant } from '@indeed/ifl-components/src/components/Button/Button';
import { FormikProps } from 'formik';
import { Modal, ModalBody } from '@indeed/ipl-modal';
import { SignAndAcknowledgeValues } from 'client/components/SignAndAcknowledgeBlock/SignAndAcknowledgeBlock';
import { getFullName, getWorkerFullName } from 'client/utils/worker';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useToast } from 'client/contexts/ToastContext';
import { useUpdateWorkerMutation } from 'client/hooks/graphql';
import { useWorker } from 'client/contexts/WorkerContext';

enum NameSource {
    WORKER = 'WORKER',
    FORM = 'FORM',
}

interface ConfirmLegalNameModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    onConfirmLegalName: () => void;
    formik: FormikProps<SignAndAcknowledgeValues>;
}

const ConfirmLegalNameModal = ({
    show,
    setShow,
    onConfirmLegalName,
    formik,
}: ConfirmLegalNameModalProps): JSX.Element => {
    const { pushToast } = useToast();
    const workerType = useWorker();
    const worker = workerType.worker!;
    const [source, setSource] = useState<string>('');
    const [callUpdateWorkerMutation] = useUpdateWorkerMutation();

    const workerLegalName = getWorkerFullName(worker);
    const formLegalName = getFullName(formik.values.firstName, 'N/A', formik.values.lastName);
    const items = [
        {
            label: workerLegalName,
            value: NameSource.WORKER,
        },
        {
            label: formLegalName,
            value: NameSource.FORM,
        },
    ];

    const onLegalNameCheckModalClose = (): void => {
        setShow(false);
        setSource('');
    };

    const onConfirm = (): void => {
        if (source === NameSource.WORKER) {
            /* eslint-disable @typescript-eslint/no-floating-promises */
            formik.setFieldValue('firstName', worker.firstName);
            formik.setFieldValue('lastName', worker.lastName);
            /* eslint-enable @typescript-eslint/no-floating-promises */
            onConfirmLegalName();
        } else {
            const input: UpdateWorkerInput = {
                accountKey: worker!.accountKey!,
                firstName: formik.values.firstName,
                lastName: formik.values.lastName,
            };
            callUpdateWorkerMutation({
                variables: { updateWorkerInput: input },
                refetchQueries: ['GetWorker'],
            })
                .then(({ data }) => {
                    const result = data?.updateWorker?.result;
                    if (result) {
                        onConfirmLegalName();
                    } else {
                        reportErrorToThirdPartyTools(
                            new Error(
                                `Could not retrieve result from update worker mutation given input ${JSON.stringify(
                                    input
                                )}`
                            ),
                            'confirmLegalNameModal',
                            'onConfirm'
                        );
                        pushToast(
                            'There was an error while attempting to submit the information, please try again.',
                            { status: 'critical' }
                        );
                    }
                })
                .catch((apolloError) => {
                    reportErrorToThirdPartyTools(apolloError, 'confirmLegalNameModal', 'onConfirm');
                    pushToast(
                        'There was an error while attempting to submit the information, please try again.',
                        { status: 'critical' }
                    );
                });
        }
    };

    const onReenter = (): void => {
        onLegalNameCheckModalClose();
    };

    const onExit = (): void => {
        onLegalNameCheckModalClose();
    };

    const footerButtons = [
        {
            label: 'Confirm',
            props: {
                onClick: onConfirm,
                disabled: !source,
            },
        },
        {
            label: 'Re-enter name',
            props: {
                onClick: onReenter,
                variant: 'secondary' as ButtonVariant,
            },
        },
    ];

    return (
        <Modal
            title="Confirm your legal name"
            isOpen={show}
            onExit={onExit}
            footerButtons={footerButtons}
            mobileFooter
        >
            <ModalBody>
                <Box sx={{ mb: '1rem' }}>
                    The information you entered does not match what we have on record. Please select
                    your correct <strong>full legal name</strong>.
                </Box>
                <div>
                    <RadioFormField
                        id="legalName"
                        name="legalName"
                        className="dd-privacy-hidden"
                        items={items.map((item) => ({
                            id: `${item.label}-field`,
                            onChange: (event: React.ChangeEvent) => {
                                setSource((event.target as HTMLInputElement).value);
                            },
                            ...item,
                        }))}
                        sx={{
                            /* eslint-disable-next-line @typescript-eslint/naming-convention */
                            '&': {
                                label: {
                                    border: ({ colors }) => `1px solid ${colors.neutral[400]}`,
                                    borderRadius: '.25rem',
                                    p: '1rem',
                                },
                                /* eslint-disable-next-line @typescript-eslint/naming-convention */
                                'label:not(:last-of-type)': { mb: '.5rem' },
                            },
                        }}
                    />
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ConfirmLegalNameModal;
