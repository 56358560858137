import React from 'react';
import { Box, Button } from '@indeed/ifl-components';
import { useHistory } from 'react-router-dom';

import ConfirmEntry from 'client/components/ConfirmEntry';
import { EmergencyFormValues } from 'client/pages/emergencycontact/components/Form/formConstants';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';
import { parsePhoneNumber } from 'libphonenumber-js';

interface ConfirmEntriesProps {
    values: EmergencyFormValues;
}

const ConfirmEntries = ({ values }: ConfirmEntriesProps): JSX.Element => {
    const phoneNumber = parsePhoneNumber(values.phone, values.phoneCountry);
    return (
        <Box>
            <ConfirmEntry title="Full name">{values.name}</ConfirmEntry>
            <ConfirmEntry title="Phone number">
                {values.phoneCountry === SupportedCountryCode.GB
                    ? phoneNumber.formatNational()
                    : phoneNumber.formatInternational()}
            </ConfirmEntry>
            <ConfirmEntry title="Relation to you">{values.relationship}</ConfirmEntry>
        </Box>
    );
};

export interface ConfirmProps {
    values: EmergencyFormValues;
    dirty: boolean;
    baseUrl: string;
    isSubmitting: boolean;
}

const Confirm = ({ values, dirty, baseUrl, isSubmitting }: ConfirmProps): JSX.Element => {
    const history = useHistory();

    if (!dirty) {
        // loaded page directly, needs to start from page one
        history.push({ pathname: baseUrl, search: window.location.search });
    }

    return (
        <>
            <ConfirmEntries values={values} />
            <Button type="submit" full loading={isSubmitting} sx={{ marginBlockStart: 4 }}>
                Confirm and submit
            </Button>
        </>
    );
};

export default Confirm;
