export const enum WebActionTypes {
    NAVIGATE_TO_URL = 'NavigateToUrl',
}

export const WEB_ACTION_SOURCE = 'ComplianceApp-Web' as const;

/* eslint-disable @typescript-eslint/naming-convention */
export const NavigateToUrlTargets = {
    EXTERNAL: 'external',
    BROWSER: 'browser',
} as const;

export type NavigateToUrlTargetOptions =
    (typeof NavigateToUrlTargets)[keyof typeof NavigateToUrlTargets];
