import React, { createContext, useContext, useMemo } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { ClientFeatureFlags, FeatureFlags, useClientConfig } from './ClientConfigContext';
import { useFeatureFlagsQuery } from 'client/hooks/graphql';

export const FeatureFlagContext = createContext<{
    loading: boolean;
    featureFlags: FeatureFlags | null;
    reloadFeatureFlags: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FeatureFlagsQuery>>;
} | null>(null);

export const useFeatureFlagContext = (): {
    loading: boolean;
    featureFlags: FeatureFlags | null;
    reloadFeatureFlags: (
        variables?:
            | Partial<
                  Exact<{
                      [key: string]: never;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FeatureFlagsQuery>>;
} => {
    const context = useContext(FeatureFlagContext);
    if (!context) {
        throw new Error('FeatureFlagContext context must be defined before being used');
    }
    return context;
};

export const FeatureFlagProvider = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
    const clientConfig = useClientConfig();
    const clientFeatureFlags: ClientFeatureFlags = clientConfig.featureFlags || {};
    const { data, loading, refetch } = useFeatureFlagsQuery();

    const value = useMemo(() => {
        const featureFlags = {
            ...clientFeatureFlags,
            ...(data?.featureFlags
                ? data.featureFlags.reduce((acc, item) => {
                      if (!item) {
                          return acc;
                      }
                      return {
                          ...acc,
                          [item.name!]: item?.active,
                      };
                  }, {})
                : {}),
        };

        return {
            loading,
            featureFlags: loading ? null : featureFlags,
            reloadFeatureFlags: refetch,
        };
    }, [clientFeatureFlags, data]);

    return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
};
