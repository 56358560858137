/**
 * The module defines types, components, context and utility methods related to
 * accessing the authentication data for use by the client side rendered application
 */

import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';

import UnknownUserPage from 'client/components/UnknownUserPage';
import useAxiosConfig from 'client/hooks/useAxiosConfig';
import useBackendUrl from 'client/hooks/useBackendUrl';
import { LoadingPage } from 'client/components/Loading';
import { expectingRolesMatchesRoles, redirect } from 'client/utils/auth';
import { setDatadogUser } from 'client/utils/datadog';
import { useClientConfig } from 'client/contexts/ClientConfigContext';

export const getVerifyUrl = (loginType: LoginType): string => {
    switch (loginType) {
        case 'PASSPORT':
            return '/api/verify-auth';
        case 'ONE_LOGIN':
            return '/saml/verify-auth';
    }
};

export const useBaseLoginUrl = (loginType: LoginType): string => {
    const clientConfig = useClientConfig();
    switch (loginType) {
        case 'PASSPORT':
            return clientConfig.passportLoginUrl!;
        case 'ONE_LOGIN':
            return clientConfig.oneLoginLoginUrl!;
    }
};

export const useBaseLogoutUrl = (loginType: LoginType): string => {
    const clientConfig = useClientConfig();
    switch (loginType) {
        case 'PASSPORT':
            return clientConfig.passportLogoutUrl!;
        case 'ONE_LOGIN':
            return clientConfig.oneLoginLogoutUrl!;
    }
};

/**
 * Type for the data stored in authentication context. The `user` value in the
 * context can be nullable, and the setter method can set the value to a null or
 * non-null value
 */
export type Authentication = {
    user: ComplianceUser | null;
    loading: boolean;
    called: boolean;
    isAuthenticated: boolean;
    loginUrl: string;
    logoutUrl: string;
};

export const AuthenticationContext = createContext<Authentication | null>(null);

/**
 * Utility hook to provide the authentication context value. If no authentication is
 * available before the hook is dynamically invoked, then an error is raised
 */
export const useAuthentication = (): Authentication => {
    const context = useContext(AuthenticationContext);
    if (!context) {
        throw new Error('Authentication context must be defined before being used');
    }

    return context;
};

/**
 * REST response type for GET /api/verify-auth endpoint
 */
type VerifyAuthResponse = {
    user: ComplianceUser | null;
};

/**
 * Utility hook that retrieves user details. If the retrieval is successful, then
 * the user details are updated in the authentication context. If the retrieval
 * fails, then the user details in the authentication context is cleared and
 * the user is redirected to login.
 */
export const useVerifyAuth = (
    loginType: LoginType,
    loginUrl: string,
    setUser: (newUser: ComplianceUser | null) => void,
    setLoading: (loading: boolean) => void,
    setIsAuthenticated: (isAuthenticated: boolean) => void
): void => {
    const url = getVerifyUrl(loginType);
    const source = axios.CancelToken.source();
    const verifyAuthCallConfig = useAxiosConfig({
        url,
        method: 'GET',
        cancelToken: source.token,
    });

    useEffect(() => {
        setLoading(true);
        axios(verifyAuthCallConfig)
            .then((res: AxiosResponse<VerifyAuthResponse>) => {
                const user = res.data?.user;
                if (!user) {
                    throw Error('User not found.');
                }
                setIsAuthenticated(true);
                setLoading(false);
                setUser(user);
                if (user.accountKey) {
                    setDatadogUser(user);
                }
            })
            .catch((err) => {
                if (!axios.isCancel(err)) {
                    // only set values if not cancelled so we don't attempt to set state on unmounted component
                    setIsAuthenticated(false);
                    setUser(null);
                    redirect(loginUrl);
                }
            });

        return () => {
            source.cancel(`Cancelling ${verifyAuthCallConfig.url} api call on unmount`);
        };
    }, []);
};

type AuthenticationWrapperProps = {
    user: ComplianceUser | null;
    loading: boolean;
    called: boolean;
    isAuthenticated: boolean;
    expectingRoles: Roles[];
};

const AuthenticationWrapper = ({
    user,
    loading,
    called,
    isAuthenticated,
    expectingRoles,
    children,
}: PropsWithChildren<AuthenticationWrapperProps>): JSX.Element => {
    if (!called || loading) {
        return <LoadingPage />;
    }

    if (!isAuthenticated) {
        return <UnknownUserPage user={user} expectingRoles={expectingRoles} />;
    }

    if (
        !user ||
        (!expectingRolesMatchesRoles(expectingRoles, user!.roles) &&
            !expectingRoles.includes('worker'))
    ) {
        return <UnknownUserPage user={user} expectingRoles={expectingRoles} />;
    }

    return <>{children}</>;
};

interface AuthenticationProviderProps {
    expectingRoles: Roles[];
    loginType: LoginType;
    children: (authentication: Authentication) => JSX.Element | null;
}

export const AuthenticationProvider = ({
    expectingRoles,
    loginType,
    children,
}: AuthenticationProviderProps): JSX.Element => {
    const [user, setUser] = useState<ComplianceUser | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [called, setCalled] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (!called && loading) {
            // if we haven't called it yet but it's now loading, set called to true once
            // it never changes after this
            setCalled(true);
        }
    }, [loading]);

    const baseLoginUrl = useBaseLoginUrl(loginType);
    const baseLogoutUrl = useBaseLogoutUrl(loginType);

    const encodedHref = encodeURIComponent(window.location.href);

    const loginUrl =
        loginType === 'PASSPORT'
            ? useBackendUrl(`${baseLoginUrl}?next=${encodedHref}&from=worker-compliance-webapp`)
            : useBackendUrl(`${baseLoginUrl}?next=${encodedHref}`);
    const logoutUrl = useBackendUrl(`${baseLogoutUrl}?next=${encodedHref}`);

    useVerifyAuth(loginType, loginUrl, setUser, setLoading, setIsAuthenticated);

    const authentication = { user, loading, called, isAuthenticated };
    const value = { ...authentication, loginUrl, logoutUrl };

    return (
        <AuthenticationContext.Provider value={value}>
            <AuthenticationWrapper {...authentication} expectingRoles={expectingRoles}>
                {children(value)}
            </AuthenticationWrapper>
        </AuthenticationContext.Provider>
    );
};
