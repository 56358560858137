import React, { PropsWithChildren } from 'react';
import { Box, VisuallyHidden } from '@indeed/ifl-components';

import Footer from 'client/components/Footer';
import Header from 'client/components/Header';
import useTitle from 'client/hooks/useTitle';
import { HeaderProps } from 'client/components/Header/Header';
import { checkHeader } from 'client/utils/common';

interface A11yPageTitleProps {
    title: string;
}

/**
 * Use this if you have a root `<Page>` component but sub-routes within that need title changes as the routes change
 *
 * Screen readers look for changes in the document title and look for <h1> tags to read the page changes.
 * This can occur on route/page changes or in a function page change, e.g. the same url but the whole page is loading.
 */
export const A11yPageTitle = ({
    title,
    children,
}: PropsWithChildren<A11yPageTitleProps>): JSX.Element => {
    useTitle(title);

    return (
        <>
            <VisuallyHidden>
                <h1>{title}</h1>
            </VisuallyHidden>
            {children}
        </>
    );
};

interface PageProps {
    title?: string;
    showHeader?: boolean;
    showFooter?: boolean;
    headerProps?: HeaderProps;
}

/**
 * ALL page components (e.g. IndexPage, BackgroundCheckPage...) should have this component at the root
 *
 * Each page should contain:
 * - header (optional)
 * - content within a `<main>` tag with styles to push the footer to the bottom of the page
 *   - title (either rendered here or by route in page router components
 * - footer (optional)
 */

const Page = ({
    title,
    showHeader = true,
    showFooter = true,
    headerProps = {},
    children,
}: PropsWithChildren<PageProps>): JSX.Element => {
    const noHeader = checkHeader();
    return (
        <>
            {showHeader && !noHeader && <Header {...headerProps} />}
            <Box as="main" sx={{ flex: '1 1 auto', marginBlockStart: noHeader ? 5 : 0 }}>
                {title && <A11yPageTitle title={title} />}
                {children}
            </Box>
            {showFooter && !noHeader && <Footer />}
        </>
    );
};

export default Page;
