import React from 'react';
import { Check, EditSection, Error, Warning } from '@indeed/ifl-icons';

const ItemProgressIcon = ({ status }: { status: TaskStatus }): JSX.Element => {
    switch (status) {
        case 'EXPIRING':
            return (
                <Warning
                    color="foreground.status.warning.weak"
                    variant="stroke"
                    aria-label="Expiring"
                />
            );
        case 'EXPIRED':
            return (
                <Error
                    color="foreground.status.critical.weak"
                    variant="stroke"
                    aria-label="Expired"
                />
            );

        case 'COMPLETED':
            return <Check color="foreground.status.success" aria-label="Completed" />;
        default:
            return <EditSection color="foreground.primary" aria-label="Not completed" />;
    }
};

export default ItemProgressIcon;
