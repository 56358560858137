import React, { PropsWithChildren, ReactNode, createContext, useContext, useState } from 'react';
import StaticToast, { StaticToastProps } from 'client/components/StaticToast';
import { uniqueId } from 'client/utils/common';

export interface ToastContextType {
    // see `ToastProvider` for docs
    pushToastComponent: (toast: JSX.Element) => void;
    pushToast: (content: ReactNode | string, props?: StaticToastProps) => void;
}

export const ToastContext = createContext<ToastContextType | null>(null);

export const useToast = (): ToastContextType => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('Toast context must be defined before being used');
    }

    return context;
};

/**
 * Render children with a top level place to render <Toasts>
 * Provide functions to push Toast components to be rendered
 * Currently does not allow rendering non-dismissable toasts
 */
export const ToastProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
    const [toasts, setToasts] = useState<JSX.Element[]>([]);

    /**
     * Private function called `onDismiss` from the Toast component
     * Uses the `props` `id` field instead of popping off the top to avoid
     * possible race conditions if we have transitions or other ways a user may
     * click to close a toast that is not on the top of the stack
     * @param id - Unique id generated by the `pushToastComponent` function to dismiss here
     */
    const dismissToast = (id: string): boolean => {
        const nextToasts = toasts.filter((toast) => toast.props.id !== id);
        const wasToastRemoved = toasts.length !== nextToasts.length;
        setToasts(nextToasts);
        return wasToastRemoved;
    };

    /**
     * Create a unique id to use as the component's `key` and to dismiss the toast later
     * Clone the component to add `id` and `key` as props, and add the `onDismiss` function to close
     * @param toast - An IFL `<Toast>` component or a component that extends it
     */
    const pushToastComponent = (toast: JSX.Element): void => {
        const id = uniqueId('toast-context__');
        const toastWithDismiss = React.cloneElement(React.Children.only(toast), {
            id,
            key: id,
            onDismiss: () => dismissToast(id),
        });
        setToasts([...toasts, toastWithDismiss]);
    };

    /**
     * Helper method to simply push a default `<Toast>` with content
     * @param content - Content to display in the toast
     * @param props - Any props to override from the default, e.g. { type: 'critical' }
     */
    const pushToast = (content: ReactNode | string, props?: StaticToastProps): void => {
        pushToastComponent(<StaticToast {...props}>{content}</StaticToast>);
    };

    return (
        <ToastContext.Provider value={{ pushToastComponent, pushToast }}>
            <div>{toasts}</div>
            {children}
        </ToastContext.Provider>
    );
};
