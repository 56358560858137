import React, { useState } from 'react';
import { Box, Card, CardSection, Text } from '@indeed/ifl-components';
import { Field, FieldProps } from 'formik';
import { FileUploadWithFormFields } from '@indeed/ipl-file-upload';
import { FileUploadWithFormFieldsProps } from '@indeed/ipl-file-upload/dist/types/FileUploadWithFormFields';
import { StyleProps } from '@indeed/ifl-components/ts/utils/styled';

type FilePreview = {
    previewUrl: string;
    filename: string;
};

type FileManagerProps = {
    currentFiles?: FileList;
    title?: string;
    showFileName?: boolean;
    imageStyles?: StyleProps['sx'];
};

export type FileUploadProps = Partial<FileUploadWithFormFieldsProps> & {
    name: string;
    fileManagerProps?: FileManagerProps;
};

export const FileManager = ({
    currentFiles,
    showFileName = true,
    imageStyles = {
        width: '100%',
        height: 'auto',
    },
}: FileManagerProps): JSX.Element => {
    const [files, setFiles] = useState<FilePreview[]>([]);
    React.useEffect(() => {
        if (!currentFiles) {
            return;
        }
        setFiles(
            Array.from(currentFiles).map((f) => ({
                previewUrl: URL.createObjectURL(f),
                filename: f.name,
            }))
        );
    }, [currentFiles]);

    if (!currentFiles) {
        return <></>;
    }

    return (
        <Box>
            {files.map((f) => {
                return (
                    <Card key={f.filename}>
                        <CardSection
                            sx={{
                                paddingBlockStart: 0,
                                paddingBlockEnd: 0,
                                paddingInlineStart: 0,
                                paddingInlineEnd: 0,
                            }}
                        >
                            <Box as="img" src={f.previewUrl} alt={f.filename} sx={imageStyles} />
                        </CardSection>

                        {showFileName && (
                            <CardSection>
                                <Text>{f.filename}</Text>
                            </CardSection>
                        )}
                    </Card>
                );
            })}
        </Box>
    );
};

export const FileUploadWithCustomFormField = ({
    name,
    label,
    helperText,
    required,
    inputTitleText,
    inputHelperText,
    fileManagerProps = {},
    accept,
    maxLength,
}: FileUploadProps): JSX.Element => {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps<string>) => (
                <FileUploadWithFormFields
                    name={name}
                    onChange={(e) => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        form.setFieldValue(name, e.target.files);
                    }}
                    onDragChange={(e) => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        form.setFieldValue(name, e.dataTransfer?.files);
                    }}
                    required={required}
                    label={label}
                    helperText={helperText}
                    inputTitleText={inputTitleText || 'Select a file'}
                    inputHelperText={inputHelperText || 'Drag and drop here, or'}
                    cards={
                        <FileManager
                            currentFiles={field.value as unknown as FileList}
                            {...fileManagerProps}
                        />
                    }
                    accept={accept}
                    maxLength={maxLength}
                />
            )}
        </Field>
    );
};
