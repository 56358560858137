import BackButton from 'client/components/BackButton';
import DocumentItem from 'client/components/DocumentItem';
import React from 'react';
import StatusTag from 'client/components/StatusTag';
import first from 'lodash/first';
import { Box, Flex, Heading, Text } from '@indeed/ifl-components';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import {
    byTaskLocation,
    getLocationRequirementsMetadata,
    getMatchingWorkerDocument,
    getMediaRequirementsMetadata,
    getTaskDocuments,
} from 'client/utils/task';
import { getFullStateName } from 'client/utils/transforms';
import { indexLocation } from 'client/utils/redirect';
import { logDocumentEvent } from 'client/utils/datadog';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';

type FormItem = {
    completedDate: string;
    formPath: string;
    title: string;
};

type DocumentListPageProps = {
    tasks: ComplianceTask[];
    forms?: FormItem[];
    pageTitle: string;
    showBackButton?: boolean;
};

export type LocationHeader = {
    type: string;
    name: string;
};

export const getLocationTaskAndHeadersList = (tasks: ComplianceTask[]): Object[] => {
    const results: Object[] = [];
    let currentState: string | null = null;
    let currentCity: string | null = null;
    tasks.sort(byTaskLocation).forEach((task) => {
        const metadata =
            task.type === 'LOCATION_REQUIREMENTS'
                ? getLocationRequirementsMetadata(task)
                : getMediaRequirementsMetadata(task);
        const state = getFullStateName(metadata!!.location!!.state as string);
        const city = metadata!!.location!!.city as string;
        if (state !== currentState) {
            currentState = state;
            results.push({ type: 'STATE', name: currentState } as LocationHeader);
        }
        if (city !== currentCity) {
            currentCity = city;
            results.push({ type: 'CITY', name: currentCity } as LocationHeader);
        }
        results.push(task);
    });
    return results;
};

const NoDocumentsDisplay = ({
    showBackButton,
    onGoBack,
}: {
    showBackButton: boolean;
    onGoBack: () => void;
}): JSX.Element => (
    <Box>
        {showBackButton && <BackButton onClick={onGoBack} />}
        <br />
        <Text>No documents to display.</Text>
    </Box>
);

const DocumentListPage = ({
    tasks,
    forms = [],
    pageTitle,
    showBackButton = true,
}: DocumentListPageProps): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    // If no documents have been acknowledged, automatically go to the first
    const locationState = location.state as { skipRedirect?: boolean } | undefined;
    const isLocationRequirements = tasks[0].type === 'LOCATION_REQUIREMENTS';
    const isMediaRequirements = tasks[0].type === 'MEDIA_REQUIREMENTS';
    // Non-Location tasks will only have 1 task, filter out non-location media
    // requirement tasks
    const isNonLocationTask = (task: ComplianceTask): boolean =>
        isMediaRequirements && !getMediaRequirementsMetadata(task)?.location;
    const sortedTasks =
        isLocationRequirements || isMediaRequirements
            ? tasks.filter((task) => !isNonLocationTask(task)).sort(byTaskLocation)
            : tasks;
    // Non-location media requirement tasks
    const nonLocationTasks = tasks.filter((task) => isNonLocationTask(task));
    const firstTask = isMediaRequirements
        ? [...nonLocationTasks, ...sortedTasks][0]
        : sortedTasks[0];
    const taskList =
        isLocationRequirements || isMediaRequirements
            ? [...nonLocationTasks, ...getLocationTaskAndHeadersList(sortedTasks)]
            : tasks;

    const onGoBack = (): void => {
        logDocumentEvent(firstTask.type, 'backBtnClicked');
        history.push(indexLocation);
    };

    if (!firstTask) {
        reportErrorToThirdPartyTools(
            new Error(`Expected there to be tasks`),
            'documentListPage',
            'getTaskDocuments'
        );
        return <NoDocumentsDisplay showBackButton={showBackButton} onGoBack={onGoBack} />;
    }

    const { templates: firstTemplates } = getTaskDocuments(firstTask);
    const firstTemplate = first(firstTemplates);
    if (!firstTemplate) {
        reportErrorToThirdPartyTools(
            new Error(
                `Expected task with slug:${firstTask.slug}, type:${firstTask.type} to contain documents, but found none`
            ),
            'documentListPage',
            'getTaskDocuments'
        );
        return <NoDocumentsDisplay showBackButton={showBackButton} onGoBack={onGoBack} />;
    }

    if (
        !locationState?.skipRedirect &&
        !tasks.find((task) => {
            const { workerDocuments } = getTaskDocuments(task);
            return workerDocuments && workerDocuments.length !== 0;
        }) &&
        !isMediaRequirements
    ) {
        return (
            <Redirect
                to={`/${location.pathname
                    .replace(/^\/+|\/+$/g, '')
                    .replace('location-requirements', firstTask.slug)}/document/${
                    firstTemplate.slug
                }`}
            />
        );
    }
    return (
        <>
            {showBackButton && <BackButton onClick={onGoBack} />}
            <Heading>{pageTitle}</Heading>
            <StatusTag task={firstTask} sx={{ mb: '1.5rem' }} />
            {forms.map((form) => {
                return (
                    <DocumentItem
                        title={form.title}
                        to={form.formPath}
                        documentComplete={!!form.completedDate}
                        key={form.title}
                    />
                );
            })}
            {taskList.map((element) => {
                if ((element as ComplianceTask).slug) {
                    const { templates, workerDocuments } = getTaskDocuments(
                        element as ComplianceTask
                    );
                    return (
                        <React.Fragment key={(element as ComplianceTask).slug}>
                            {templates.map((template) => {
                                const matchingWorkerDocument = getMatchingWorkerDocument(
                                    template,
                                    workerDocuments
                                );
                                const task = element as ComplianceTask;
                                return (
                                    <DocumentItem
                                        title={template.title!}
                                        documentComplete={!!matchingWorkerDocument}
                                        to={`${task.slug}/document/${template?.slug}`}
                                        key={template.slug}
                                    />
                                );
                            })}
                        </React.Fragment>
                    );
                } else {
                    const headerType = (element as LocationHeader).type;
                    const headerName = (element as LocationHeader).name;
                    return (
                        <React.Fragment key={headerName}>
                            {headerType === 'STATE' && (
                                <Flex
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: '.5rem',
                                        mx: '-1rem',
                                        padding: '1rem 1rem',
                                        borderBottom: ({ colors }) =>
                                            `1px solid ${colors.neutral['300']}`,
                                    }}
                                >
                                    <Box sx={{ flex: '1' }}>
                                        <Heading
                                            level={4}
                                            aria-label={headerName ? headerName : ''}
                                            aria-hidden={'false'}
                                            sx={{ m: 0 }}
                                        >
                                            {headerName}
                                        </Heading>
                                    </Box>
                                </Flex>
                            )}
                            {headerType === 'CITY' && (
                                <Flex
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: '.5rem',
                                        mx: '-1rem',
                                        padding: '1rem 1rem',
                                        borderBottom: ({ colors }) =>
                                            `1px solid ${colors.neutral['300']}`,
                                    }}
                                >
                                    <Box sx={{ flex: '1' }}>
                                        <Heading
                                            level={3}
                                            aria-label={headerName ? headerName : ''}
                                            aria-hidden={'false'}
                                            sx={{ m: 0, textIndent: '2rem' }}
                                        >
                                            {headerName}
                                        </Heading>
                                    </Box>
                                </Flex>
                            )}
                        </React.Fragment>
                    );
                }
            })}
        </>
    );
};

export default DocumentListPage;
