export const I9TaskTrackingEvents = {
    backBtnClicked: 'i9_task.back_btn.clicked',
    redirectUrlClicked: 'i9_task.redirect_url.clicked',
    i9CtaClick: 'i9_task.cta.clicked',
    i9NotCompletedRendered: 'i9_task.not_completed.viewed',
    i9SubmittedRendered: 'i9_task.submitted.viewed',
    i9NeedsReviewRendered: 'i9_task.needs_review.viewed',
    i9CompletedRendered: 'i9_task.completed.viewed',
    i9RejectedRendered: 'i9_task.rejected.viewed',
    i9ErroredRendered: 'i9_task.errored.viewed',
} as const;
