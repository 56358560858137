import { array, object, string } from 'yup';

export const BANK_DETAILS_VALIDATION_SCHEMA = object().shape({
    agree: array().length(1, 'Authorization is required to continue.'),
    ssn: string()
        .trim()
        .max(9, 'Social security number must be at most 9 digits.')
        .min(9, 'Social security number must be at least 9 digits.')
        .matches(/^\d+$/, 'Social security number should contain digits only.')
        .required('Social security number is required and must be 9 numerical characters.'),
});

export interface BankDetailsFormValues {
    agree: boolean[];
    ssn: string;
}

export const getInitialBankDetailsValues = (): BankDetailsFormValues => {
    return { agree: [], ssn: '' };
};

export const toCompleteBankDetailsSsnInput = (
    task: ComplianceTask,
    values: BankDetailsFormValues
): CompleteBankDetailsSsnInput => {
    return {
        ssn: values.ssn,
        slug: task.slug,
    };
};
