import React from 'react';
import { ButtonLink, Flex, Heading, Text } from '@indeed/ifl-components';
import { Col, Row } from '@indeed/itmp-components';

import ComplianceContainer from 'client/components/ComplianceContainer';
import Image from 'client/components/Image';
import Page from 'client/components/Page';
import { useAuthentication } from 'client/contexts/AuthenticationContext';

export interface UnknownUserPageProps {
    user: ComplianceUser | null;
    expectingRoles: Roles[];
    showHeader?: boolean;
    showFooter?: boolean;
}
const UnknownUserPage = ({
    user,
    expectingRoles,
    showHeader = true,
    showFooter = false,
}: UnknownUserPageProps): JSX.Element => {
    const { logoutUrl } = useAuthentication();

    return (
        <Page title="No Access" showHeader={showHeader} showFooter={showFooter}>
            <ComplianceContainer size="sm">
                <Row sx={{ mb: '2rem' }}>
                    <Col>
                        <Flex sx={{ justifyContent: 'center' }}>
                            <Image path="alert.svg" alt="alert" width="164px" />
                        </Flex>
                    </Col>
                </Row>
                <Row sx={{ mb: '1rem' }}>
                    <Col>
                        <Heading className="dd-privacy-hidden" level={4}>
                            {user?.email
                                ? `${user.email} does not have access to this portal.`
                                : 'You do not have access to this portal.'}
                        </Heading>
                    </Col>
                </Row>
                {expectingRoles.includes('worker') && (
                    <Row sx={{ mb: '1rem' }}>
                        <Col>
                            <Text level={3}>
                                This portal is where workers hired by Indeed Flex can complete their
                                onboarding documents. If you are an Indeed Flex worker, double check
                                you are using the correct email or try signing in with another
                                email.
                            </Text>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <ButtonLink
                            variant="primary"
                            full
                            id="signoutButton"
                            size="lg"
                            href={logoutUrl}
                        >
                            Sign in with another email
                        </ButtonLink>
                    </Col>
                </Row>
            </ComplianceContainer>
        </Page>
    );
};

export default UnknownUserPage;
