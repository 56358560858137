import React, { PropsWithChildren } from 'react';
import { ArrowLeft } from '@indeed/ifl-icons';
import { Button, ButtonProps } from '@indeed/ifl-components';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory, useRouteMatch } from 'react-router-dom';
type BackButtonProps = ButtonProps & {
    trackingEvent?: string;
};

const BackButton = ({
    onClick,
    sx,
    children = 'Go back',
    trackingEvent,
    ...rest
}: PropsWithChildren<BackButtonProps>): JSX.Element => {
    const { goBack, push } = useHistory();
    const { isExact } = useRouteMatch();
    return (
        <Button
            variant="text"
            type="button"
            size="sm"
            iconBefore={<ArrowLeft />}
            onClick={(event) => {
                if (onClick) {
                    onClick(event);
                } else {
                    if (trackingEvent) {
                        datadogRum.addAction(trackingEvent);
                    }
                    // On the root task page redirect to index (and keep query params) otherwise go back to previous
                    if (isExact) {
                        push('/');
                    } else {
                        goBack();
                    }
                }
            }}
            sx={{ mb: '1rem', ...sx }}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default BackButton;
