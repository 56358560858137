import { LatLng, getGeocode, getLatLng } from 'use-places-autocomplete';

const useGetLatLong = (): ((address: string) => Promise<LatLng>) => {
    const getLatLongForAddress = (address: string): Promise<LatLng> => {
        return getGeocode({
            address,
        }).then((results) => {
            if (!results) {
                throw new Error('No results found');
            }
            return getLatLng(results[0]);
        });
    };

    return getLatLongForAddress;
};

export default useGetLatLong;
