import React from 'react';
import { verifyAllowedHostName } from 'client/utils/redirect';

type Props = {
    redirectUrl: string;
};

const ExternalPageRedirect = ({ redirectUrl }: Props): JSX.Element => {
    if (verifyAllowedHostName(redirectUrl)) {
        window.location.replace(redirectUrl);
    } else {
        throw new Error(`${redirectUrl} domain is not allowed`);
    }
    return <></>;
};

export default ExternalPageRedirect;
