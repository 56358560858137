import React, { useEffect, useState } from 'react';
import { Alert, Button, Flex, Text } from '@indeed/ifl-components';
import { logDocumentEvent } from 'client/utils/datadog';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { useDownloadDocumentLazyQuery } from 'client/hooks/graphql';
import { useToast } from 'client/contexts/ToastContext';

type DocumentDownloadModalProps = {
    template: TaskDocumentTemplateType;
    workerDocument: TaskWorkerDocumentType;
    taskType: TaskType;
};

const DocumentDownloadModal = ({
    template,
    workerDocument,
    taskType,
}: DocumentDownloadModalProps): JSX.Element => {
    const [callDownloadDocumentQuery, { data, called, loading, error }] =
        useDownloadDocumentLazyQuery();
    const { pushToast } = useToast();
    const [pdfGetLoading, setPdfGetLoading] = useState(false);
    const [forceFetch, setForceFetch] = useState(0);

    useEffect(() => {
        if (!called) return;
        const documentUrl = data?.downloadDocument?.url;
        if (error || !documentUrl) {
            reportErrorToThirdPartyTools(
                new Error(
                    `Could not retrieve result from download document query given input: documentSlug:${template.slug}, documentType:WORKER`
                ),
                'documentDownloadModal',
                'download-document'
            );
            pushToast(
                'There was an error while attempting to retrieve the document, please try again.',
                { status: 'critical' }
            );
        } else {
            setPdfGetLoading(true);
            fetch(documentUrl, {
                method: 'GET',
            })
                .then((response) => {
                    if (!response.body) {
                        throw new Error('Received empty response body - nothing to download');
                    }
                    return response.blob();
                })
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const useFilename = `Flex_${(template.title || 'Onboarding_Doc').replace(
                        /[^\w\-\._]/g,
                        '_'
                    )}.pdf`;
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', useFilename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                })
                .catch((fetchError) => {
                    reportErrorToThirdPartyTools(
                        new Error(
                            `Could not retrieve document from secure-document-service at url ${documentUrl}: ${fetchError}`
                        ),
                        'documentDownloadModal',
                        'download-document'
                    );
                    pushToast(
                        'There was an error while attempting to retrieve the document, please try again.',
                        { status: 'critical' }
                    );
                })
                .finally(() => {
                    setPdfGetLoading(false);
                });
        }
    }, [data, forceFetch]);

    const getUserAction = (): string => {
        switch (workerDocument.action) {
            case 'SIGNED':
                return 'signed';
            case 'ACKNOWLEDGED':
                return 'acknowledged';
            case 'DECLINED':
                return 'declined';
            case 'WATCHED':
                return 'completed';
            default:
                // should never happen but just in case
                return 'looked at';
        }
    };

    const logDownloadEvent = (): void => {
        logDocumentEvent(taskType, 'downloadDocumentClicked', {
            documentId: workerDocument.slug,
            documentType: template.type,
        });
    };

    // Starts the retrieval of the document URL. Actual downloading is handled by the useEffect above
    const triggerDownloadDocument = (): void => {
        logDownloadEvent();
        if (!data) {
            callDownloadDocumentQuery({
                variables: {
                    slug: workerDocument.slug,
                    documentType: 'WORKER',
                },
            }).catch(() => {});
        } else {
            setForceFetch((prev) => prev + 1);
        }
    };

    // TODO: Retrieve actual user locale
    const formattedDate = new Date(workerDocument.created).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    return (
        <Alert status="info" sx={{ mb: '1rem' }}>
            <Flex sx={{ flexDirection: 'column', flexGrow: 'unset' }}>
                <Text sx={{ mb: '1rem' }}>
                    You {getUserAction()} this{' '}
                    {workerDocument.action !== 'WATCHED' ? 'document' : 'training'} on{' '}
                    {formattedDate}.
                </Text>
                {workerDocument.action !== 'WATCHED' && (
                    <Button
                        sx={{ width: 'max-content' }}
                        disabled={loading || pdfGetLoading}
                        onClick={triggerDownloadDocument}
                    >
                        Download
                    </Button>
                )}
            </Flex>
        </Alert>
    );
};

export default DocumentDownloadModal;
