import { ApolloError } from '@apollo/client';
import { FormikErrors } from 'formik';
import { QueryResult } from '@apollo/client/react/types/types';
import { keysToCamel } from 'client/utils/graphqlErrorTransform';

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
export interface BaseQueryResult<TData, TVariables>
    extends Pick<QueryResult<TData>, 'previousData' | 'error' | 'loading'> {
    // QueryResult sets `called` to be `true`, in our case it could be `true` or `false` so declare it explicitly
    called: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
const getEdges = <TData>(data: TData): any[] => {
    return data
        ? // @ts-ignore: if data is truthy, data always has one key that is its operation name
          data[Object.keys(data)[0]]?.edges ?? []
        : [];
};

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const getNodes = <TData>(data: TData): any | null => {
    return data ? getEdges(data)?.map((edge) => edge?.node) ?? [] : [];
};

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
export const getNode = <TData>(data: TData): any | null => {
    const nodes = getNodes(data);
    if (nodes.length > 1) {
        throw new Error(
            'Tried to retrieve individual node from nodes with length greater than one'
        );
    }
    return nodes[0] ?? null;
};

/**
 * Pull any validation errors off of the extensions if the code matches and cast to FormikErrors
 * Only check the first errors value for now since that's how the backend graphene implementation currently works
 */
export const getFormikErrors = <Values>(apolloError: ApolloError): FormikErrors<Values> | null => {
    const errors = apolloError?.graphQLErrors;
    const extensions = errors?.length ? errors[0]?.extensions : undefined;
    const extensionErrors = extensions?.errors;
    const isBadUserInputCode = extensions?.code === 'BAD_USER_INPUT';
    return isBadUserInputCode && extensionErrors
        ? (keysToCamel(extensionErrors) as FormikErrors<Values>)
        : null;
};

export enum BgcPackage {
    READY2WORK_COUNTY = '2117',
    READY2WORK_FEDERAL = '2379',
    HYLA = '2370',
    SHUTTERFLY = '2400',
    CANTEL_MEDICAL = '2378',
    STAFFMARK = 'staffmark',
    CARDINAL = '2427',
    JB_HUNT = '2457',
    RADIAL = '2861',
    CALERES = '2533',
    DENSO = '2536',
    FEDEX = '2865',
    IHERB = '2863',
    KENCO = '2578',
    LEVY = '2878',
    PER_COMPONENT_OFAC_RADIAL_ONLY = '2528',
    SADDLE_CREEK = '2498',
    SALESLYTICS_AMEX = '2864',
    STORD = '2862',
    YUSEN_LOGISTICS = '2576',
}

export const getBackgroundCheckInput = (
    accountKey: string,
    bgcPackage?: string,
    country?: string,
    state?: string,
    status?: TaskStatus
): CreateTaskInput => {
    const provider = (
        bgcPackage === BgcPackage.STAFFMARK ? 'TRANSFER' : 'FIRST_ADVANTAGE'
    ) as BackgroundCheckProvider;
    const initialStatus: TaskStatus =
        provider === ('TRANSFER' as BackgroundCheckProvider) ? 'APPROVED' : 'NOT_COMPLETED';
    return {
        type: 'BACKGROUND_CHECK' as TaskType,
        accountKey: accountKey,
        status: status ?? initialStatus,
        metadata: {
            backgroundCheck: {
                package: bgcPackage ? bgcPackage : BgcPackage.READY2WORK_COUNTY,
                location: country
                    ? {
                          state,
                          country,
                      }
                    : undefined,
                provider,
            },
        },
    };
};

export const getLocationRequirementsInput = (
    accountKey: string,
    country: string,
    state?: string,
    status?: TaskStatus
): CreateTaskInput => {
    return {
        type: 'LOCATION_REQUIREMENTS' as TaskType,
        status: status ?? 'NOT_COMPLETED',
        accountKey: accountKey,
        metadata: {
            locationRequirements: {
                location: {
                    state,
                    country,
                },
            },
        },
    };
};

export const getMediaRequirementsInput = (
    accountKey: string,
    country: string,
    state?: string,
    status?: TaskStatus
): CreateTaskInput => {
    return {
        type: 'MEDIA_REQUIREMENTS' as TaskType,
        status: status ?? 'NOT_COMPLETED',
        accountKey: accountKey,
        metadata: {
            mediaRequirements: {
                location: {
                    state,
                    country,
                },
            },
        },
    };
};

export const getConditionalJobOfferInput = (
    accountKey: string,
    status?: TaskStatus
): CreateTaskInput => {
    return {
        type: 'CONDITIONAL_JOB_OFFER' as TaskType,
        accountKey: accountKey,
        status: status ?? 'NOT_COMPLETED',
    };
};

export const getFormI9Input = (accountKey: string, status?: TaskStatus): CreateTaskInput => {
    return {
        type: 'FORM_I9' as TaskType,
        accountKey: accountKey,
        status: status ?? 'NOT_COMPLETED',
        metadata: {
            formI9: {
                eVerifyRequired: true,
            },
        },
    };
};

export const getPayrollInput = (
    accountKey: string,
    eligibleTaskTypes: TaskType[],
    status?: TaskStatus
): CreateTaskInput => {
    const initialStatus: TaskStatus = eligibleTaskTypes.includes('FORM_I9')
        ? 'NOT_COMPLETED'
        : 'NEEDS_REVIEW';
    return {
        type: 'PAYROLL' as TaskType,
        accountKey: accountKey,
        status: status ?? initialStatus,
    };
};

export const getProfileInput = (
    accountKey: string,
    worker: ComplianceWorker | null,
    status?: TaskStatus
): CreateTaskInput => {
    const initialStatus: TaskStatus = 'NOT_COMPLETED';
    return {
        type: 'PROFILE' as TaskType,
        accountKey: accountKey,
        status: status ?? initialStatus,
    };
};

export const getUkPayrollInput = (accountKey: string, status?: TaskStatus): CreateTaskInput => {
    return {
        type: 'UK_PAYROLL' as TaskType,
        accountKey: accountKey,
        status: status ?? 'NOT_COMPLETED',
    };
};

export const getUkDocumentsInput = (accountKey: string, status?: TaskStatus): CreateTaskInput => {
    return {
        type: 'UK_DOCUMENTS' as TaskType,
        accountKey: accountKey,
        status: status ?? 'NOT_COMPLETED',
    };
};

export const getEmergencyContactInput = (
    accountKey: string,
    worker: ComplianceWorker | null,
    status?: TaskStatus
): CreateTaskInput => {
    const completed = false;
    const initialStatus: TaskStatus = completed ? 'COMPLETED' : 'NOT_COMPLETED';
    return {
        type: 'EMERGENCY_CONTACT' as TaskType,
        accountKey: accountKey,
        status: status ?? initialStatus,
    };
};

export const getRightToWorkInput = (accountKey: string, status?: TaskStatus): CreateTaskInput => {
    return {
        type: 'RIGHT_TO_WORK' as TaskType,
        accountKey: accountKey,
        status: status ?? 'NOT_COMPLETED',
    };
};
