import React from 'react';

interface ConfirmAddressEntryProps {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
}

const ConfirmAddressEntry = ({
    address1,
    address2,
    city,
    state,
    postalCode,
}: ConfirmAddressEntryProps): JSX.Element => {
    return (
        <>
            <div>{address1}</div>
            {!!address2 && <div>{address2}</div>}
            <div>
                {city}, {state} {postalCode}
            </div>
        </>
    );
};

export default ConfirmAddressEntry;
