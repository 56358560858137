import React, { useEffect, useState } from 'react';
import usePlacesAutocomplete, {
    GetDetailsArgs,
    RequestOptions,
    getDetails,
} from 'use-places-autocomplete';
import { AddressDetail, AddressType, GeometryDetail, mapAddress } from 'client/utils/google';
import {
    Combobox,
    ComboboxInput,
    ComboboxMenuItem,
    ComboboxMenuList,
    ComboboxPopover,
    Label,
    Text,
} from '@indeed/ifl-components';
import { FormikProps, FormikValues } from 'formik';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';
import { reportErrorToThirdPartyTools } from 'client/utils/errorReporting';
import { v4 as uuidv4 } from 'uuid';

export interface AddressFieldProps {
    formik: FormikProps<unknown> | FormikValues;
    name: string;
    label: string;
    country?: SupportedCountryCode;
    helperText?: string;
    onAddressSuggestionSelected?: (address: string) => void;
}

const getAutoCompleteRequestOptions = (
    sessionToken: google.maps.places.AutocompleteSessionToken | undefined,
    country?: SupportedCountryCode
): RequestOptions => {
    const options: RequestOptions = {
        sessionToken,
        types: ['address'],
    };
    if (country) {
        options.componentRestrictions = {
            country,
        };
    }
    return options;
};

const createNewSessionToken = (): google.maps.places.AutocompleteSessionToken | undefined =>
    !!window.google ? new google.maps.places.AutocompleteSessionToken(uuidv4()) : undefined;

const AddressField = ({
    formik,
    name,
    label,
    helperText,
    country,
    onAddressSuggestionSelected,
}: AddressFieldProps): JSX.Element => {
    const [sessionToken, setSessionToken] = React.useState(createNewSessionToken());
    const addressAutoComplete = usePlacesAutocomplete({
        debounce: 300,
        requestOptions: getAutoCompleteRequestOptions(sessionToken, country),
    });

    const [currentAddress, setCurrentAddress] = useState<AddressType | null>(null);
    useEffect(() => {
        const revalidateForm = async (address: AddressType): Promise<void> => {
            await formik.validateForm();
            Object.keys(address).forEach((key) => {
                if (key in formik.initialValues) {
                    formik.setFieldTouched(key);
                }
            });
        };
        // trigger formik validation on address change
        // otherwise the form gets out of sync since we are manually setting fields
        if (currentAddress !== null) {
            revalidateForm(currentAddress).catch((err) => {
                // this shouldn't really happen but catch errors just in case
                console.log('error revalidating form', err);
            });
        }
    }, [currentAddress, formik.validateForm]);

    const handleSelectPlace = async (placeId: string): Promise<void> => {
        const args: GetDetailsArgs = {
            fields: ['address_components', 'geometry'],
            sessionToken,
            placeId,
        };
        const addressDetailsResponse = await getDetails(args);
        if (
            !addressDetailsResponse ||
            !(addressDetailsResponse as google.maps.places.PlaceResult).address_components
        ) {
            setSessionToken(createNewSessionToken());
            addressAutoComplete.clearSuggestions();
            reportErrorToThirdPartyTools(
                new Error(
                    `Failed to get place details from google places api with place id ${placeId}`
                ),
                'AddressField',
                'handleSelectPlace'
            );
            return;
        }
        const addressDetails: AddressDetail[] = (
            addressDetailsResponse as google.maps.places.PlaceResult
        ).address_components;

        const geometryDetails: GeometryDetail = (
            addressDetailsResponse as google.maps.places.PlaceResult
        ).geometry;

        const address = mapAddress(addressDetails);
        // Update formik values with address details
        Object.keys(address).forEach((key) => {
            if (address[key] && key in formik.initialValues) {
                formik.setFieldValue(key, address[key]);
            }
        });

        if (geometryDetails?.location) {
            formik.setFieldValue('lat', geometryDetails.location.lat());
            formik.setFieldValue('lon', geometryDetails.location.lng());
        }
        setSessionToken(createNewSessionToken());
        addressAutoComplete.clearSuggestions();
        setCurrentAddress(address);
    };
    const handleSelectDescription = async (description: string): Promise<void> => {
        const place = addressAutoComplete.suggestions.data.find(
            (suggestion) => suggestion.description === description
        );
        onAddressSuggestionSelected?.(description);
        if (place?.place_id) {
            await handleSelectPlace(place.place_id);
        }
    };
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = (event.target as HTMLInputElement).value;
        addressAutoComplete.setValue(value);
        formik.setFieldValue(name, value);
    };

    return (
        <>
            <Label size="md" htmlFor="addressInput" showRequiredIndicator>
                {label}
            </Label>
            {helperText ? (
                <Text level={2} sx={{ marginBlockEnd: 1, display: 'inline-block' }}>
                    {helperText}
                </Text>
            ) : null}
            <Combobox onSelect={handleSelectDescription}>
                <ComboboxInput
                    id="addressInput"
                    value={formik.values[name]}
                    onChange={handleInput}
                    autoComplete="new-password"
                    required={true}
                    className="dd-privacy-hidden"
                    aria-label={label}
                    name={name}
                />
                <ComboboxPopover>
                    <ComboboxMenuList>
                        {addressAutoComplete.suggestions.status === 'OK' &&
                            addressAutoComplete.suggestions.data.map((suggestion) => (
                                <React.Fragment key={suggestion.place_id}>
                                    <ComboboxMenuItem
                                        selectIcon={null}
                                        value={suggestion.description}
                                    >
                                        {suggestion.description}
                                    </ComboboxMenuItem>
                                </React.Fragment>
                            ))}
                    </ComboboxMenuList>
                </ComboboxPopover>
            </Combobox>
        </>
    );
};

export default AddressField;
