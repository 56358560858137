import { getWorkerFullName } from 'client/utils/worker';
import { object, string } from 'yup';

export interface PayrollFieldValues {
    bankAccountNumber: Scalars['String'];
    name: Scalars['String'];
    niNumber?: Maybe<Scalars['String']>;
    waitingForNiNumber?: Maybe<Scalars['String'][]>;
    sortCode: Scalars['String'];
    studentLoan?: Maybe<StudentLoanDeclaration>;
    taxInfo?: Maybe<UkTaxDeclaration>;
}

type TaxInfoOption = Record<UkTaxDeclaration, string>;
type StudentLoanOption = Record<StudentLoanDeclaration, string>;

export const waitingForNiNumberLabel = 'I am waiting to receive my National Insurance number';

export const payrollFormValidationSchema = object().shape({
    name: string().trim().required('Account holder name is required'),
    bankAccountNumber: string()
        .required('Account number is required')
        .matches(/^[0-9]+$/gi, 'Account number must only contain numbers')
        .min(8, 'Account number must be 8 digits')
        .max(8, 'Account number must be 8 digits'),
    sortCode: string()
        .min(8, 'Sort code must be 6 digits')
        .max(8, 'Sort code must be 6 digits')
        .required('Sort code is required')
        .matches(/^(?!(?:0{6}|00-00-00))(\d\d-\d\d-\d\d)$/, 'Must be a valid UK sort code'),
    niNumber: string().when('waitingForNiNumber', {
        is: (waitingForNumber: string[]) => waitingForNumber.length > 0,
        then: string().trim(),
        otherwise: string()
            .trim()
            .required('National Insurance number is required')
            .matches(
                /^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D]{1}$/,
                'Must be a valid National Insurance number'
            ),
    }),
    taxInfo: string().trim().required('Tax information is required'),
    studentLoan: string().trim().required('Student loan information is required'),
});

export const generatePayload = (slug: string, values: PayrollFieldValues): UkPayrollInput => {
    const ukPayroll = {
        ...values,
        // remove hyphens from masked input
        sortCode: values.sortCode.replace(/-/g, ''),
        waitingForNiNumber: values.waitingForNiNumber && values.waitingForNiNumber.length > 0,
    };

    return {
        slug,
        metadata: {
            ukPayroll: ukPayroll as UkPayrollFieldInput,
        },
    };
};

export const getInitialValues = (
    worker: ComplianceWorker,
    task: ComplianceTask
): PayrollFieldValues => {
    const { ukPayroll } = task.metadata as UkPayrollMetadataType;
    const name = ukPayroll.name || getWorkerFullName(worker);
    const sortCode = ukPayroll.sortCode || '';
    const niNumber = ukPayroll.niNumber || '';

    return {
        name,
        bankAccountNumber: ukPayroll.bankAccountNumber,
        // add hyphens
        sortCode: sortCode.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3'),
        niNumber: niNumber,
        waitingForNiNumber: ukPayroll.waitingForNiNumber ? ['WAITING'] : [],
        taxInfo: ukPayroll.taxInfo,
        studentLoan: ukPayroll.studentLoan,
    };
};

export const taxInfoOptions: TaxInfoOption = {
    /* eslint-disable @typescript-eslint/naming-convention */
    FIRST_JOB:
        'This is my first job since last 6 April. I have not been receiving taxable Jobseekers Allowance, Employment and Support of Allowance or taxable Incapacity Benefit or a state or occupational pension',
    ONLY_JOB:
        'This is now my only job, but since last 6 April I have had another job, or have received taxable Jobseekers Allowance, Employment and Support of Allowance or taxable Incapacity Benefit. I do not receive a state or occupational pension.',
    ANOTHER_JOB: 'I have another job or receive a state or occupational pension.',
};

export const studentLoanOptions: StudentLoanOption = {
    NO: 'No',
    PLAN_1: 'I have a Plan 1 student loan (before September 2012)',
    PLAN_2: 'I have a Plan 2 student loan (after September 2012)',
    /* eslint-enable @typescript-eslint/naming-convention */
};

export const mapObjectsToOptions = (obj: Record<string, string>): SelectFormatType[] =>
    Object.entries(obj).map(([key, value]) => ({
        value: key,
        label: value,
    }));

export const getTaxInfoOptions = (): SelectFormatType[] => mapObjectsToOptions(taxInfoOptions);
export const getStudentLoanOptions = (): SelectFormatType[] =>
    mapObjectsToOptions(studentLoanOptions);

export const isNiNumberRequired = (formValues: PayrollFieldValues): boolean =>
    !formValues.waitingForNiNumber || formValues.waitingForNiNumber.length === 0;
