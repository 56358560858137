import DocumentItem from 'client/components/DocumentItem';
import React from 'react';
import first from 'lodash/first';
import { Heading, Text } from '@indeed/ifl-components';
import { getMatchingWorkerDocument, getTaskDocuments } from 'client/utils/task';

type DocumentDownloadPageProps = {
    task: ComplianceTask;
    pageTitle: string;
};

const DocumentDownload = ({ task, pageTitle }: DocumentDownloadPageProps): JSX.Element => {
    const { templates, workerDocuments } = getTaskDocuments(task);
    const firstTemplate = first(templates);
    if (!firstTemplate) {
        return (
            <>
                <br />
                <Text>No documents to display.</Text>
            </>
        );
    }

    return (
        <>
            <br />
            <Heading>{pageTitle}</Heading>
            {templates.map((template) => {
                const matchingWorkerDocument = getMatchingWorkerDocument(template, workerDocuments);
                return (
                    <DocumentItem
                        title={template.title!}
                        documentComplete={!!matchingWorkerDocument}
                        to={`${task.slug}/document/${template?.slug}`}
                        key={template.slug}
                    />
                );
            })}
        </>
    );
};

export default DocumentDownload;
