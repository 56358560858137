import * as FormikFields from 'client/components/FormikFields';
import FormRow from 'client/components/FormRow';
import React from 'react';
import { Col } from '@indeed/itmp-components';
import { FormikProps, FormikValues } from 'formik';
import { SupportedCountryCode } from 'client/contexts/LocaleContext';
import { getTitleOptions } from 'client/utils/options';

export interface NameFieldProps {
    formik: FormikProps<unknown> | FormikValues;
    country?: SupportedCountryCode;
}
const NameField = ({ formik, country }: NameFieldProps): JSX.Element => {
    const titleOptions = getTitleOptions();

    return (
        <>
            {country === SupportedCountryCode.GB ? (
                <FormRow>
                    <Col>
                        <FormikFields.RadioField
                            label="Title"
                            name="title"
                            items={titleOptions}
                            radioProps={{
                                showRequiredIndicator: true,
                                className: 'dd-privacy-hidden',
                            }}
                        />
                    </Col>
                </FormRow>
            ) : null}
            <FormRow>
                <Col>
                    <FormikFields.TextField
                        inputProps={{
                            autoComplete: 'given-name',
                            required: true,
                            className: 'dd-privacy-hidden',
                        }}
                        label="Legal first name"
                        name="firstName"
                    />
                </Col>
            </FormRow>
            <FormRow>
                <Col>
                    <FormikFields.TextField
                        inputProps={{
                            autoComplete: 'additional-name',
                            required: true,
                            disabled: formik.values.noMiddleName.includes('true'),
                            className: 'dd-privacy-hidden',
                        }}
                        label="Legal middle name"
                        name="middleName"
                    />
                    <FormikFields.CheckboxField
                        checkboxProps={{
                            className: 'dd-privacy-hidden',
                            onChange: (event) => {
                                formik.handleChange(event);
                                const checked = (event.target as HTMLInputElement).checked;
                                if (checked) {
                                    /* eslint-disable @typescript-eslint/no-floating-promises */
                                    formik.setFieldTouched('middleName', true);
                                    formik.setFieldValue('middleName', 'N/A');
                                } else {
                                    formik.setFieldValue('middleName', '');
                                    /* eslint-enable @typescript-eslint/no-floating-promises */
                                }
                            },
                        }}
                        name="noMiddleName"
                        items={[{ label: 'I do not have a middle name', value: 'true' }]}
                    />
                </Col>
            </FormRow>
            <FormRow>
                <Col>
                    <FormikFields.TextField
                        inputProps={{
                            autoComplete: 'family-name',
                            required: true,
                            className: 'dd-privacy-hidden',
                        }}
                        label="Legal last name"
                        name="lastName"
                    />
                </Col>
            </FormRow>
        </>
    );
};

export default NameField;
