import React, { createContext, useContext, useState } from 'react';

import { useLocation } from 'react-router';
import { useWorker } from 'client/contexts/WorkerContext';

export enum SupportedCountryCode {
    US = 'US',
    GB = 'GB',
}

export type LocaleContextType = {
    country: SupportedCountryCode;
    region: string;
};

export const LocaleContext = createContext<LocaleContextType | null>(null);

export const useLocaleContext = (): LocaleContextType => {
    const context = useContext(LocaleContext);
    if (!context) {
        throw new Error('LocaleContext context must be defined before being used');
    }
    return context;
};

export const LocaleProvider = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
    const location = useLocation();
    const { worker } = useWorker();

    const [localeState, setLocaleState] = useState<{
        country: SupportedCountryCode;
        region: null | string;
    }>({ country: SupportedCountryCode.US, region: null });

    if (worker?.country) {
        if (worker.country !== localeState.country) {
            setLocaleState({ country: worker.country as SupportedCountryCode, region: null });
        }
    } else if (location.pathname.includes('/us')) {
        if (localeState.country !== SupportedCountryCode.US) {
            setLocaleState({ country: SupportedCountryCode.US, region: null });
        }
    } else if (location.pathname.includes('/gb')) {
        if (localeState.country !== SupportedCountryCode.GB) {
            setLocaleState({ country: SupportedCountryCode.GB, region: null });
        }
    }
    const region = localeState.region || '';

    return (
        <LocaleContext.Provider value={{ country: localeState.country, region: region }}>
            {children}
        </LocaleContext.Provider>
    );
};
